// To be done: Date is stored in wrong format in the backend, need to understand why
import moment from "moment";

export function getTileClassNameFunction(timesheetData, monthStatus) {
  // Function to aggregate status for a date
  const getStatusForDate = (date) => {
    const formatDate = moment(date).format("DD-MM-yyyy");

    const timesheetDataForDate = timesheetData.find(
      (data) => data.date === formatDate
    );

    if (!timesheetDataForDate) {
      return null;
    }
    // Assuming status of timesheetData for a date is unique
    return monthStatus.toLowerCase();
  };

  // Return tileClassName function
  return function tileClassName({ date, view }) {
    if (view === "month") {
      const status = getStatusForDate(date);
      if (status === "approved") {
        return ["approved-timesheet"];
      } else if (status === "rejected") {
        return ["rejected-timesheet"];
      } else if (status === "submitted") {
        return ["submitted-timesheet"];
      } else if (status === "pending") {
        return ["pending-timesheet"];
      }
    }
    return null;
  };
}
