import axios from "axios";

// create an axios instance that is needed for GET, PUT, DELETE, POST requests to database
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// create an axiosInstance interceptor that will make sure that for each request the authorization header is added
axiosInstance.interceptors.request.use(function (config) {
  // get access token from local storage
  const auth_token = localStorage.getItem("access_token");

  // get user item
  const logged_in_user_id = localStorage.getItem("loggedInUser");
  const active_user_id = localStorage.getItem("activeUser");

  // get cv type item
  let cv_type = localStorage.getItem("cv-type");

  // check if cv-type is not set
  if (!cv_type) {
    cv_type = "Avaloq";
  }

  // store Bearer token in authorization header of axios request
  config.headers.Authorization = auth_token ? `Bearer ${auth_token}` : "";

  // set id of active user and logged in user
  config.headers["logged-in-user-id"] = logged_in_user_id;
  config.headers["active-user-id"] = active_user_id;

  // set type of cv (avaloq or general) that should be retrieved
  config.headers["cv-type"] = cv_type;

  return config;
});

// axiosInstance.defaults.headers.common["activeUser"] =
//   localStorage.getItem("loggedInUser");

export default axiosInstance;
