import { createSlice } from "@reduxjs/toolkit";
import { resetPageLayout } from "../components/CardsLayoutsHelperFunctions";
import axiosInstance from "../axios/axiosConfig";
import { resetReduxState } from "./resetState";

const initialState = {
  linkedContacts: [],
  idEditLinkedContact: null,
  maritalStatus: "",
  layout: [],
};

export const personalinfoSlice = createSlice({
  name: "personalinfo",
  initialState,
  extraReducers: (builder) =>
    builder.addCase(resetReduxState, () => initialState),
  reducers: {
    initalizeLinkedContacts: (state, action) => {
      state.linkedContacts = action.payload;
    },
    addLinkedContact: (state, action) => {
      state.linkedContacts.push(action.payload);
    },
    handleMaritalStatusChange: (state, action) => {
      state.maritalStatus = action.payload;
    },
    initializeLayout: (state, action) => {
      state.layout = action.payload;
    },
    setIdEditLinkedContact: (state, action) => {
      state.idEditLinkedContact = action.payload;
    },
    resetLayout: (state, action) => {
      let newLayout = resetPageLayout(state.layout);

      // reset local storage item that indicates whether error occurs or not
      localStorage.setItem("errorResetLayout", JSON.stringify(false));

      // update the cards via PUT request
      for (let j = 0; j < newLayout.length; j++) {
        axiosInstance
          .put("/cvapp/cards-layout/", newLayout[j])
          .catch((error) => {
            localStorage.setItem("errorResetLayout", JSON.stringify(true));
            console.log("error reset layout personalInfoSlice: ", error);
          });
      }

      state.layout = newLayout;
    },
  },
});

export const {
  initalizeLinkedContacts,
  addLinkedContact,
  handleMaritalStatusChange,
  initializeLayout,
  resetLayout,
  setIdEditLinkedContact,
} = personalinfoSlice.actions;

export default personalinfoSlice.reducer;
