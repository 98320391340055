import React, { useEffect, useState, useLayoutEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";
// import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./styles.css";
import axiosInstance from "../../axios/axiosConfig";
import {
  changeAvqSkillsOpenOverview,
  changeBusinessSkillsOpenOverview,
  changeConsultingSkillsOpenOverview,
  changeTechSkillsOpenOverview,
  changeThirdPartySkillsOpenOverview,
  initalizeAvqSkillsOverview,
  initalizeBusinessSkillsOverview,
  initalizeConsultingSkillsOverview,
  initalizeEmployeeSkillsPerCategory,
  initalizeNamesAndImages,
  initalizeTechSkillsOverview,
  initalizeThirdPartySkilsOverview,
} from "../../features/skillsMatrixSlice";

function ContentSkillsMatrixOverview() {
  const adminState = useSelector((state) => state.admin);
  //   const colNumber = adminState.usersComplete.length;
  const colNumber = 2;
  const [zIndexSkillMatrixTable, setZIndexSkillsMatrixTable] = useState(5);
  const internalInfoState = useSelector((state) => state.internalinfo);
  const skillMatrixState = useSelector((state) => state.skillmatrix);

  const dispatch = useDispatch();

  useEffect(() => {
    // make request to retrieve employee names and profile images
    axiosInstance.get("/cvapp/employee-names-and-images/").then((response) => {
      dispatch(initalizeNamesAndImages(response.data));
    });

    // make request to retrieve skills of employees
    axiosInstance.get("cvapp/skills-matrix-overview/").then((response) => {
      // dispatch(initalizeEmployeeSkillsPerCategory(response.data));
      let avqSkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("AVQ");
      });

      dispatch(initalizeAvqSkillsOverview(avqSkills));

      let techSkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("TEC");
      });

      dispatch(initalizeTechSkillsOverview(techSkills));

      let thirdPartySkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("3rd");
      });

      dispatch(initalizeThirdPartySkilsOverview(thirdPartySkills));

      let businessSkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("BUS");
      });

      dispatch(initalizeBusinessSkillsOverview(businessSkills));

      let consultingSkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("Consulting");
      });

      //   initalize Business skills
      dispatch(initalizeConsultingSkillsOverview(consultingSkills));
    });
  }, []);

  useLayoutEffect(() => {
    if (internalInfoState.openBackdropPhilicoLogo) {
      setZIndexSkillsMatrixTable(1);
    } else {
      const timeout = setTimeout(() => {
        setZIndexSkillsMatrixTable(5);
      }, 300);

      // clearTimeout(timeout);
    }
  }, [internalInfoState.openBackdropPhilicoLogo]);

  // computes the average of an array
  const average = (array) => array.reduce((a, b) => a + b) / array.length;

  // computes background color based on  skillIndex
  const computeSkillColor = (skillIndex) => {
    // color palette was generated with: https://www.learnui.design/tools/data-color-picker.html
    let opacity = 0.8;

    if (skillIndex === 0) {
      return `rgba(255, 6, 6, ${opacity})`;
    }

    if (skillIndex > 0 && skillIndex <= 0.15) {
      return `rgba(217, 75, 0, ${opacity})`;
    }

    if (skillIndex > 0.15 && skillIndex <= 0.3) {
      return `rgba(177, 99, 0, ${opacity})`;
    }

    if (skillIndex > 0.3 && skillIndex <= 0.45) {
      return `rgba(137, 111, 0, ${opacity})`;
    }

    if (skillIndex > 0.45 && skillIndex <= 0.6) {
      return `rgba(98, 116, 0, ${opacity})`;
    }

    if (skillIndex > 0.6 && skillIndex <= 0.75) {
      return `rgba(60, 117, 0, ${opacity})`;
    }

    if (skillIndex > 0.75) {
      return `rgba(0, 115, 32, ${opacity})`;
    }
  };

  return (
    // <div
    //   className="grid-skills-matrix-overview-wrapper"
    //   style={{
    //     gridTemplateColumns: `150px 150px repeat(${colNumber}, 40px) 50px`,
    //   }}>
    //   <div>Category</div>
    //   <div>Skill</div>
    //   <div
    //     className="user-name-table-header"
    //     // style={{
    //     //   alignSelf: "center",
    //     //   transform: "rotate(-90deg)",
    //     //   textWrap: "nowrap",
    //     //       }}
    //   >
    //     Patrick Amstad
    //   </div>
    //   <div className="user-name-table-header">P-tech Admin</div>
    //   <div>Company Average</div>
    //   <div>AVQ - Cash Operation</div>
    //   <div>Cards</div>
    //   <div className="skill-score">0</div>
    //   <div className="skill-score">1</div>
    //   <div style={{ justifySelf: "center" }}>0.5</div>
    //   <div>AVQ - Cash Operation</div>
    //   <div>Cards</div>
    //   <div className="skill-score">0</div>
    //   <div className="skill-score">1</div>
    //   <div>0.5</div>
    // </div>
    <TableContainer
      component={Paper}
      style={{
        marginTop: "32px",
        maxHeight: "100vh",
        // maxHeight: "2000px",
      }}>
      <Table
        aria-label="collapsible table"
        stickyHeader
        style={{ position: "relative", zIndex: zIndexSkillMatrixTable }}>
        <TableHead>
          <TableRow className="header-row-skills-matrix">
            {/* <TableCell colSpan={1} style={{ width: "40px" }} /> */}
            <TableCell
              align="left"
              style={{ width: "200px" }}
              className="header-row-skills-matrix-column-name">
              Category
            </TableCell>
            <TableCell
              style={{ width: "200px" }}
              align="left"
              className="header-row-skills-matrix-column-name">
              Sub-Category
            </TableCell>
            {skillMatrixState.namesAndImages.map((user) => {
              return (
                <TableCell
                  align="center"
                  className="header-row-skills-matrix-column-name"
                  style={{ width: "40px" }}>
                  {/* <div> */}
                  <Tooltip title={user.name} arrow>
                    <Avatar
                      style={{ scale: "1.5", margin: "auto" }}
                      // style={{ width: "100%", height: "100%", scale: "1.25" }}
                      alt={user.name}
                      src={user.image}
                    />
                  </Tooltip>
                  {/* </div> */}
                </TableCell>
              );
            })}
            <TableCell
              align="left"
              colSpan={1}
              className="header-row-skills-matrix-column-name"
              style={{ width: "40px", textAlign: "center" }}>
              Company Average
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              align="left"
              colSpan="100%"
              style={{ width: "40px" }}
              className="skills-matrix-collapse-title">
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  className="collapse-skills-matrix-button"
                  aria-label="expand row"
                  size="small"
                  onClick={() =>
                    dispatch(
                      changeAvqSkillsOpenOverview(
                        !skillMatrixState.avqSkillsOpenOverview
                      )
                    )
                  }>
                  {skillMatrixState.avqSkillsOpenOverview ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                <div style={{ margin: "auto" }}>AVALOQ SKILLS</div>
              </div>
            </TableCell>
          </TableRow>
          {skillMatrixState.avqSkillsOpenOverview ? (
            skillMatrixState.avqSkillsOverview.map((skillObject) => {
              return (
                <TableRow>
                  <TableCell>{skillObject.skillCategory}</TableCell>
                  <TableCell>{skillObject.skillSubCategory}</TableCell>
                  {skillObject.skillIndexes.map((skillIndex, index) => {
                    // skillObject.skillDurations[index] ? (
                    //   <TableCell
                    //     style={{
                    //       backgroundColor: `${computeSkillColor(skillIndex)}`,
                    //     }}
                    //     align="center"
                    //     className="skills-matrix-ov-skill-index">
                    //     {skillIndex}
                    //   </TableCell>
                    // ) : (
                    //   <TableCell
                    //     style={{
                    //       backgroundColor: `${computeSkillColor(skillIndex)}`,
                    //     }}
                    //     align="center"
                    //     className="skills-matrix-ov-skill-index">
                    //     {skillIndex}
                    //   </TableCell>
                    // );

                    if (skillIndex !== 0) {
                      return (
                        <Tooltip
                          classes={{ tooltip: "tooltip-skill-index" }}
                          // className="tooltip-skill-index"
                          // sx={{ marginTop: "-5px" }}
                          title={`Last used: ${skillObject.skillDurations[index]}`}
                          arrow>
                          <TableCell
                            style={{
                              backgroundColor: `${computeSkillColor(
                                skillIndex
                              )}`,
                            }}
                            align="center"
                            className="skills-matrix-ov-skill-index">
                            {skillIndex}
                          </TableCell>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <TableCell
                          style={{
                            backgroundColor: `${computeSkillColor(skillIndex)}`,
                          }}
                          align="center"
                          className="skills-matrix-ov-skill-index">
                          {skillIndex}
                        </TableCell>
                      );
                    }

                    // return (
                    //   <TableCell
                    //     style={{
                    //       backgroundColor: `${computeSkillColor(skillIndex)}`,
                    //     }}
                    //     align="center"
                    //     className="skills-matrix-ov-skill-index">
                    //     {skillIndex}
                    //   </TableCell>
                    // );
                  })}
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: `${computeSkillColor(
                        average(skillObject.skillIndexes)
                      )}`,
                      // borderRadius: "8px",
                    }}>
                    {Number(average(skillObject.skillIndexes).toFixed(2))}{" "}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}

          <TableRow>
            <TableCell
              align="left"
              colSpan="100%"
              style={{ width: "40px" }}
              className="skills-matrix-collapse-title">
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  className="collapse-skills-matrix-button"
                  aria-label="expand row"
                  size="small"
                  onClick={() =>
                    dispatch(
                      changeTechSkillsOpenOverview(
                        !skillMatrixState.techSkillsOpenOverview
                      )
                    )
                  }>
                  {skillMatrixState.techSkillsOpenOverview ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                <div style={{ margin: "auto" }}>TECHNICAL SKILLS</div>
              </div>
            </TableCell>
          </TableRow>
          {skillMatrixState.techSkillsOpenOverview ? (
            skillMatrixState.techSkillsOverview.map((skillObject) => {
              return (
                <TableRow>
                  <TableCell>{skillObject.skillCategory}</TableCell>
                  <TableCell>{skillObject.skillSubCategory}</TableCell>
                  {skillObject.skillIndexes.map((skillIndex) => {
                    return (
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: `${computeSkillColor(skillIndex)}`,
                        }}
                        className="skills-matrix-ov-skill-index">
                        {skillIndex}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: `${computeSkillColor(
                        average(skillObject.skillIndexes)
                      )}`,
                      // borderRadius: "8px",
                    }}>
                    {Number(average(skillObject.skillIndexes).toFixed(2))}{" "}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}

          {/* 3rd Party applications */}
          <TableRow>
            <TableCell
              align="left"
              colSpan="100%"
              style={{ width: "40px" }}
              className="skills-matrix-collapse-title">
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  className="collapse-skills-matrix-button"
                  aria-label="expand row"
                  size="small"
                  onClick={() =>
                    dispatch(
                      changeThirdPartySkillsOpenOverview(
                        !skillMatrixState.thirdPartySkillsOpenOverview
                      )
                    )
                  }>
                  {skillMatrixState.thirdPartySkillsOpenOverview ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                <div style={{ margin: "auto" }}>3rd PARTY APPLICATIONS</div>
              </div>
            </TableCell>
          </TableRow>
          {skillMatrixState.thirdPartySkillsOpenOverview ? (
            skillMatrixState.thirdPartySkillsOverview.map((skillObject) => {
              return (
                <TableRow>
                  <TableCell>{skillObject.skillCategory}</TableCell>
                  <TableCell>{skillObject.skillSubCategory}</TableCell>
                  {skillObject.skillIndexes.map((skillIndex) => {
                    return (
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: `${computeSkillColor(skillIndex)}`,
                        }}
                        className="skills-matrix-ov-skill-index">
                        {skillIndex}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: `${computeSkillColor(
                        average(skillObject.skillIndexes)
                      )}`,
                      // borderRadius: "8px",
                    }}>
                    {Number(average(skillObject.skillIndexes).toFixed(2))}{" "}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}

          {/* Business skills */}
          <TableRow>
            <TableCell
              align="left"
              colSpan="100%"
              style={{ width: "40px" }}
              className="skills-matrix-collapse-title">
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  className="collapse-skills-matrix-button"
                  aria-label="expand row"
                  size="small"
                  onClick={() =>
                    dispatch(
                      changeBusinessSkillsOpenOverview(
                        !skillMatrixState.businessSkillsOpenOverview
                      )
                    )
                  }>
                  {skillMatrixState.businessSkillsOpenOverview ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                <div style={{ margin: "auto" }}>BUSINESS</div>
              </div>
            </TableCell>
          </TableRow>
          {skillMatrixState.businessSkillsOpenOverview ? (
            skillMatrixState.businessSkillsOverview.map((skillObject) => {
              return (
                <TableRow>
                  <TableCell>{skillObject.skillCategory}</TableCell>
                  <TableCell>{skillObject.skillSubCategory}</TableCell>
                  {skillObject.skillIndexes.map((skillIndex) => {
                    return (
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: `${computeSkillColor(skillIndex)}`,
                        }}
                        className="skills-matrix-ov-skill-index">
                        {skillIndex}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: `${computeSkillColor(
                        average(skillObject.skillIndexes)
                      )}`,
                      // borderRadius: "8px",
                    }}>
                    {Number(average(skillObject.skillIndexes).toFixed(2))}{" "}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}

          {/* Consulting skills */}
          <TableRow>
            <TableCell
              align="left"
              colSpan="100%"
              style={{ width: "40px" }}
              className="skills-matrix-collapse-title">
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  className="collapse-skills-matrix-button"
                  aria-label="expand row"
                  size="small"
                  onClick={() =>
                    dispatch(
                      changeConsultingSkillsOpenOverview(
                        !skillMatrixState.consultingSkillsOpenOverview
                      )
                    )
                  }>
                  {skillMatrixState.consultingSkillsOpenOverview ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                <div style={{ margin: "auto" }}>CONSULTING / PM</div>
              </div>
            </TableCell>
          </TableRow>
          {skillMatrixState.consultingSkillsOpenOverview ? (
            skillMatrixState.consultingSkillsOverview.map((skillObject) => {
              return (
                <TableRow>
                  <TableCell>{skillObject.skillCategory}</TableCell>
                  <TableCell>{skillObject.skillSubCategory}</TableCell>
                  {skillObject.skillIndexes.map((skillIndex) => {
                    return (
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: `${computeSkillColor(skillIndex)}`,
                        }}
                        className="skills-matrix-ov-skill-index">
                        {skillIndex}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: `${computeSkillColor(
                        average(skillObject.skillIndexes)
                      )}`,
                      // borderRadius: "8px",
                    }}>
                    {Number(average(skillObject.skillIndexes).toFixed(2))}{" "}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
      ;
    </TableContainer>
  );
}

export default ContentSkillsMatrixOverview;
