import { createSlice } from "@reduxjs/toolkit";
import { convertToRaw, EditorState } from "draft-js";
import { resetPageLayout } from "../components/CardsLayoutsHelperFunctions";
import axiosInstance from "../axios/axiosConfig";

const initialState = {
  experiences: [],
  textValue: "",
  layout: [],
};

export const experienceSlice = createSlice({
  name: "experience",
  initialState,
  reducers: {
    initalizeExperiences: (state, action) => {
      state.experiences = action.payload;
    },
    addExperience: (state, action) => {
      state.experiences.push(action.payload);
    },
    setTextValue: (state, action) => {
      state.textValue = action.payload;
    },
    resetTextValue: (state, action) => {
      state.textValue = JSON.stringify(
        convertToRaw(EditorState.createEmpty().getCurrentContent())
      );
    },
    initalizeLayout: (state, action) => {
      state.layout = action.payload;
    },
    resetLayout: (state, action) => {
      let newLayout = resetPageLayout(state.layout);

      // reset local storage item that indicates whether error occurs or not
      localStorage.setItem("errorResetLayout", JSON.stringify(false));

      // update the cards via PUT request
      for (let j = 0; j < newLayout.length; j++) {
        axiosInstance
          .put("/cvapp/cards-layout/", newLayout[j])
          .catch((error) => {
            localStorage.setItem("errorResetLayout", JSON.stringify(true));
            console.log("error reset layout personalInfoSlice: ", error);
          });
      }

      state.layout = newLayout;
    },
  },
});

export const {
  initalizeExperiences,
  addExperience,
  setTextValue,
  resetTextValue,
  initalizeLayout,
  resetLayout,
} = experienceSlice.actions;

export default experienceSlice.reducer;
