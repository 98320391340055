import { useState, useRef, useEffect, createRef } from "react";
import { ButtonGroup, Button, Grid, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import PublishIcon from "@mui/icons-material/Publish";
import ForwardIcon from "@mui/icons-material/Forward";
import { getCurrentAndPastThreeMonths, getMonthName } from "./helpers";
import { getMonthStatus, updateMonthStatus } from "../../helpers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./HistoryCard.css";
import { makeStyles } from "@mui/styles";
import { setSelectedDates } from "../../../../features/timesheetSlice";
import SubmitDialog from "./SubmitDialog";

const useStyles = makeStyles((theme) => ({
  pendingButton: {
    fontWeight: "bold",
    color: "white", // Text color
    backgroundColor: "var(--pending-color) !important", // Background color
  },
  submittedButton: {
    fontWeight: "bold",
    color: "white", // Text color
    backgroundColor: "var(--submitted-color) !important", // Background color
  },
  approvedButton: {
    fontWeight: "bold",
    color: "white", // Text color
    backgroundColor: "var(--approved-color) !important", // Background color
  },
  rejectedButton: {
    fontWeight: "bold",
    color: "white", // Text color
    backgroundColor: "var(--rejected-color) !important", // Background color
  },
}));

const TimesheetHistoryCard = ({
  selectedMonth,
  setSelectedMonth,
  monitorTimesheet,
  setMonitorTimesheet,
}) => {
  const adminState = useSelector((state) => state.admin);
  const [months, setMonths] = useState(getCurrentAndPastThreeMonths());
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [monthStatus, setMonthStatus] = useState([]);
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedDates([null, null]));
  }, [selectedMonth]);

  const getClass = (monthStatus) => {
    switch (monthStatus) {
      case "Pending":
        return classes.pendingButton;
      case "Rejected":
        return classes.rejectedButton;
      case "Approved":
        return classes.approvedButton;
      case "Submitted":
        return classes.submittedButton;
    }
  };

  useEffect(() => {
    const promises = months.map((month) => getMonthStatus(month));
    Promise.all(promises)
      .then((data) => {
        setMonthStatus(data);
      })
      .catch((error) => {
        console.error("Error fetching Timesheet Month Status data:", error);
      });
  }, [monitorTimesheet, selectedMonth]);

  const submitCallback = (month) => {
    updateMonthStatus(
      month,
      "Submitted",
      adminState.activeUser,
      "",
      monitorTimesheet,
      setMonitorTimesheet
    );
    setOpenSubmitDialog(false);
  };

  const navigateToMonthCallback = (key) => {
    const firstDay = new Date(key.year, key.month - 1, 1);
    setSelectedMonth(firstDay);
    console.log(key, firstDay);
  };

  const handleDialogClose = () => {
    setOpenSubmitDialog(false);
  };

  return (
    <div className="timesheet-history-card">
      <div className="timesheet-history-card-title">History</div>

      <Grid container columnSpacing={2} direction="row" rowSpacing={4}>
        {months.map((month, index) => {
          const status = monthStatus[index];
          const classes = getClass(status);
          // console.log("month[0]: ", month);
          if (status === "Pending" || status === "Rejected") {
            return (
              <Grid item xs={14} key={index}>
                <ButtonGroup
                  fullWidth={true}
                  variant="contained"
                  key={index}
                  // aria-label="split button"
                >
                  <Button
                    classes={{ root: classes }}
                    sx={{
                      display: "flex",
                      width: 1,
                    }}
                    onClick={() => navigateToMonthCallback(month)}>
                    {getMonthName(month.month)}
                  </Button>
                  <Tooltip
                    classes={{ tooltip: classes }}
                    disableFocusListener
                    title="Submit"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [60, -50],
                          },
                        },
                      ],
                    }}>
                    <Button
                      classes={{ root: classes }}
                      size="small"
                      onClick={() => setOpenSubmitDialog(true)}
                      sx={{
                        display: "flex",
                        width: 0.25,
                      }}>
                      <ForwardIcon />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
                <SubmitDialog
                  open={openSubmitDialog}
                  handleClose={handleDialogClose}
                  title="Submit Timesheet?"
                  body="Submitting the timesheet will notify your approver and you will not be able to make any more adjustments to your timesheet."
                  handleSubmit={submitCallback}
                  month={month}
                />
              </Grid>
            );
          } else {
            return (
              <Grid item xs={14} key={index}>
                <Button
                  classes={{ root: classes }}
                  fullWidth={true}
                  variant="contained"
                  key={index}
                  onClick={() => navigateToMonthCallback(month)}>
                  {getMonthName(month.month)}
                </Button>
              </Grid>
            );
          }
        })}
      </Grid>
    </div>
  );
};

export default TimesheetHistoryCard;
