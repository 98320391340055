import React, { useLayoutEffect, useRef, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import { useSelector, useDispatch } from "react-redux";
import { changeProjectObject } from "../../../../features/cvProjectExperience";
import { parse } from "date-fns";
import moment from "moment";
import axiosInstance from "../../../../axios/axiosConfig";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MUIRichTextEditor from "mui-rte";
import { ThemeProvider } from "@mui/material/styles";
import { convertToRaw, convertFromHTML, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { noErrorTheme } from "../../../../components/RichTextEditorNoErrorTheme";

function ProjectExperience(props) {
  const projectExperienceState = useSelector(
    (state) => state.projectExperience
  );

  const dispatch = useDispatch();

  const titleRef = useRef(null);
  const positionRef = useRef(null);
  const projectTypeRef = useRef(null);
  const clientRef = useRef(null);
  const clientTypeRef = useRef(null);
  const clientLocationRef = useRef(null);
  const descriptionRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const [titleWidth, setTitleWidth] = useState(0);
  const [positionWidth, setPositionWidth] = useState(0);
  const [projectTypeWidth, setProjectTypeWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);
  const [clientTypeWidth, setClientTypeWidth] = useState(0);
  const [clientLocationWidth, setClientLocationWidth] = useState(0);
  const [descriptionWidth, setDescriptionWidth] = useState(0);
  const [startDateWidth, setStartDateWidth] = useState(0);
  const [endDateWidth, setEndDateWidth] = useState(0);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setTitleWidth(titleRef.current.offsetWidth + 2);
      setPositionWidth(positionRef.current.offsetWidth + 2);
      setProjectTypeWidth(projectTypeRef.current.offsetWidth + 2);
      setClientWidth(clientRef.current.offsetWidth + 2);
      setClientTypeWidth(clientTypeRef.current.offsetWidth + 2);
      setClientLocationWidth(clientLocationRef.current.offsetWidth + 2);
      setDescriptionWidth(descriptionRef.current.offsetWidth + 2);
      setStartDateWidth(startDateRef.current.offsetWidth + 2);
      setEndDateWidth(endDateRef.current.offsetWidth + 2);
    }, 150);

    setTitleWidth(titleRef.current.offsetWidth + 2);
    setPositionWidth(positionRef.current.offsetWidth + 2);
    setProjectTypeWidth(projectTypeRef.current.offsetWidth + 2);
    setClientWidth(clientRef.current.offsetWidth + 2);
    setClientTypeWidth(clientTypeRef.current.offsetWidth + 2); // +14 is because of the cross button that allows to delete the input of the field
    setClientLocationWidth(clientLocationRef.current.offsetWidth + 2);
    setDescriptionWidth(descriptionRef.current.offsetWidth + 2);
    setStartDateWidth(startDateRef.current.offsetWidth + 2);
    setEndDateWidth(endDateRef.current.offsetWidth + 2);
    return () => clearTimeout(timer);
  }, [projectExperienceState.experiences]);

  const handleTextEditorChange = (e) => {
    /*
    Check the following link for more details: https://github.com/niuware/mui-rte/issues/25
    In order to handle changes for that text editor we need to do the following steps:
        1. Convert the html string to actual html content
        2. Create a ContentState object
        3. Stringify the ContentState object
    */

    // convert text editor content to an html string (need to use that for formik; we will store the value as a string in the database)
    let htmlString = draftToHtml(convertToRaw(e.getCurrentContent()));

    // get plain text, which does not contain any html tags
    let plainText = e.getCurrentContent().getPlainText();

    dispatch(
      changeProjectObject({
        id: props.projectObject.id,
        property: "description",
        value: htmlString,
      })
    );
  };

  const handleTextEditorSave = (e) => {
    var stateStringified = "";
    // convert value to format that can be used by rich texteditor in a string format
    if (props.projectObject.description !== null) {
      const contentHTML = convertFromHTML(props.projectObject.description);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );

      // conver to stringified object and store it in state variable that is displayed in text editor
      stateStringified = JSON.stringify(convertToRaw(state));
    }

    axiosInstance
      .put("/cvapp/experiences/", {
        id: props.projectObject.id,
        description: props.projectObject.description,
        descriptionStringified: stateStringified,
      })
      .then((response) => {
        dispatch(
          changeProjectObject({
            id: response.data.id,
            property: "description",
            value: response.data.description,
          })
        );
        dispatch(
          changeProjectObject({
            id: response.data.id,
            property: "descriptionStringified",
            value: response.data.descriptionStringified,
          })
        );
      })
      .catch((error) => {
        console.log("error updating position: ", error);
      });
  };

  return (
    <>
      <div
        className="flexbox-wrapper-project-experience"
        style={{ marginBottom: "0px" }}>
        <div className="grid-wrapper-project-experience">
          <div className="grid-item-date-col">
            <div className="flexbox-date-col-wrapper">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  className="outlinedinput-dynamic-cv"
                  inputFormat="MM.yyyy"
                  mask="__.____"
                  disableMaskedInput={false}
                  value={parse(
                    props.projectObject.startDate,
                    "MM.yyyy",
                    new Date()
                  )}
                  onChange={(newValue) => {
                    let transformedDate = moment(newValue).format("MM.YYYY");
                    if (transformedDate !== "Invalid date") {
                      axiosInstance
                        .put("/cvapp/experiences/", {
                          id: props.projectObject.id,
                          startDate: transformedDate,
                        })
                        .then((response) => {
                          dispatch(
                            changeProjectObject({
                              id: response.data.id,
                              property: "startDate",
                              value: response.data.startDate,
                            })
                          );
                        })
                        .catch((error) => {
                          console.log(
                            "error updating grad date degrees: ",
                            error
                          );
                        });
                    }
                  }}
                  disableOpenPicker
                  renderInput={(params) => {
                    //add style prop to increase font-size
                    return (
                      <OutlinedInput
                        className="outlinedinput-dynamic-cv"
                        InputProps={params.InputProps}
                        {...params}
                        // label="birthdate"
                        inputRef={params.inputRef}
                        //   helperText="dd.mm.yyyy"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "mm.yyyy",
                        }}
                        onChange={(e) => {
                          console.log("e.target.value ", e.target.value);
                          console.log("e", e);
                        }}
                        sx={{ width: `${startDateWidth}px` }}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
              <span
                style={{
                  paddingLeft: "5px",
                  paddingRight: "7px",
                  alignSelf: "center",
                  fontWeight: "700",
                  marginBottom: "3px",
                }}>
                -
              </span>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  className="outlinedinput-dynamic-cv"
                  // inputFormat="dd.MM.yyyy"
                  // mask="__.__.____"
                  inputFormat="MM.yyyy"
                  mask="__.____"
                  disableMaskedInput={false}
                  value={parse(
                    props.projectObject.endDate,
                    "MM.yyyy",
                    new Date()
                  )}
                  onChange={(newValue) => {
                    let transformedDate = moment(newValue).format("MM.YYYY");
                    if (
                      transformedDate !== "Invalid date" ||
                      newValue === null
                    ) {
                      axiosInstance
                        .put("/cvapp/experiences/", {
                          id: props.projectObject.id,
                          endDate:
                            transformedDate !== "Invalid date"
                              ? transformedDate
                              : null, //null will result to 'today' therefore we need to cover this case
                        })
                        .then((response) => {
                          dispatch(
                            changeProjectObject({
                              id: response.data.id,
                              property: "endDate",
                              value: response.data.endDate,
                            })
                          );
                        })
                        .catch((error) => {
                          console.log(
                            "error updating endDate project experience: ",
                            error
                          );
                        });
                    }
                  }}
                  disableOpenPicker
                  // helperText="dd.mm.yyyy"
                  // placeholder="dd.mm.yyyy"
                  renderInput={(params) => {
                    //add style prop to increase font-size
                    return (
                      <Tooltip title="empty value results in 'today'" arrow>
                        <OutlinedInput
                          className="outlinedinput-dynamic-cv"
                          InputProps={params.InputProps}
                          {...params}
                          // label="birthdate"
                          inputRef={params.inputRef}
                          //   helperText="dd.mm.yyyy"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "mm.yyyy",
                          }}
                          onChange={(e) => {
                            console.log("e.target.value ", e.target.value);
                            console.log("e", e);
                            // check for null value which corresponds to an empty date
                            // if (e.target.value === null) {
                            //   console.log(
                            //     "target value is null we create put request"
                            //   );
                            //   axiosInstance
                            //     .put("/cvapp/experiences/", {
                            //       id: props.projectObject.id,
                            //       endDate: e.target.value,
                            //     })
                            //     .then((response) => {
                            //       dispatch(
                            //         changeProjectObject({
                            //           id: response.data.id,
                            //           property: "endDate",
                            //           value: response.data.endDate,
                            //         })
                            //       );
                            //     })
                            //     .catch((error) => {
                            //       console.log(
                            //         "error updating endDate project experience: ",
                            //         error
                            //       );
                            //     });
                            // }
                          }}
                          sx={{ width: `${endDateWidth}px` }}
                        />
                      </Tooltip>
                    );
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="experience-title">
            <OutlinedInput
              placeholder="Project title"
              className="outlinedinput-dynamic-cv"
              value={props.projectObject.title}
              onChange={(e) => {
                dispatch(
                  changeProjectObject({
                    id: props.projectObject.id,
                    property: "title",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                axiosInstance
                  .put("/cvapp/experiences/", {
                    id: props.projectObject.id,
                    title: e.target.value,
                  })
                  .then((response) => {
                    dispatch(
                      changeProjectObject({
                        id: response.data.id,
                        property: "title",
                        value: response.data.title,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log("error updating position: ", error);
                  });
              }}
              sx={{ width: `${titleWidth}px` }}
              multiline
            />
          </div>
          <div className="experience-subtitle">
            <OutlinedInput
              placeholder="Project type"
              className="outlinedinput-dynamic-cv"
              value={props.projectObject.projectType}
              onChange={(e) => {
                dispatch(
                  changeProjectObject({
                    id: props.projectObject.id,
                    property: "projectType",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                axiosInstance
                  .put("/cvapp/experiences/", {
                    id: props.projectObject.id,
                    projectType: e.target.value,
                  })
                  .then((response) => {
                    dispatch(
                      changeProjectObject({
                        id: response.data.id,
                        property: "projectType",
                        value: response.data.projectType,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log("error updating projectType: ", error);
                  });
              }}
              sx={{ width: `${projectTypeWidth}px` }}
            />
            <span>, </span>
            <OutlinedInput
              placeholder="Client"
              className="outlinedinput-dynamic-cv"
              value={props.projectObject.company}
              onChange={(e) => {
                dispatch(
                  changeProjectObject({
                    id: props.projectObject.id,
                    property: "company",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                axiosInstance
                  .put("/cvapp/experiences/", {
                    id: props.projectObject.id,
                    company: e.target.value,
                  })
                  .then((response) => {
                    dispatch(
                      changeProjectObject({
                        id: response.data.id,
                        property: "company",
                        value: response.data.company,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log("error updating company: ", error);
                  });
              }}
              sx={{ width: `${clientWidth}px` }}
            />
            <span>, </span>
            {/* <div style={{ display: "inline-flex" }}> */}
            <Autocomplete
              freeSolo
              disableClearable
              id="checkboxes-tags-demo"
              className="client-type-dropdown-selection"
              classes={{
                popper: "client-type-dropdown-selection",
              }}
              style={{
                width: `${clientTypeWidth}px`,
                display: "inline-flex",
              }}
              value={props.projectObject.clientType}
              options={clientTypes}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                );
              }}
              onChange={(e, value) => {
                axiosInstance
                  .put("/cvapp/experiences/", {
                    id: props.projectObject.id,
                    clientType: value,
                  })
                  .then((response) => {
                    dispatch(
                      changeProjectObject({
                        id: response.data.id,
                        property: "clientType",
                        value: response.data.clientType,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log("error updating company: ", error);
                  });
              }}
              onClose={(e, reason) => {
                if (reason === "blur") {
                  axiosInstance
                    .put("/cvapp/experiences/", {
                      id: props.projectObject.id,
                      clientType: props.projectObject.clientType,
                    })
                    .then((response) => {
                      dispatch(
                        changeProjectObject({
                          id: response.data.id,
                          property: "clientType",
                          value: response.data.clientType,
                        })
                      );
                    })
                    .catch((error) => {
                      console.log("error updating company: ", error);
                    });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => {
                    dispatch(
                      changeProjectObject({
                        id: props.projectObject.id,
                        property: "clientType",
                        value: e.target.value,
                      })
                    );
                  }}
                  placeholder="Client type"
                  className="textfield-dynamic-cv"
                />
              )}
            />
            <span>, </span>
            <OutlinedInput
              placeholder="Client location"
              className="outlinedinput-dynamic-cv"
              value={props.projectObject.location}
              onChange={(e) => {
                dispatch(
                  changeProjectObject({
                    id: props.projectObject.id,
                    property: "location",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                axiosInstance
                  .put("/cvapp/experiences/", {
                    id: props.projectObject.id,
                    location: e.target.value,
                  })
                  .then((response) => {
                    dispatch(
                      changeProjectObject({
                        id: response.data.id,
                        property: "location",
                        value: response.data.location,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log("error updating company location: ", error);
                  });
              }}
              sx={{ width: `${clientLocationWidth}px` }}
            />
            {/* </div> */}
          </div>
          <div
            className="experience-description"
            style={{ marginLeft: "-1px" }}>
            <ThemeProvider theme={noErrorTheme}>
              <MUIRichTextEditor
                label="Project description"
                defaultValue={props.projectObject.descriptionStringified}
                onChange={(e) => handleTextEditorChange(e)}
                onBlur={(e) => {
                  handleTextEditorSave(e);
                }}
                controls={[
                  "bold",
                  "italic",
                  // "underline",
                  "bulletList",
                  "numberList",
                ]}
              />
            </ThemeProvider>
          </div>
        </div>
        <Tooltip title="Include in CV" arrow>
          <Checkbox
            checked={props.experienceObject.isIncludedInCv}
            onChange={(e) => {
              axiosInstance
                .put("/cvapp/experiences/", {
                  id: props.experienceObject.id,
                  isIncludedInCv: e.target.checked,
                })
                .then((response) => {
                  dispatch(
                    changeProjectObject({
                      id: response.data.id,
                      property: "isIncludedInCv",
                      value: response.data.isIncludedInCv,
                    })
                  );
                })
                .catch((error) => {
                  console.log(
                    "error updating isIncludedInCv key skill: ",
                    error
                  );
                });
            }}
            style={{ maxHeight: "42px", marginTop: "-8px" }}
          />
        </Tooltip>
        <Tooltip title="Delete Experience" arrow>
          <IconButton
            style={{ maxHeight: "42px", marginTop: "-8px" }}
            onClick={(e) => {
              props.handleDelete(props.experienceObject.id);
            }}>
            <img src={binIcon} alt="bin icon" />
          </IconButton>
        </Tooltip>
      </div>
      <div ref={titleRef} id="start-year-measure">
        {props.projectObject.title
          ? props.projectObject.title
          : "Project title"}
      </div>
      <div ref={positionRef} id="start-year-measure">
        {props.projectObject.position
          ? props.projectObject.position
          : "Project position"}
      </div>{" "}
      <div ref={projectTypeRef} id="start-year-measure">
        {props.projectObject.projectType
          ? props.projectObject.projectType
          : "Project type"}
      </div>
      <div ref={clientRef} id="start-year-measure">
        {props.projectObject.company ? props.projectObject.company : "Client"}
      </div>
      <div ref={descriptionRef} id="start-year-measure">
        {props.projectObject.description
          ? props.projectObject.description
          : "Project description"}
      </div>
      <div ref={startDateRef} id="start-year-measure">
        {props.projectObject.startDate
          ? props.projectObject.startDate
          : "mm.yyyy"}
      </div>
      <div ref={endDateRef} id="start-year-measure">
        {props.projectObject.endDate ? props.projectObject.endDate : "mm.yyyy"}
      </div>
      <div ref={clientTypeRef} id="start-year-measure">
        {props.projectObject.clientType
          ? props.projectObject.clientType
          : "Client type"}
      </div>
      <div ref={clientLocationRef} id="start-year-measure">
        {props.projectObject.location
          ? props.projectObject.location
          : "Client Location"}
      </div>
    </>
  );
}

export default ProjectExperience;

const clientTypes = [
  "Private Bank",
  "Universal Bank",
  "Retail Bank",
  "Commercial Bank",
  "Investment Bank",
  "Saving Bank",
  "Large Private Bank",
  "Large Universal Bank",
  "Large Retail Bank",
  "Large Commercial Bank",
  "Large Investment Bank",
  "Large Saving Bank",
  "BPO for Private Banks",
  "Internal project",
];
