import { createSlice } from "@reduxjs/toolkit";
import { resetPageLayout } from "../components/CardsLayoutsHelperFunctions";
import axiosInstance from "../axios/axiosConfig";
import { resetReduxState } from "./resetState";

const initialState = {
  isAddressSet: false,
  contactDetails: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    countryCode: "",
    birthDate: null,
  },
  nationality: "",
  linkedinUrl: "",
  addressComplete: "",
  address: {
    street: "",
    streetNr: "",
    zip: "",
    city: "",
    country: "",
  },
  image: null,
  layout: [],
};

export const profileinfoSlice = createSlice({
  name: "profileinfo",
  initialState,
  // extraReducers: (builder) =>
  //   builder.addCase(resetReduxState, () => initialState),
  reducers: {
    changeNationality: (state, action) => {
      state.nationality = action.payload;
    },
    changeLinkedinUrl: (state, action) => {
      state.linkedinUrl = action.payload;
    },
    changeCompleteAddress: (state, action) => {
      state.addressComplete = action.payload;
    },
    changeAddressComponent: (state, action) => {
      state.address[action.payload.property] = action.payload.value;
    },
    syncCompleteAddress: (state, action) => {
      if (
        !state.address.street &&
        !state.address.streetNr &&
        !state.address.city &&
        !state.address.country
      ) {
        state.addressComplete = "";
      } else {
        state.addressComplete = state.address.street
          .trim()
          .concat(
            " ",
            state.address.streetNr,
            ", ",
            state.address.city,
            ", ",
            state.address.country
          );
      }
    },
    initalizeContactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    changeContactDetails: (state, action) => {
      state.contactDetails[action.payload.property] = action.payload.value;
    },

    changeFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    initalizeAddressFields: (state, action) => {
      state.address = action.payload;
    },
    initializeCompleteAddressSet: (state, action) => {
      // console.log("action.payload. ", action.payload);
      state.addressComplete = action.payload.street
        .trim()
        .concat(
          " ",
          action.payload.streetNr,
          ", ",
          action.payload.city,
          ", ",
          action.payload.country
        );
    },
    isAddressSet: (state, action) => {
      // action is a boolean value
      state.isAddressSet = action.payload;
    },
    changeImagePath: (state, action) => {
      state.image = action.payload;
    },
    initalizeLayout: (state, action) => {
      state.layout = action.payload;
    },
    resetLayout: (state, action) => {
      let newLayout = resetPageLayout(state.layout);

      // reset local storage item that indicates whether error occurs or not
      localStorage.setItem("errorResetLayout", JSON.stringify(false));

      // update the cards via PUT request
      for (let j = 0; j < newLayout.length; j++) {
        axiosInstance
          .put("/cvapp/cards-layout/", newLayout[j])
          .catch((error) => {
            localStorage.setItem("errorResetLayout", JSON.stringify(true));
            console.log("error reset layout profileInfoSlice: ", error);
          });
      }

      state.layout = newLayout;
    },
  },
});

export const {
  changeCompleteAddress,
  changeAddressComponent,
  syncCompleteAddress,
  changeFirstName,
  initalizeAddressFields,
  initializeCompleteAddressSet,
  isAddressSet,
  changeImagePath,
  changeContactDetails,
  initalizeContactDetails,
  initalizeLayout,
  resetLayout,
  changeNationality,
  changeLinkedinUrl,
} = profileinfoSlice.actions;
export default profileinfoSlice.reducer;
