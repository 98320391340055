import { useMemo, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import "./TimesheetDataView.css";
import "./TimesheetDataView.css";
import { monthOverviewColumns } from "./ColumnsViews";
import { useTable } from "./tableUtils";
import { useSelector, useDispatch } from "react-redux";
import { setInitialGrouping } from "../../../../features/timesheetSlice";

const TimesheetDataView = ({
  timesheetData,
  monitorTimesheet,
  setMonitorTimesheet,
  desactivateEditing,
  enableRowActions = true,
}) => {
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState("");

  const timesheetState = useSelector((state) => state.timesheet);
  const dispatch = useDispatch();
  // Use useEffect to log timesheetData after it's updated
  useEffect(() => {
    setLoading(!loading);
  }, [timesheetData]);

  const data = useMemo(() => {
    return timesheetData;
  }, [loading]);

  const columns = useMemo(
    () =>
      monthOverviewColumns(
        data,
        time,
        setTime,
        dispatch,
        timesheetState.durationEditValidationError,
        timesheetState.dateEditValidationError
      ),
    [
      data,
      time,
      timesheetState.durationEditValidationError,
      timesheetState.dateEditValue,
      timesheetState.dateEditValidationError,
    ]
  );

  const table = useTable(
    columns,
    data,
    monitorTimesheet,
    setMonitorTimesheet,
    desactivateEditing,
    dispatch,
    timesheetState.initialGrouping,
    timesheetState,
    enableRowActions
  );

  useEffect(() => {
    // store grouping options in cache, so user does not have to reselect grouping every time
    dispatch(setInitialGrouping(table.options?.state?.grouping));
  }, [table.options?.state?.grouping]);

  return (
    <div className="data-view-container">
      <MaterialReactTable table={table} />
    </div>
  );
};

export default TimesheetDataView;
