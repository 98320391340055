import React, { useState } from "react";
import icon from "../../../assets/relationship_child_card_icon.svg";
import editIcon from "../../../assets/language_child_card_edit_icon.svg";
import binIcon from "../../../assets/language_child_card_bin_icon.svg";
import checkIcon from "../../../assets/relationship_child_card_check_icon.svg";
import crossIcon from "../../../assets/relationship_child_card_cross_icon.svg";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { scroller } from "react-scroll";
import PopoverDeleteConfirmation from "../../../components/PopoverDeleteConfirmation";

function RelationshipsChildCard(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isToolTipDisabled, setIsToolTipDisabled] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handlePopoverClose = (e, reason) => {
    setAnchorEl(null);
    setOpen(false);
    setIsToolTipDisabled(!isToolTipDisabled);
  };

  const handleDelete = (e) => {
    props.handleEditOrDelete(props.linkedContactObject.id);
  };

  const handleCancel = (e) => {
    setAnchorEl(null);
    setOpen(false);
    setIsToolTipDisabled(!isToolTipDisabled);
  };

  const accompanyText = props.linkedContactObject.willAccompanyToEvents
    ? "Will accompany me to events"
    : "Will NOT accompany me to events";

  const emcText = props.linkedContactObject.isEMC
    ? "Is Emergency Contact"
    : "Is not Emergency Contact";

  return (
    <>
      <PopoverDeleteConfirmation
        open={open}
        anchorEl={anchorEl}
        handleClose={handlePopoverClose}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
      />
      <div className="relationships-child-card-wrapper">
        <div className="relationships-child-card-grid-wrapper">
          <div className="relationships-child-card-grid-item-1">
            <img src={icon} alt="relationship icon" />
          </div>
          <div className="relationships-child-card-grid-item-2">
            <span className="language-skills-child-card-title">
              {props.linkedContactObject.relation}
            </span>
          </div>
          <div className="non-draggable relationships-child-card-grid-item-3">
            <Tooltip title="Edit Contact" arrow>
              <IconButton
                onClick={() => {
                  props.handleEditOrDelete(
                    props.linkedContactObject.id,
                    props.linkedContactObject,
                    props.formik,
                    "EDIT"
                  );
                  scroller.scrollTo("relationships-form-scroll-goal", {
                    duration: 900,
                    delay: 0,
                    smooth: true,
                    offset: -105,
                  });
                }}>
                <img src={editIcon} alt="pencil icon" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Delete Contact"
              arrow
              open={!isToolTipDisabled && isHover}>
              <IconButton
                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={(e) => {
                  setIsToolTipDisabled(!isToolTipDisabled);
                  setAnchorEl(e.currentTarget);
                  setOpen((previousState) => !previousState);
                }}>
                <img src={binIcon} alt="bin icon" />
              </IconButton>
            </Tooltip>
          </div>
          <div className="relationships-child-card-grid-item-4">
            <span className="language-skills-child-card-content">
              {props.linkedContactObject.fullName}
            </span>
            {props.linkedContactObject.isEMC && (
              <span className="language-skills-child-card-content emc-phone-number">
                {`(Phone: ${props.linkedContactObject.phoneEMC})`}
              </span>
            )}
          </div>
          <div className="relationships-child-card-grid-item-5">
            <span className="relationships-child-card-birthdate">
              {props.linkedContactObject.dateOfBirth}
            </span>
          </div>
          <div className="relationships-child-card-grid-item-6">
            <div className="flexbox-wrapper-relationships-child-check-text">
              <div className="relationships-child-card-flex-item-1">
                <img
                  src={
                    props.linkedContactObject.willAccompanyToEvents
                      ? checkIcon
                      : crossIcon
                  }
                  alt="bin icon"
                  style={{ scale: "1.5" }}
                />
                <div
                  style={{
                    fontSize: "1.4rem",
                    marginLeft: "25px",

                    marginTop: "-18px",
                  }}>
                  {accompanyText}
                </div>
              </div>
              <div className="relationships-child-card-flex-item-2">
                {" "}
                <img
                  src={props.linkedContactObject.isEMC ? checkIcon : crossIcon}
                  alt="bin icon"
                  style={{ scale: "1.5" }}
                />
                <div
                  style={{
                    fontSize: "1.4rem",
                    marginLeft: "25px",
                    marginTop: "-18px",
                  }}>
                  {emcText}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RelationshipsChildCard;
