import React from "react";
import illustration from "../../assets/unauthorized_illustration.svg";
import { useNavigate } from "react-router-dom";

function UnauthorizedPage() {
  const navigate = useNavigate();

  const handleHereClick = (e) => {
    //   navigate to the login page if text is clicked
    navigate("/");
  };

  return (
    <div className="unauthorized-content-wrapper">
      <div className="unauthorized-title">Error 401: Unauthorized</div>
      <div className="unauthorized-subtitle">
        Please try to login{" "}
        <span className="unauthorized-link-text" onClick={handleHereClick}>
          here
        </span>
      </div>
      <div className="unauthorized-image">
        <img src={illustration} alt="unauth illustration" />
      </div>
    </div>
  );
}

export default UnauthorizedPage;
