import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";

function LogoutDialog(props) {

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <div className="dialog-reset-layout-wrapper">
        <div className="dialog-reset-layouts-title">Logout?</div>
        <div className="dialog-reset-layouts-subtitle">
          Are you sure you want to logout? Any unsubmitted data could get lost.
        </div>
        <div style={{ marginTop: "6px", float: "right", marginBottom: "8px" }}>
          <Button type="submit" onClick={props.handleLogout} variant="contained">
            LOGOUT
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default LogoutDialog;
