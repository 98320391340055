import React, { useState, useEffect } from "react";
import useWindowDimensions from "../components/useWindowDimensions";
import Confetti from "react-confetti";

function ConfettiEffect(props) {
  const { height, width } = useWindowDimensions();
  const [runConfetti, setRunConfetti] = useState(false);

  useEffect(() => {
    console.log("value of props.runConfetti: ", props.runConfetti);
    if (props.runConfetti) {
      setRunConfetti(true);
      setTimeout(() => {
        setRunConfetti(false);
      }, 8000);
    }
  }, [props.runConfetti]);

  return (
    <div>
      <Confetti
        width={width}
        height={height}
        run={runConfetti}
        numberOfPieces={1000}
        gravity={0.5}
        recycle={false}
      />
    </div>
  );
}

export default ConfettiEffect;
