import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import GridLayout from "react-grid-layout";
import { initializeLayout } from "../../../features/internalinfoSlice";
import BankInfoCard from "./BankInfoCard";
import ContractInfoCard from "./ContractInfoCard";
import IdentityDocumentCard from "./IdentityDocumentParentCard";
import axiosInstance from "../../../axios/axiosConfig";
import { useSelector, useDispatch } from "react-redux";
import {
  initializeCardLayouts,
  adjustCardHeights,
} from "../../../components/CardsLayoutsHelperFunctions";
import RelationshipsCard from "../../PersonalPage/components/RelationshipsParentCard";

function ContentInternalInfoPage(props) {
  const [idCardHeight, setIdCardHeight] = useState(0);
  const [contractCardHeight, setContractCardHeight] = useState(0);
  const [bankInfoCardHeight, setBankInfoCardHeight] = useState(0);
  const [relationshipCardHeight, setRelationshipCardHeight] = useState(0);

  const internalInfoState = useSelector((state) => state.internalinfo);

  const dispatch = useDispatch();

  //defines y margin between card components (contact details, address)
  const grid_y_margin = 10;

  //ref hook for contract info card; Based on these refs we can set height of the cards (H value in layout array)
  const refContractInfoCard = useRef(null);

  //ref hook for identity document card; Based on these refs we can set height of the cards
  const refIdDocumentCard = useRef(null);

  //ref hook for bank informatio card; Based on these refs we can set height of the cards
  const refBankInfoCard = useRef(null);

  //ref hook for relationship card; Based on these refs we can set height of the cards (H value in layout array)
  const refRelationshipCard = useRef(null);

  // did mount hook: We set height of card based on DOM elements. Note that we set minH = maxH = H. This means we can scale the cards only in horizontal direction
  // did mount hook: We set height of card based on DOM elements. Note that we set minH = maxH = H. This means we can scale the cards only in horizontal direction
  // useLayoutEffect(() => {
  useEffect(() => {
    async function getCardLayouts(cardHeights) {
      // console.log("we are in getCardLayout function");
      // get card layouts from db
      var responseCardLayouts = await axiosInstance.get("/cvapp/cards-layout/");

      console.log("responsecardlayouts: ", responseCardLayouts);

      let extractedLayout = initializeCardLayouts(responseCardLayouts.data, [
        "idDocumentCard",
        "bankInfoCard",
        "relationshipCard",
        "contractInfoCard",
      ]);

      // adjust the card heights based on the useRef hooks
      const layoutAdjusted = adjustCardHeights(extractedLayout, cardHeights);

      // initalize the layout variable
      dispatch(initializeLayout(layoutAdjusted));
    }

    getCardLayouts({
      idDocumentCard: refIdDocumentCard.current.offsetHeight + grid_y_margin,
      bankInfoCard: refBankInfoCard.current.offsetHeight + grid_y_margin,
      relationshipCard:
        refRelationshipCard.current.offsetHeight + grid_y_margin,
      contractInfoCard:
        refContractInfoCard.current.offsetHeight + grid_y_margin,
    });
  }, [
    idCardHeight,
    contractCardHeight,
    bankInfoCardHeight,
    relationshipCardHeight,
  ]);

  useLayoutEffect(() => {
    if (idCardHeight !== refIdDocumentCard.current.offsetHeight) {
      setIdCardHeight(refIdDocumentCard.current.offsetHeight);
    }

    if (contractCardHeight !== refContractInfoCard.current.offsetHeight) {
      setContractCardHeight(refContractInfoCard.current.offsetHeight);
    }

    if (bankInfoCardHeight !== refBankInfoCard.current.offsetHeight) {
      setBankInfoCardHeight(refBankInfoCard.current.offsetHeight);
    }

    if (relationshipCardHeight !== refRelationshipCard.current.offsetHeight) {
      setRelationshipCardHeight(refRelationshipCard.current.offsetHeight);
    }
  });

  const handleDragStop = async (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    //  define card heights object
    let cardHeights = {
      idDocumentCard: refIdDocumentCard.current.offsetHeight + grid_y_margin,
      bankInfoCard: refBankInfoCard.current.offsetHeight + grid_y_margin,
      relationshipCard:
        refRelationshipCard.current.offsetHeight + grid_y_margin,
      contractInfoCard:
        refContractInfoCard.current.offsetHeight + grid_y_margin,
    };

    // adjust the card heights
    const newState = adjustCardHeights(layout, cardHeights);

    // update the cards via PUT request
    for (let j = 0; j < newState.length; j++) {
      await axiosInstance
        .put("/cvapp/cards-layout/", newState[j])
        .catch((error) => {
          console.log("error contract card update: ", error);
        });
    }

    dispatch(initializeLayout(newState));
  };

  const handleResizeStop = async (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    //  define card heights object
    let cardHeights = {
      idDocumentCard: refIdDocumentCard.current.offsetHeight + grid_y_margin,
      bankInfoCard: refBankInfoCard.current.offsetHeight + grid_y_margin,
      relationshipCard:
        refRelationshipCard.current.offsetHeight + grid_y_margin,
      contractInfoCard:
        refContractInfoCard.current.offsetHeight + grid_y_margin,
    };

    // adjust the card heights
    const newState = adjustCardHeights(layout, cardHeights);

    // update the cards via PUT request
    for (let j = 0; j < newState.length; j++) {
      await axiosInstance
        .put("/cvapp/cards-layout/", newState[j])
        .catch((error) => {
          console.log("error contract card update: ", error);
        });
    }

    dispatch(initializeLayout(newState));
  };

  return (
    <>
      <GridLayout
        className="layout"
        layout={internalInfoState.layout}
        cols={12}
        rowHeight={1} // if marginy in margin props is set to zero, this corresponds to 1 pixel
        draggableCancel=".non-draggable, .MuiCalendarPicker-root, .MuiPaper-root"
        width={props.width}
        onDragStop={handleDragStop}
        onResizeStop={handleResizeStop}
        useCSSTransforms={false}
        compactType="vertical"
        containerPadding={[0, 10]}
        margin={[10, 0]} //important to leave y-margin to 0; otherwise height computation will not work
        isBounded={true} //will prevent from dragging to infinity
      >
        <div key="idDocumentCard">
          <IdentityDocumentCard innerRef={refIdDocumentCard} />
        </div>
        <div key="bankInfoCard">
          <BankInfoCard innerRef={refBankInfoCard} />
        </div>
        <div key="relationshipCard">
          <RelationshipsCard innerRef={refRelationshipCard} />
        </div>
        <div key="contractInfoCard">
          <ContractInfoCard innerRef={refContractInfoCard} />
        </div>
      </GridLayout>
    </>
  );
}

export default ContentInternalInfoPage;
