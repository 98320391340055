import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  degrees: [],
};

export const degreeSlice = createSlice({
  name: "degrees",
  initialState,
  reducers: {
    initalizeDegrees: (state, action) => {
      state.degrees = action.payload;
    },
    changeDegreeObject: (state, action) => {
      var newState = state.degrees.map((degree) => {
        if (degree.id === action.payload.id) {
          return {
            ...degree,
            [action.payload.property]: action.payload.value,
          };
        }
        return degree;
      });

      state.degrees = newState;
    },
    addDegree: (state, action) => {
      state.degrees.push(action.payload);
    },
  },
});

export const { addDegree, changeDegreeObject, initalizeDegrees } =
  degreeSlice.actions;

export default degreeSlice.reducer;
