import { createSlice } from "@reduxjs/toolkit";
import { resetPageLayout } from "../components/CardsLayoutsHelperFunctions";
import axiosInstance from "../axios/axiosConfig";

const initialState = {
  degrees: [],
  certificates: [],
  radioButton: "Degree",
  layout: [],
};

export const educationSlice = createSlice({
  name: "education",
  initialState,
  reducers: {
    changeRadioButton: (state, action) => {
      state.radioButton = action.payload;
    },
    initalizeDegrees: (state, action) => {
      state.degrees = action.payload;
    },
    addDegree: (state, action) => {
      state.degrees.push(action.payload);
    },
    initalizeCertificates: (state, action) => {
      state.certificates = action.payload;
    },
    addCertificates: (state, action) => {
      state.certificates.push(action.payload);
    },
    initalizeLayout: (state, action) => {
      state.layout = action.payload;
    },
    resetLayout: (state, action) => {
      let newLayout = resetPageLayout(state.layout);

      // reset local storage item that indicates whether error occurs or not
      localStorage.setItem("errorResetLayout", JSON.stringify(false));

      // update the cards via PUT request
      for (let j = 0; j < newLayout.length; j++) {
        axiosInstance
          .put("/cvapp/cards-layout/", newLayout[j])
          .catch((error) => {
            localStorage.setItem("errorResetLayout", JSON.stringify(true));
            console.log("error reset layout personalInfoSlice: ", error);
          });
      }

      state.layout = newLayout;
    },
  },
});

export const {
  changeRadioButton,
  addDegree,
  initalizeDegrees,
  initalizeCertificates,
  addCertificates,
  initalizeLayout,
  resetLayout,
} = educationSlice.actions;

export default educationSlice.reducer;
