import React from "react";
import ContentSkillsMatrixOverview from "./ContentSkillsMatrixOverview";
import Header from "../../components/Header";

function SkillsMatrixOverviewPage() {
  return (
    <div className="outer-div-content-wrapper">
      <div className="back-button-navigation-grid-item-1"></div>
      <div className="main-content-wrapper">
        <Header />
        <ContentSkillsMatrixOverview />
      </div>
    </div>
  );
}

export default SkillsMatrixOverviewPage;
