// Sidebar.jsx
import React from 'react';
import { IoMdMenu, IoMdHome, IoMdPerson, IoMdDocument, IoMdTime, IoMdApps } from 'react-icons/io';
import styled from 'styled-components';
import { Link
  // ,useLocation
 } from 'react-router-dom';

const SidebarWrapper = styled.div`
  background-color: #004170;
  width: 90px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  position: fixed;
  top: 0;
`;

const Icon = styled(Link)`
  color: white;
  font-size: 40px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;

const IconImage = styled.div`
  svg {
    height: 50px;

  }
`;

const Text = styled.span`
  color: white;
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const Sidebar = () => {
    // const location = useLocation();
  return (
    <SidebarWrapper>
      <Icon>
        <IconImage>
          <IoMdMenu />
        </IconImage>
      </Icon>
      <Icon to="/home">
        <IconImage>
          <IoMdHome />
        </IconImage>
        <Text>Home</Text>
      </Icon>
      <Icon to="/profile">
        <IconImage>
          <IoMdPerson />
        </IconImage>
        <Text>Profile</Text>
      </Icon>
      <Icon to="/create-cv">
        <IconImage>
          <IoMdDocument />
        </IconImage>
        <Text>Resume</Text>
      </Icon>
      <Icon to="/timemate">
        <IconImage>
          <IoMdTime />
        </IconImage>
        <Text>TimeMate</Text>
      </Icon>
      <Icon to="/skills-matrix">
        <IconImage>
          <IoMdApps />
        </IconImage>
        <Text>Skills Matrix</Text>
      </Icon>
    </SidebarWrapper>
  );
};

export default Sidebar;
