import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SvgIcon from "@mui/material/SvgIcon";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import AlarmOutlinedIcon from "@mui/icons-material/AlarmOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import SportsBarIcon from "@mui/icons-material/SportsBar";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import ViewCompactOutlinedIcon from "@mui/icons-material/ViewCompactOutlined";
import MenuCategory from "./MenuCategory";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
function HamburgerMenu() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const menuItems = [
    {
      title: "CV App",
      titleIcon: (
        <SvgIcon viewBox="0 0 16 20">
          <path
            d="M5.80004 6.34999C6.36004 6.34999 6.81004 5.89999 6.81004 5.33999C6.81004 4.77999 6.36004 4.32999 5.80004 4.32999C5.24004 4.32999 4.79004 4.77999 4.79004 5.33999C4.79004 5.89999 5.24004 6.34999 5.80004 6.34999Z"
            fill="#004170"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.83 8.55C7.83 7.47 6.92 6.69 5.8 6.69C4.68 6.69 3.77 7.47 3.77 8.55V9.06C3.77 9.15 3.81 9.24 3.87 9.3C3.93 9.36 4.02 9.4 4.11 9.4H7.49C7.58 9.4 7.67 9.36 7.73 9.3C7.79 9.24 7.83 9.15 7.83 9.06V8.55ZM3.75 11.5C3.75 11.3011 3.82902 11.1103 3.96967 10.9697C4.11032 10.829 4.30109 10.75 4.5 10.75H11.5C11.6989 10.75 11.8897 10.829 12.0303 10.9697C12.171 11.1103 12.25 11.3011 12.25 11.5C12.25 11.6989 12.171 11.8897 12.0303 12.0303C11.8897 12.171 11.6989 12.25 11.5 12.25H4.5C4.30109 12.25 4.11032 12.171 3.96967 12.0303C3.82902 11.8897 3.75 11.6989 3.75 11.5ZM3.75 14.5C3.75 14.3011 3.82902 14.1103 3.96967 13.9697C4.11032 13.829 4.30109 13.75 4.5 13.75H11.5C11.6989 13.75 11.8897 13.829 12.0303 13.9697C12.171 14.1103 12.25 14.3011 12.25 14.5C12.25 14.6989 12.171 14.8897 12.0303 15.0303C11.8897 15.171 11.6989 15.25 11.5 15.25H4.5C4.30109 15.25 4.11032 15.171 3.96967 15.0303C3.82902 14.8897 3.75 14.6989 3.75 14.5Z"
            fill="#004170"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.5 2.5C0.5 1.398 1.398 0.5 2.5 0.5H9.19C9.752 0.5 10.282 0.738 10.655 1.131L10.661 1.138L14.973 5.84C15.332 6.223 15.5 6.724 15.5 7.2V17.5C15.5 18.602 14.602 19.5 13.5 19.5H2.5C1.398 19.5 0.5 18.602 0.5 17.5V2.5ZM9.189 2.5H2.5V17.5H13.5V7.192L9.204 2.507L9.201 2.506C9.19736 2.50335 9.1933 2.50133 9.189 2.5Z"
            fill="#004170"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.18994 0.5C9.45516 0.5 9.70951 0.605357 9.89705 0.792893C10.0846 0.98043 10.1899 1.23478 10.1899 1.5V6.2H14.4999C14.6313 6.2 14.7613 6.22587 14.8826 6.27612C15.004 6.32638 15.1142 6.40003 15.207 6.49289C15.2999 6.58575 15.3736 6.69599 15.4238 6.81732C15.4741 6.93864 15.4999 7.06868 15.4999 7.2C15.4999 7.33132 15.4741 7.46136 15.4238 7.58268C15.3736 7.70401 15.2999 7.81425 15.207 7.90711C15.1142 7.99997 15.004 8.07362 14.8826 8.12388C14.7613 8.17413 14.6313 8.2 14.4999 8.2H9.18994C8.92472 8.2 8.67037 8.09464 8.48283 7.90711C8.2953 7.71957 8.18994 7.46522 8.18994 7.2V1.5C8.18994 1.23478 8.2953 0.98043 8.48283 0.792893C8.67037 0.605357 8.92472 0.5 9.18994 0.5Z"
            fill="#004170"
          />
        </SvgIcon>
      ),
      userRoles: "", //empty string means no restriction (all users have access)
      initialOpen: false,
      subcategories: [
        {
          title: "Create CV",
          icon: <NoteAddOutlinedIcon className="hamburger-menu-item" />,
          url: "/create-cv",
          userRoles: "",
        },
        {
          title: "Personal Info",
          icon: <AccountBoxOutlinedIcon className="hamburger-menu-item" />,
          url: "/profile",
          userRoles: "",
        },
      ],
    },
    {
      title: "TimeMate",
      titleIcon: <AlarmOutlinedIcon className="hamburger-menu-item" />,
      userRoles: "",
      initialOpen: true,
      subcategories: [
        {
          title: "Enter hours",
          icon: <CalendarMonthOutlinedIcon className="hamburger-menu-item" />,
          url: "/timemate",
          userRoles: "",
        },
        {
          title: "Leave Request",
          icon: <FollowTheSignsIcon className="hamburger-menu-item" />,
          url: "/leave-request",
          userRoles: "",
        },
        {
          title: "Public Holidays",
          icon: <SportsBarIcon className="hamburger-menu-item" />,
          url: "/public-holidays",
          userRoles: "",
        },
        {
          title: "Admin",
          icon: (
            <SupervisedUserCircleOutlinedIcon className="hamburger-menu-item" />
          ),
          url: "/timemateadmin",
          userRoles: "Admin", //comma separate if multiple user roles
        },
      ],
    },
    {
      title: "Skills Matrix",
      titleIcon: <HandymanOutlinedIcon className="hamburger-menu-item" />,
      userRoles: "",
      initialOpen: false,
      subcategories: [
        {
          title: "Add skills",
          icon: <BuildOutlinedIcon className="hamburger-menu-item" />,
          url: "/skills-matrix",
          userRoles: "",
        },
        {
          title: "Skills Overview",
          icon: <ViewCompactOutlinedIcon className="hamburger-menu-item" />,
          url: "/skills-matrix-ov",
          userRoles: "",
        },
      ],
    },
  ];

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation">
      {menuItems.map((menuItem, index) => {
        return (
          <>
            <MenuCategory
              userRoles={menuItem.userRoles}
              titleIcon={menuItem.titleIcon}
              title={menuItem.title}
              subcategories={menuItem.subcategories}
              initialOpen={menuItem.initialOpen}
            />
            <Divider />
          </>
        );
      })}
    </Box>
  );

  return (
    <div className="menu-flex-item">
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon className="hamburger-menu-icon" />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

export default HamburgerMenu;
