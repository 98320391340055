import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import TimeSheetCard from "./components/TimesheetCard/TimesheetCard";
import CustomCalendar from "./components/Calendar/Calendar";
import { DaysInMonthList, getTimesheetData } from "./helpers"; // getUserFullName
import TimesheetDataView from "./components/TimesheetDataView/TimesheetDataView";
import TimesheetHistoryCard from "./components/HistoryCard/HistoryCardNew";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "./helpers";
import moment from "moment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import "./TimeMatePage.css";
// import { setDailyGroupingState } from "../../features/timesheetSlice";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TimeMatePage() {
  // const [selectedDates, setSelectedDates] = useState(null);
  const [timesheetData, setTimesheetData] = useState([]);
  const [timesheetDataLoaded, setTimeSheetDataLoaded] = useState(false);
  const [monitorTimesheet, setMonitorTimesheet] = useState(false);
  const [desactivateCalendar, setDesactivateCalendar] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [openRejectionAlert, setOpenRejectionAlert] = useState(false);
  const [rejectionComment, setRejectionComment] = useState("");

  const adminState = useSelector((state) => state.admin);
  const timesheetState = useSelector((state) => state.timesheet);

  const dispatch = useDispatch();
  useEffect(() => {
    setMonitorTimesheet(!monitorTimesheet);
  }, [adminState.activeUser]);

  useEffect(() => {
    let response = getTimesheetData(
      setTimesheetData,
      formatDate(selectedMonth)
    );
    response.then((data) => {
      setTimeSheetDataLoaded(!timesheetDataLoaded);
    });
  }, [selectedMonth]);

  useEffect(() => {
    getTimesheetData(setTimesheetData, formatDate(selectedMonth));
  }, [selectedMonth, monitorTimesheet, adminState.activeUser]);


  useEffect(() => {
    const status = timesheetData.map((timesheet, index) => timesheet.status);
    if (status.includes("Rejected")) {
      const rejection_comment = timesheetData.map(
        (timesheet, index) => timesheet.rejection_comment
      );
      setRejectionComment(rejection_comment[0]);
      setOpenRejectionAlert(true);
    }
    const calendarState =
      status.includes("Approved") || status.includes("Submitted");
    setDesactivateCalendar(calendarState);
  }, [timesheetDataLoaded, timesheetData]);

  // [timesheetData]); //remove otherwise reject reason appears on every hour submit

  const onAddTimeSheet = () => {
    // Flag updating timesheet table
    setMonitorTimesheet(!monitorTimesheet);
  };

  const handleClose = () => {
    setOpenRejectionAlert(false);
  };

  return (
    <>
      <div className="outer-div-content-wrapper">
        <div className="back-button-navigation-grid-item-1"></div>
        <div className="main-content-wrapper">
          <Header />
          <Dialog
            open={openRejectionAlert}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>Your Timesheet has been rejected!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {rejectionComment}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" onClick={handleClose}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <div className="timemate-block-container">
            <div className="timemate-upper-block-container">
              <div className="timemate-entry-columns">
                <div className="timemate-history-column">
                  <TimesheetHistoryCard
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    monitorTimesheet={monitorTimesheet}
                    setMonitorTimesheet={setMonitorTimesheet}
                  />
                </div>
                <div className="timemate-calendar-column">
                  <CustomCalendar
                    selectedDates={timesheetState.selectedDates}
                    dispatch={dispatch}
                    timesheetData={timesheetData}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    desactivateCalendar={desactivateCalendar}
                  />

                  {/* <CalendarGrid></CalendarGrid> */}
                </div>
                <div className="timemate-entry-card-column">
                  <TimeSheetCard
                    dates={timesheetState.selectedDates}
                    // setSelectedDates={setSelectedDates}
                    onAdd={onAddTimeSheet}
                  />
                </div>
              </div>
            </div>
            {/* <button onClick={() => dispatch(setDailyGroupingState(""))}>
              Daily aggregation
            </button> */}
            <div className="timemate-lower-block-container">
              <TimesheetDataView
                timesheetData={timesheetData}
                monitorTimesheet={monitorTimesheet}
                setMonitorTimesheet={setMonitorTimesheet}
                desactivateEditing={desactivateCalendar}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeMatePage;
