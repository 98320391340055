import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextFieldCustomized from "./TextFieldCustomized";

function DatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={props.label}
        value={props.value}
        mask={props.mask}
        onClose={props.onClose}
        views={props.views}
        name={props.name}
        onChange={(newValue) => props.onChange(newValue)}
        onBlur={props.onBlur}
        disabled={props.disabled}
        inputFormat={props.inputFormat}
        disableMaskedInput={props.disableMaskedInput}
        renderInput={(params) => {
          //add style prop to increase font-size
          params.inputProps.style = { fontSize: "1.6rem" };
          return (
            <TextFieldCustomized
              InputProps={params.InputProps}
              inputRef={params.inputRef}
              helperText={props.helperText}
              InputLabelProps={{ style: { fontSize: "1.6rem" } }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder,
              }}
              onChange={(e) => {
                console.log("e.target.value ", e.target.value);
                console.log("e", e);
              }}
              disabled={props.disabled}
              error={props.error}
              onBlur={props.onBlur}
              name={props.name}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

DatePicker.defaultProps = {
  onChange: (newValue) => {
  },
  value: Date.now(),
  onBlur: (e) => {},
  error: false,
  name: "",
  inputFormat: "dd-MM-yyyy",
  helperText: "dd-mm-yyyy",
  disabled: false,
  placeholder: "dd-mm-yyyy",
  disableMaskedInput: false,
};

export default DatePicker;
