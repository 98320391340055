import React, { useState, useRef, useLayoutEffect } from "react";
import AvatarCvPic from "../../../../assets/avatar_cv_pic.svg";
import CircularProgress from "@mui/material/CircularProgress";
import philicoLogoWhite from "../../../../assets/PhilicoLogoWhite.svg";
import {
  setAlertMessage,
  setIsFailAlertOpen,
  setIsSuccessAlertOpen,
} from "../../../../features/alertMessageSlice";
import { changeImagePath } from "../../../../features/profileinfoSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import axiosInstance from "../../../../axios/axiosConfig";
import { handlePositionChange } from "../../../../features/internalinfoSlice";
import {
  imageSuccessAlert,
  imageFailAlert,
} from "../../../../components/AlertMessages";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  changeAddressComponent,
  changeContactDetails,
  changeNationality,
  initalizeAddressFields,
  initalizeContactDetails,
  initializeCompleteAddressSet,
  isAddressSet,
  syncCompleteAddress,
} from "../../../../features/profileinfoSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function DynamicCvHeader2nd() {
  const profileInfoState = useSelector((state) => state.profileinfo);
  const internalInfoState = useSelector((state) => state.internalinfo);

  // we set a timeout to wait until page is fully rendered until we compute length of text fields
  const timeoutWidthComputation = 300;

  const [isImageUploading, setIsImageUploading] = useState(false);

  const [firstNameWidth, setFirstNameWidth] = useState(0);
  const [lastNameWidth, setlastNameWidth] = useState(0);
  const [nationalityWidth, setNationalityWidth] = useState(0);
  const [emailWidth, setEmailWidth] = useState(0);
  const [birthDateWidth, setBirthDateWidth] = useState(0);
  const [positionWidth, setPositionWidth] = useState(0);
  const [phoneWidth, setPhoneWidth] = useState(0);
  const [completeAddressWidth, setCompleteAddressWidth] = useState(0);
  const [streetWidth, setStreetWidth] = useState(0);
  const [streetNrWidth, setStreetNrWidth] = useState(0);
  const [zipWidth, setZipWidth] = useState(0);
  const [cityWidth, setCityWidth] = useState(0);
  const [countryWidth, setCountryWidth] = useState(0);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const nationalityRef = useRef(null);
  const birthdayRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const completeAddressRef = useRef(null);
  const streetRef = useRef(null);
  const streetNrRef = useRef(null);
  const zipRef = useRef(null);
  const cityRef = useRef(null);
  const countryRef = useRef(null);
  const positionRef = useRef(null);
  const googlePlacesRef = useRef(null);
  const googlePlacesTextFieldRef = useRef(null);

  const dispatch = useDispatch();

  const handleImageChange = (e) => {
    // set isImageUploading state to true; this will cause the progress symbol to appear
    setIsImageUploading(true);

    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "cv-app-profile-images");

    axios
      .post("https://api.cloudinary.com/v1_1/dnuaxknhc/image/upload", formData)
      .then((responseCloudinary) => {
        // take url from cloudinary and store in db
        axiosInstance
          .put("/cvapp/employee-profile/", {
            imageURL: responseCloudinary.data.url,
          })
          .then((responseDjango) => {
            // open success alert
            dispatch(setIsSuccessAlertOpen(true));

            // set success alert metssage
            dispatch(setAlertMessage(imageSuccessAlert));

            dispatch(changeImagePath(responseDjango.data.imageURL));
            setIsImageUploading(false);
          })
          .catch((error) => {
            dispatch(setIsFailAlertOpen(true));
            dispatch(
              setAlertMessage(
                `${imageFailAlert} Error: ${error.response.status} ${error.response.statusText}`
              )
            );
            console.log("error when saving image url in db: ", error);
            setIsImageUploading(false);
          });
      })
      .catch((error) => {
        console.log("error in image upload clouidinary: ", error);
        setIsImageUploading(false);
      });
  };

  // layout effect that listens to changes in first name in order to scale the input field accordingly
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setFirstNameWidth(firstNameRef.current.offsetWidth + 4 + 1);
    }, timeoutWidthComputation);

    setFirstNameWidth(firstNameRef.current.offsetWidth + 4 + 1); //4 is the padding-left + padding-right

    return () => clearTimeout(timer);
  }, [profileInfoState.contactDetails.firstName]);

  // layout effect that listens to changes in first name in order to scale the input field accordingly
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setlastNameWidth(lastNameRef.current.offsetWidth + 4 + 1);
    }, timeoutWidthComputation);

    setlastNameWidth(lastNameRef.current.offsetWidth + 4 + 1); //4 is the padding-left + padding-right
    return () => clearTimeout(timer);
  }, [profileInfoState.contactDetails.lastName]);

  // layout effect that listens to changes in first name in order to scale the input field accordingly
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setPositionWidth(positionRef.current.offsetWidth + 4 + 1);
    }, timeoutWidthComputation);

    setPositionWidth(positionRef.current.offsetWidth + 4 + 1); //4 is the padding-left + padding-right
    return () => clearTimeout(timer);
  }, [internalInfoState.positionWithinPhilico]);

  useLayoutEffect(() => {
    // get contact details information
    axiosInstance
      .get("/cvapp/employee-profile/")
      .then((response) => {
        // destructure the array with only the properties that you need; we create an arrow function which we then directly call with the response.data object as an argument
        const contactDetails = (({
          firstName,
          lastName,
          email,
          phone,
          countryCode,
          birthDate,
        }) => ({
          firstName,
          lastName,
          email,
          phone,
          countryCode,
          birthDate,
        }))(response.data);
        // initalize the contact details state with the db values
        dispatch(initalizeContactDetails(contactDetails));
        dispatch(changeNationality(response.data.nationality));
      })
      .catch((error) => {
        console.log("error updating first name contact details: ", error);
      });
  }, []);

  return (
    <>
      <div className="cv-header-flexbox-outer-wrapper">
        <label htmlFor="contained-button-file">
          <input
            // accept="image/*"
            accept="image/png, image/jpeg"
            id="contained-button-file"
            type="file"
            onChange={handleImageChange}
            style={{ display: "None" }}
          />
          {isImageUploading ? (
            <div
              style={{
                borderRadius: "50%",
                height: "130px",
                width: "130px",
                borderStyle: "solid",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                zIndex: "2",
              }}>
              <CircularProgress
                style={{ width: "70px", height: "70px", alignSelf: "center" }}
              />
            </div>
          ) : (
            <img
              className="cv-pic-2nd"
              src={
                profileInfoState.image ? profileInfoState.image : AvatarCvPic
              }
              alt="cv pic"
              onError={(e) => {
                (e.target.onerror = null)((e.target.src = AvatarCvPic));
              }}
            />
          )}
        </label>
        <div className="cv-header-flexbox-blue-banner">
          <div className="cv-header-flexbox-name-and-role">
            {/* <div className="first-name-last-name-flexbox-wrapper"> */}
            <div>
              <span>
                <OutlinedInput
                  placeholder="First name"
                  className="outlinedinput-dynamic-cv header-first-name-2nd"
                  value={profileInfoState.contactDetails.firstName.toUpperCase()}
                  sx={{ width: `${firstNameWidth}px` }}
                  onChange={(e) =>
                    dispatch(
                      changeContactDetails({
                        property: "firstName",
                        value: e.target.value,
                      })
                    )
                  }
                  onBlur={(e) => {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        firstName: e.target.value,
                      })
                      .then((response) => {
                        dispatch(
                          changeContactDetails({
                            property: "firstName",
                            value: response.data.firstName,
                          })
                        );
                      })
                      .catch((error) => {
                        console.log(
                          "error updating first name contact details: ",
                          error
                        );
                      });
                  }}
                />
              </span>
              <span>
                <OutlinedInput
                  placeholder="Last name"
                  className="outlinedinput-dynamic-cv header-first-name-2nd"
                  value={profileInfoState.contactDetails.lastName.toUpperCase()}
                  sx={{ width: `${lastNameWidth}px` }}
                  onChange={(e) =>
                    dispatch(
                      changeContactDetails({
                        property: "lastName",
                        value: e.target.value,
                      })
                    )
                  }
                  onBlur={(e) => {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        lastName: e.target.value,
                      })
                      .then((response) => {
                        dispatch(
                          changeContactDetails({
                            property: "lastName",
                            value: response.data.lastName,
                          })
                        );
                      })
                      .catch((error) => {
                        console.log(
                          "error updating first name contact details: ",
                          error
                        );
                      });
                  }}
                />
              </span>
            </div>
            <div>
              <FormControl sx={{ width: `${positionWidth}px` }}>
                <Select
                  className="selectfield-dynamic-cv position-philico-2nd"
                  value={internalInfoState.positionWithinPhilico}
                  label="Age"
                  displayEmpty={true}
                  renderValue={(value) =>
                    value?.length ? (
                      Array.isArray(value) ? (
                        value.join(", ")
                      ) : (
                        value
                      )
                    ) : (
                      <span style={{ color: "white" }}>
                        Position within Philico
                      </span>
                    )
                  }
                  onChange={(e) => {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        positionWithinPhilico: e.target.value,
                      })
                      .then((response) => {
                        dispatch(
                          handlePositionChange(
                            response.data.positionWithinPhilico
                          )
                        );
                      })
                      .catch((error) => {
                        console.log(
                          "error put marital status request: ",
                          error
                        );
                      });
                  }}>
                  <MenuItem value="Junior Consultant">
                    Junior Consultant
                  </MenuItem>
                  <MenuItem value="Consultant">Consultant</MenuItem>
                  <MenuItem value="Senior Consultant">
                    Senior Consultant
                  </MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Partner">Partner</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* </div> */}
          </div>
          <div>
            {" "}
            <img
              src={philicoLogoWhite}
              alt="philico logo"
              className="Philico-logo-cv-header-2nd"
            />
          </div>
        </div>
      </div>
      <div ref={firstNameRef} id="first-name-measure">
        {profileInfoState.contactDetails.firstName
          ? profileInfoState.contactDetails.firstName.toUpperCase()
          : "First Name"}
      </div>
      <div ref={lastNameRef} id="last-name-measure">
        {profileInfoState.contactDetails.lastName
          ? profileInfoState.contactDetails.lastName.toUpperCase()
          : "Last Name"}
      </div>
      <div ref={positionRef} id="position-measure">
        {internalInfoState.positionWithinPhilico
          ? internalInfoState.positionWithinPhilico
          : "Position within Philico"}
      </div>
    </>
  );
}

export default DynamicCvHeader2nd;
