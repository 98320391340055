import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useRef } from "react";
// these two imports are necessary to see handles for resizing and actual resizing
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./App.css";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import CreateCVPage from "./pages/CreateCv/CreateCvPage";
import ProtectedRoute from "./components/ProtectedRoute";
import UnauthorizedPage from "./pages/Unauthorized/UnauthorizedPage";
import SessionExpiredPage from "./pages/SessionExpired/SessionExpiredPage";
import Testing from "./pages/Testing/Testing";
import ContentCvGuidelines from "./pages/CVGuidelinesPage/ContentCvGuidelines";
import ContentReleaseNotes from "./pages/ReleaseNotesPage/ContentReleaseNotes";
import ContentSkillsMatrixPage from "./pages/SkillsMatrixPage/SkillsMatrixPage";
import ContentSkillsMatrixOverview from "./pages/SkillsMatrixOverview/ContentSkillsMatrixOverview";
import SkillsMatrixOverviewPage from "./pages/SkillsMatrixOverview/SkillsMatrixOverviewPage";
import { useIdleTimer } from "react-idle-timer";
import axiosInstanceGoogleAuth from "./axios/axiosConfigGoogleAuth";
import { useNavigate } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import TimeMatePage from "./pages/TimeMate/TimeMate";
import TimeMateAdminPage from "./pages/TimeMate/TimeMateAdmin";

import Sidebar from "./components/Sidebar";
import styled from "styled-components";
import LeaveRequest from "./pages/TimeMate/LeaveRequest";
import PublicHolidays from "./pages/TimeMate/PublicHolidays";

function App() {
  const navigate = useNavigate();

  const ContentWrapper = styled.div`
    // margin-left: 90px;
  `;
  const onIdle = () => {
    navigate("/session-expired");
  };

  const onActive = () => {
    // if user comes back from idle we will check if google auth token is still valid or we have to refresh it
    let elapsedTimeSinceLogin = getElapsedTime() / 1000; //elapsed time since login in seconds

    // check expiration time of access token
    let tokenExpiration = localStorage.getItem("token_expiration");

    // check that tokenExpiration is not null
    if (tokenExpiration) {
      // convert token expiration in seconds to number
      tokenExpiration = Number(tokenExpiration);
      //0.9 is safety factor
      if (elapsedTimeSinceLogin > tokenExpiration * 0.9) {
        // refresh token
        let refreshToken = localStorage.getItem("refresh_token");
        let drfClientId = process.env.REACT_APP_CLIENT_ID;
        let drfClientSecret = process.env.REACT_APP_CLIENT_SECRET;

        // var refresh_token_response = await axiosInstanceGoogleAuth.post(
        axiosInstanceGoogleAuth
          .post("/auth/token/", {
            refresh_token: refreshToken,
            grant_type: "refresh_token",
            client_id: drfClientId,
            client_secret: drfClientSecret,
          })
          .then((response) => {
            // reset the timer
            reset();

            // set starttime to measure when token expires
            localStorage.setItem("startTime", new Date());

            // store time (in seconds), when token expires
            localStorage.setItem("token_expiration", response.data.expires_in);

            // store django access token in local stoage for future requests
            localStorage.setItem("refresh_token", response.data.refresh_token);

            // store django access token in local stoage for future requests
            localStorage.setItem("access_token", response.data.access_token);

            // store variable to monitor changes in timesheet
            localStorage.setItem("timesheetMonitoring", 0);
          })
          .catch((error) => {
            // if error occurs during refresh we will navigate to session expired page
            navigate("/session-expired");
          });
      }
    }
  };

  // this idle timer is used to check every 5 secs if google auth token is still valid; if not it will be refreshed
  const { getTotalActiveTime, getLastIdleTime, getElapsedTime, reset } =
    useIdleTimer({
      // onIdle,
      onActive,
      // onAction,
      timeout: 5_000, //time measured in miliseconds
      throttle: 500,
      crossTab: true,
    });

  const idleTimerLogout = useIdleTimer({
    onIdle,
    timeout: 1800_000, //time measured in miliseconds; after 30 minutes idle user will be logged out
    throttle: 500,
    crossTab: true,
  });

  // define a color theme. These colors will be used throughout the App
  const theme = createTheme({
    palette: {
      philicoblue: {
        main: "#004170",
      },
      textfield_background_color: {
        main: "rgba(235, 234, 236, 0.42)",
      },
    },
    typography: {
      // need because we set font-size in App.css to 62.5 % (10 px)
      htmlFontSize: 10,
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <Sidebar /> */}
        <ContentWrapper>
          <Routes>
            <Route path="/" element={<LoginPage />}></Route>
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/timemate"
              element={
                <ProtectedRoute>
                  <TimeMatePage />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/timemateadmin"
              element={
                <ProtectedRoute>
                  <TimeMateAdminPage />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/leave-request"
              element={
                <ProtectedRoute>
                  <LeaveRequest />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/public-holidays"
              element={
                <ProtectedRoute>
                  <PublicHolidays />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/create-cv"
              element={
                <ProtectedRoute>
                  <CreateCVPage />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/skills-matrix"
              element={
                <ProtectedRoute>
                  <ContentSkillsMatrixPage />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/skills-matrix-ov"
              element={
                <ProtectedRoute>
                  <SkillsMatrixOverviewPage />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/testing"
              element={
                <ProtectedRoute>
                  <Testing />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/cv-guidelines"
              element={
                <ProtectedRoute>
                  <ContentCvGuidelines />
                </ProtectedRoute>
              }></Route>
            <Route
              path="/release-notes"
              element={
                <ProtectedRoute>
                  <ContentReleaseNotes />
                </ProtectedRoute>
              }></Route>
            <Route path="/unauthorized" element={<UnauthorizedPage />}></Route>
            <Route
              path="/session-expired"
              element={<SessionExpiredPage />}></Route>
          </Routes>
        </ContentWrapper>
      </ThemeProvider>
    </>
  );
}

export default App;
