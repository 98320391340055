import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  let access_token;
  let token_expiration;
  let location = useLocation();

  // check if access token is present
  if (localStorage.getItem("access_token")) {
    access_token = localStorage.getItem("access_token");
  }

  // get access token is expired
  if (localStorage.getItem("token_expiration")) {
    token_expiration = localStorage.getItem("token_expiration") * 1;
  }

  //   check if acccess token or expiration time of token is missing; if one of them is missing we conclude that user is not authenticated
  if (
    localStorage.getItem("access_token") !== null &&
    localStorage.getItem("token_expiration") !== null &&
    localStorage.getItem("startTime") !== null
  ) {
    // get token expiration time in seconds; multiplication by 1 converts the string to a number
    var tokenExpiration = localStorage.getItem("token_expiration") * 1;

    // get current date to measure time difference since login
    var endTime = new Date();

    // get start time from local storage; this gets saved to local storage in GoogleLogin.jsx component
    var startTime = Date.parse(localStorage.getItem("startTime"));

    //compute time passed since login (in seconds); we subtract 100 seconds to be on the save side
    var timeDiff = (endTime - startTime) / 1000 - 10;

    // check if token is expired
    if (timeDiff > tokenExpiration) {
      return (
        <Navigate to="/session-expired" state={{ from: location }} replace />
      );
    } else {
      return children;
    }
  } else {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }
};

export default ProtectedRoute;
