import React, { useLayoutEffect, useState, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axiosInstance from "../../../../axios/axiosConfig";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguageObject } from "../../../../features/languageSkillSlice";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import bulletPoint from "../../../../assets/bullet_point_cv.svg";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";

function Languages(props) {
  const [languageWidth, setLanguageWidth] = useState(0);
  const [languageSkillWidth, setLanguageSkillWidth] = useState(0);

  const languageSkillState = useSelector((state) => state.languageskill);

  const languageRef = useRef(null);
  const languageSkillRef = useRef(null);

  // define timeout to wait until width of text fields is computed
  const timeoutWidthComputation = 300;

  const dispatch = useDispatch();

  // layout effect that listens to changes in first name in order to scale the input field accordingly
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setLanguageWidth(languageRef.current.offsetWidth + 4 + 1);
      setLanguageSkillWidth(languageSkillRef.current.offsetWidth + 4 + 1);
    }, timeoutWidthComputation);

    setLanguageWidth(languageRef.current.offsetWidth + 4 + 1); //4 is the padding-left + padding-right
    setLanguageSkillWidth(languageSkillRef.current.offsetWidth + 4 + 1);

    return () => clearTimeout(timer);
  }, [languageSkillState.languageSkills]);

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent: "space-between",
        }}>
        <div style={{ display: "flex" }}>
          <div style={{ minWidth: "150px" }}>
            <FormControl
              sx={{ width: `${languageWidth}px`, fontFamily: "Montserrat" }}>
              <Select
                className="selectfield-dynamic-cv position-philico"
                //   labelId="demo-simple-select-label"
                //   id="demo-simple-select"
                // value={age}
                //   value={internalInfoState.positionWithinPhilico}
                value={props.languageObject.language}
                label="Age"
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length ? (
                    Array.isArray(value) ? (
                      value.join(", ")
                    ) : (
                      value
                    )
                  ) : (
                    <span style={{ color: "rgba(95, 99, 104, 0.4)" }}>
                      Language
                    </span>
                  )
                }
                onChange={(e) => {
                  axiosInstance
                    .put("/cvapp/language-skills/", {
                      id: props.languageObject.id,
                      language: e.target.value,
                    })
                    .then((response) => {
                      dispatch(
                        changeLanguageObject({
                          id: response.data.id,
                          property: "language",
                          value: response.data.language,
                        })
                      );
                    })
                    .catch((error) => {
                      console.log("error put marital status request: ", error);
                    });
                }}>
                <MenuItem value="French">French</MenuItem>
                <MenuItem value="Catalan">Catalan</MenuItem>
                <MenuItem value="German">German</MenuItem>
                <MenuItem value="Italian">Italian</MenuItem>
                <MenuItem value="Romansch">Romansch</MenuItem>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Chinese">Chinese</MenuItem>
                <MenuItem value="Portuguese">Portuguese</MenuItem>
                <MenuItem value="Russian">Russian</MenuItem>
                <MenuItem value="Arabic">Arabic</MenuItem>
                <MenuItem value="Spanish">Spanish</MenuItem>
                <MenuItem value="Dutch">Dutch</MenuItem>
                <MenuItem value="Japanese">Japanese</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl
              sx={{
                width: `${languageSkillWidth}px`,
                fontFamily: "Montserrat",
              }}>
              <Select
                className="selectfield-dynamic-cv position-philico"
                //   labelId="demo-simple-select-label"
                //   id="demo-simple-select"
                // value={age}
                //   value={internalInfoState.positionWithinPhilico}
                value={props.languageObject.languageSkill}
                label="Age"
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length ? (
                    Array.isArray(value) ? (
                      value.join(", ")
                    ) : (
                      value
                    )
                  ) : (
                    <span style={{ color: "rgba(95, 99, 104, 0.4)" }}>
                      Skill level
                    </span>
                  )
                }
                onChange={(e) => {
                  axiosInstance
                    .put("/cvapp/language-skills/", {
                      id: props.languageObject.id,
                      languageSkill: e.target.value,
                    })
                    .then((response) => {
                      dispatch(
                        changeLanguageObject({
                          id: response.data.id,
                          property: "languageSkill",
                          value: response.data.languageSkill,
                        })
                      );
                    })
                    .catch((error) => {
                      console.log("error put marital status request: ", error);
                    });
                }}>
                <MenuItem value="Mother tongue">Mother tongue</MenuItem>
                <MenuItem value="Written and spoken fluency">
                  Written and spoken fluency
                </MenuItem>
                <MenuItem value="Working proficiency">
                  Working proficiency
                </MenuItem>
                <MenuItem value="Intermediate">Intermediate</MenuItem>
                <MenuItem value="Beginner">Beginner</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <Tooltip title="Include in CV" arrow>
            <Checkbox
              checked={props.languageObject.isIncludedInCv}
              onChange={(e) => {
                axiosInstance
                  .put("/cvapp/skills/", {
                    id: props.languageObject.id,
                    isIncludedInCv: e.target.checked,
                  })
                  .then((response) => {
                    dispatch(
                      changeLanguageObject({
                        id: response.data.id,
                        property: "isIncludedInCv",
                        value: response.data.isIncludedInCv,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log(
                      "error updating isIncludedInCv key skill: ",
                      error
                    );
                  });
              }}
              style={{ maxHeight: "42px", marginTop: "-8px" }}
            />
          </Tooltip>
          <Tooltip
            title="Delete language"
            arrow
            //   open={!isToolTipDisabled && isHover}
          >
            <IconButton
              style={{ marginTop: "-8px", maxHeight: "42px" }}
              onClick={() => {
                props.handleDelete(props.languageObject.id);
              }}>
              <img src={binIcon} alt="bin icon" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div ref={languageRef} id="position-measure">
        {props.languageObject.language
          ? props.languageObject.language
          : "Language"}
      </div>
      <div ref={languageSkillRef} id="position-measure">
        {props.languageObject.languageSkill
          ? props.languageObject.languageSkill
          : "Language Skill"}
      </div>
    </>
  );
}

export default Languages;
