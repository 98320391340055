import { createTheme } from "@mui/material/styles";

export const noErrorTheme = createTheme();
Object.assign(noErrorTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        border: "1px solid rgba(0, 0, 0, 0.87)",
        width: "100%",
        borderRadius: "4px",
        fontSize: "1.6rem",
        fontFamily: "Montserrat",
        // marginTop: "1.6rem",
        // marginBottom: "1.6rem",
      },
      editor: {
        display: "block",
        maxHeight: 1500,
        padding: "0 5px 0 5px",
        marginTop: 2,
        fontSize: 16,
      },
      container: {
        display: "flex",
        flexDirection: "column",
        margin: 0,
        justifyContent: "space-between",
        minHeight: "70px",
      },
      toolbar: {
        display: "block",
        order: 2,
        position: "relative",
      },
      placeHolder: {},
      editorContainer: {
        padding: 13,
        margin: 0,
        fontSize: 16,
      },
    },
  },
});
