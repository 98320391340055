import React from "react";
import blobimage from "../assets/blob_title_card.svg";
// import illustration from "../assets/page1_profile_illustration.svg";

// function TitleCard({ title, subtitle, illustration }) {
function TitleCard(props) {
  return (
    <div className="title-card">
      <div className="title-flex-box">
        <div className="title-title-card">{props.title}</div>
        <div className="subtitle-title-card">{props.subtitle}</div>
      </div>
      <div></div>
      <div className="title-card-illustration-blob">
        <img
          className="illustration-title-card"
          src={props.illustration}
          alt="prof_illu"
          style={{ scale: props.scale, marginRight: "-120px" }}
        />
        <img className="blob-title-card" src={blobimage} alt="blob_image" />
      </div>
    </div>
  );
}

export default TitleCard;
