import React, { useEffect } from "react";
import axiosInstance from "../../../axios/axiosConfig";
import { useSelector, useDispatch } from "react-redux";
import { initalizeDataWorkflowObject } from "../../../features/cvDataWorkflowSlice";

function UpdateAndApprove() {
  const cvDataWorkflowState = useSelector((state) => state.cvdataworkflow);
  const dispatch = useDispatch();

  useEffect(() => {
    axiosInstance
      .get("/cvapp/cv-data-workflow/")
      .then((response) => {
        dispatch(initalizeDataWorkflowObject(response.data));
      })
      .catch((error) => {
        console.log("error initalizing cv data workflow");
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        zIndex: "1",
        fontSize: "14px",
        marginLeft: "16px",
      }}>
      <div>
        <span style={{ fontWeight: "600", marginRight: "4px" }}>
          {" "}
          Last time updated:{" "}
        </span>
        {/* <span>23.Dec 2023 </span> */}
        <span>
          {cvDataWorkflowState.dataWorkflow?.lastTimeUpdated
            ? cvDataWorkflowState.dataWorkflow.lastTimeUpdated
            : "Pending"}
        </span>
      </div>
      <div>
        <span style={{ fontWeight: "600", marginRight: "4px" }}>
          {" "}
          Approval Status:{" "}
        </span>
        {/* <span>Pending </span>{" "} */}
        <span>
          {cvDataWorkflowState.dataWorkflow?.lastTimeApproved
            ? `${cvDataWorkflowState.dataWorkflow.lastTimeApproved} (${cvDataWorkflowState.dataWorkflow.approver})`
            : "Pending"}
        </span>
      </div>
    </div>
  );
}

export default UpdateAndApprove;
