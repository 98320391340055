import React, { useRef, useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import philicoLogoWhite from "../../assets/PhilicoLogoWhite.svg";
import { useSelector, useDispatch } from "react-redux";
import bulletPoint from "../../assets/bullet_point_cv.svg";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import AvatarCvPic from "../../assets/avatar_cv_pic.svg";
import Header from "../../components/Header";

function Testing() {
  return (
    <>
      <div style={{ maxWidth: "1260px", margin: "auto" }}>
        <Header />
        <div style={{ position: "relative", zIndex: "10" }}>
          <h1 style={{ marginBottom: "16px" }}>We are in React app</h1>{" "}
          <button
            onClick={(e) => {
              window.location.replace("http://localhost:4200/");
            }}>
            GO TO ANGULAR APP
          </button>
          <input />
          <textarea />
        </div>
      </div>
      <div style={{ marginTop: "500px" }}>
        {" "}
        <input />
        <textarea />
      </div>
    </>
  );
}

export default Testing;
