import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  certificates: [],
};

export const certificateSlice = createSlice({
  name: "certificates",
  initialState,
  reducers: {
    initalizeCertificates: (state, action) => {
      state.certificates = action.payload;
    },
    changeCertificateObject: (state, action) => {
      var newState = state.certificates.map((certificate) => {
        if (certificate.id === action.payload.id) {
          return {
            ...certificate,
            [action.payload.property]: action.payload.value,
          };
        }
        return certificate;
      });

      state.certificates = newState;
    },
    addCertificate: (state, action) => {
      state.certificates.push(action.payload);
    },
  },
});

export const {
  initalizeCertificates,
  changeCertificateObject,
  addCertificate,
} = certificateSlice.actions;

export default certificateSlice.reducer;
