import React, { useLayoutEffect } from "react";
import Button from "@mui/material/Button";
import AddIconButton from "../../../../components/input_components/AddIconButton";
import Employer from "./Employer";
import { useSelector, useDispatch } from "react-redux";
import {
  addEmploymentHistory,
  initalizeEmploymentHistory,
} from "../../../../features/employmentHistory";
import axiosInstance from "../../../../axios/axiosConfig";

function DynamicCvEmploymentHistory() {
  const employmentHistoryState = useSelector(
    (state) => state.employmenthistory
  );

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    axiosInstance.get("/cvapp/employment-history/").then((response) => {
      dispatch(initalizeEmploymentHistory(response.data));
    });
  }, []);

  const handleDelete = (id) => {
    axiosInstance
      .delete("/cvapp/employment-history/", {
        data: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(initalizeEmploymentHistory(response.data));
      })
      .catch((error) => {
        console.log("error deleting experience");
      });
  };

  return (
    <div className="bounding-box-cv-summary-section">
      <div className="cv-project-experience-section-grid-wrapper">
        {" "}
        <div className="left-col-section-title">EMPLOYMENT HISTORY</div>
        <div>
          {/* <Employer /> */}
          {employmentHistoryState.employmentHistory.map((history) => {
            return (
              <Employer
                key={history.id}
                historyObject={history}
                handleDelete={handleDelete}
              />
            );
          })}
          <div>
            <Button
              variant="contained"
              startIcon={<AddIconButton />}
              className="dynamic-cv"
              onClick={(e) => {
                axiosInstance
                  .post("/cvapp/employment-history/", {
                    jobTitle: "",
                    employer: "",
                    location: "",
                  })
                  .then((response) => {
                    dispatch(addEmploymentHistory(response.data));
                  });
              }}
            >
              EMPLOYER
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicCvEmploymentHistory;
