import React, { useEffect } from "react";
import SelectCustomized from "../../../components/input_components/SelectCustomized";
import DatePicker from "../../../components/input_components/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../axios/axiosConfig";
import {
  handleAHVChange,
  handleCoachChange,
  handlePermitChange,
  handlePlaceOfOriginChange,
  handlePositionChange,
  handleStartDateChange,
  handleSubcontractorChange,
  handleWitholdingTaxChange,
} from "../../../features/internalinfoSlice";
import { parse } from "date-fns";
import moment from "moment";
import TextFieldCustomized from "../../../components/input_components/TextFieldCustomized";
import {
  initalizeManagerUsers,
  initalizeUserRoles,
} from "../../../features/adminSlice";

function ContractInfoCard(props) {
  const internalInfoState = useSelector((state) => state.internalinfo);
  const adminState = useSelector((state) => state.admin);

  // create dispatch variable to access state reducers later
  const dispatch = useDispatch();

  // fetch data on mount of component
  useEffect(() => {
    axiosInstance
      .get("/cvapp/employee-profile/")
      .then((response) => {
        dispatch(handlePositionChange(response.data.positionWithinPhilico));
        dispatch(handleStartDateChange(response.data.startDatePhilico));
        dispatch(handleSubcontractorChange(response.data.subcontractor));
        dispatch(handleAHVChange(response.data.ahv));
        dispatch(handlePlaceOfOriginChange(response.data.placeOfOrigin));
        dispatch(handleWitholdingTaxChange(response.data.withholdingTax));
        dispatch(handlePermitChange(response.data.permit));
        dispatch(handleCoachChange(response.data.coach));
      })
      .catch((error) => {
        console.log("error initializing contract card: ", error);
      });
  }, []);

  useEffect(() => {
    console.log("we update manager users roles");
    // get employees and their user roles
    axiosInstance
      .get("/cvapp/user-roles/")
      .then((response) => {
        // dispatch(initalizeUserRoles(response.data));

        var managers = [];

        // iterate through data to find manager user roles
        response.data.map((user) => {
          if (user.userRole.includes("Manager")) {
            managers.push({ id: user.id, name: user.name });
          }
        });

        dispatch(initalizeManagerUsers(managers));
      })
      .catch((error) => {
        console.log("error GET /cvapp/user-roles/: ", error);
      });
  }, [adminState.userRoles]);

  return (
    <div className="content-card" ref={props.innerRef}>
      <h1 className="content-card-title">Contract Information</h1>
      <div className="contract-info-flexbox-wrapper">
        <div className="non-draggable contract-info-flex-item-1">
          <SelectCustomized
            label="Position within Philico"
            select_options={[
              "Junior Consultant",
              "Consultant",
              "Senior Consultant",
              "Manager",
              "Partner",
              "Other",
            ]}
            values={[
              "Junior Consultant",
              "Consultant",
              "Senior Consultant",
              "Manager",
              "Partner",
              "Other",
            ]}
            value={internalInfoState.positionWithinPhilico}
            onChange={(e) => {
              axiosInstance
                .put("/cvapp/employee-profile/", {
                  positionWithinPhilico: e.target.value,
                })
                .then((response) => {
                  dispatch(
                    handlePositionChange(response.data.positionWithinPhilico)
                  );
                })
                .catch((error) => {
                  console.log("error put marital status request: ", error);
                });
            }}
            fullWidth={true}
            letterSpacing={0}
          />
        </div>
        <div className="non-draggable contract-info-flex-item-1">
          <SelectCustomized
            label="Philico Coach"
            // select_options={[
            //   "Junior Consultant",
            //   "Consultant",
            //   "Senior Consultant",
            //   "Manager",
            //   "Partner",
            //   "Other",
            // ]}
            // select_options={adminState.managerUsers.map((user) => user.name)}
            // select_options={["Patrick Amstad"]}
            // values={[2]}
            values={adminState.managerUsers.map((user) => user.id)}
            select_options={adminState.managerUsers.map((user) => user.name)}
            // select_options={adminState.userRoles.map((user) => {
            //   console.log("user: ", user);
            //   if (user.userRole.includes("Manager")) {
            //     return user.name;
            //   }
            // })}
            // values={adminState.userRoles.map((user) => {
            //   console.log("user: ", user);
            //   if (user.userRole.includes("Manager")) {
            //     return user.id;
            //   }
            // })}
            // onChange={(e) => console.log("e.target.value: ", e.target.value)}
            // values={[
            //   "Junior Consultant",
            //   "Consultant",
            //   "Senior Consultant",
            //   "Manager",
            //   "Partner",
            //   "Other",
            // ]}
            value={internalInfoState.coach}
            // value={internalInfoState.positionWithinPhilico}
            onChange={(e) => {
              axiosInstance
                .put("/cvapp/employee-profile/", {
                  coach: e.target.value,
                })
                .then((response) => {
                  dispatch(handleCoachChange(response.data.coach));
                })
                .catch((error) => {
                  console.log("error put coach request: ", error);
                });
            }}
            fullWidth={true}
            letterSpacing={0}
          />
        </div>
        <div className="non-draggable contract-info-flex-item-2">
          {" "}
          <DatePicker
            onChange={(newValue) => {
              let transformedDate = moment(newValue).format("YYYY-MM-DD");
              if (transformedDate !== "Invalid date") {
                axiosInstance
                  .put("/cvapp/employee-profile/", {
                    startDatePhilico: transformedDate, //we need to transform javascript date object to date string, as Django expects the date in a string format
                  })
                  .then((response) => {
                    dispatch(
                      handleStartDateChange(response.data.startDatePhilico)
                    );
                  })
                  .catch((error) => {
                    console.log(
                      "error put start date philico request: ",
                      error
                    );
                  });
              }
            }}
            onBlur={(e) => {
              // check if string is not empty
              if (e.target.value) {
                axiosInstance
                  .put("/cvapp/employee-profile/", {
                    startDatePhilico: e.target.value, //we need to transform javascript date object to date string, as Django expects the date in a string format
                  })
                  .then((response) => {
                    dispatch(
                      handleStartDateChange(response.data.startDatePhilico)
                    );
                  })
                  .catch((error) => {
                    console.log(
                      "error put start date philico request: ",
                      error
                    );
                  });
              }
            }}
            label="Start Date Philico"
            name="startDatePhilico"
            inputFormat="dd.MM.yyyy"
            mask="__.__.____"
            disableMaskedInput={false}
            value={parse(
              internalInfoState.startDatePhilico,
              "yyyy-MM-dd",
              new Date()
            )}
            helperText="dd.mm.yyyy"
            placeholder="dd.mm.yyyy"
          />
        </div>
        <div className="non-draggable contract-info-flex-item-4">
          <TextFieldCustomized
            value={internalInfoState.ahv}
            label="AHV"
            placeholder="756.2792.5290.99"
            // helperText="Here is some text "
            onChange={(e) => {
              dispatch(handleAHVChange(e.target.value));
            }}
            onBlur={(e) => {
              axiosInstance
                .put("/cvapp/employee-profile/", {
                  ahv: e.target.value,
                })
                .then((response) => {
                  dispatch(handleAHVChange(response.data.ahv));
                })
                .catch((error) => {
                  console.log("error put ahv request: ", error);
                });
            }}
          />
        </div>
        <div className="non-draggable contract-info-flex-item-5">
          <TextFieldCustomized
            value={internalInfoState.placeOfOrigin}
            label="Place of Origin"
            onChange={(e) => {
              dispatch(handlePlaceOfOriginChange(e.target.value));
            }}
            placeholder="Dietikon"
            onBlur={(e) => {
              axiosInstance
                .put("/cvapp/employee-profile/", {
                  placeOfOrigin: e.target.value,
                })
                .then((response) => {
                  dispatch(
                    handlePlaceOfOriginChange(response.data.placeOfOrigin)
                  );
                })
                .catch((error) => {
                  console.log("error put placeOfOrigin request: ", error);
                });
            }}
          />
        </div>
        <div className="non-draggable contract-info-flex-item-1">
          <SelectCustomized
            label="Type of Residence permit"
            select_options={[
              "Permit B",
              "Permit C",
              "Permit Ci",
              "Permit G",
              "Permit L",
              "Permit F",
              "Permit N",
              "Permit S",
            ]}
            values={[
              "Permit B",
              "Permit C",
              "Permit Ci",
              "Permit G",
              "Permit L",
              "Permit F",
              "Permit N",
              "Permit S",
            ]}
            value={internalInfoState.permit}
            onChange={(e) => {
              axiosInstance
                .put("/cvapp/employee-profile/", {
                  permit: e.target.value,
                })
                .then((response) => {
                  dispatch(handlePermitChange(response.data.permit));
                })
                .catch((error) => {
                  console.log("error put permit request: ", error);
                });
            }}
            fullWidth={true}
            letterSpacing={0}
            helperText="only needed from non swiss citizens"
          />
        </div>
        {/* <div className="non-draggable contract-info-flex-item-1"></div> */}
        <div className="non-draggable contract-info-flex-item-3">
          <FormGroup style={{ marginTop: "0px", marginBottom: "-10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={internalInfoState.subcontractor}
                  onChange={(e) => {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        subcontractor: e.target.checked,
                      })
                      .then((response) => {
                        dispatch(
                          handleSubcontractorChange(response.data.subcontractor)
                        );
                      })
                      .catch((error) => {
                        console.log(
                          "error put marital status request: ",
                          error
                        );
                      });
                  }}
                  name="subcontractor"
                  style={{ marginTop: "0px" }}
                />
              }
              label="Subcontractor"
              style={{ lineHeight: 1 }}
            />
          </FormGroup>
        </div>
        <div className="non-draggable contract-info-flex-item-3">
          <FormGroup style={{ marginTop: "0px", marginBottom: "-10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={internalInfoState.withholdingTax}
                  onChange={(e) => {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        withholdingTax: e.target.checked,
                      })
                      .then((response) => {
                        dispatch(
                          handleWitholdingTaxChange(
                            response.data.withholdingTax
                          )
                        );
                      })
                      .catch((error) => {
                        console.log(
                          "error put marital status request: ",
                          error
                        );
                      });
                  }}
                  // name="subcontractor"
                  style={{ marginTop: "0px" }}
                />
              }
              label="Subject to withholding tax"
              style={{ lineHeight: 1 }}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}

export default ContractInfoCard;
