import React from "react";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import deleteIconPopover from "../assets/delete_icon_popover.svg";

function PopoverDeleteConfirmation(props) {
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          overflow: "visible",
          marginTop: "5px",
          borderRadius: "8px",
        },
      }}>
      <Box
        sx={{
          position: "relative",
          overflow: "visible",
          "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            boxShadow: "-1px -3px 3px rgba(0, 0, 0, 0.15)",
            borderTop: "blue",
            width: 12,
            height: 12,
            top: -6,
            transform: "rotate(45deg)",
            left: "4%",
          },
        }}
      />
      <div className="grid-wrapper-delete-popover">
        <div className="grid-item-1-icon">
          <img src={deleteIconPopover} alt="delete symbol" />
        </div>
        <div className="grid-item-2-title">Deleting this experience?</div>
        <div className="grid-item-3-text">
          Deleting this experience will be permanent and cannot be undone!
        </div>
        <div className="grid-item-4-buttons">
          <Button
            className="deleteButton"
            variant="contained"
            onClick={props.handleDelete}>
            DELETE
          </Button>
          <Button
            variant="contained"
            className="cancelButton"
            onClick={props.handleCancel}>
            CANCEL
          </Button>
        </div>
      </div>
    </Popover>
  );
}

export default PopoverDeleteConfirmation;
