import React, { useEffect } from "react";
import SelectCustomized from "../../../components/input_components/SelectCustomized";
import TextFieldCustomized from "../../../components/input_components/TextFieldCustomized";
import DatePicker from "../../../components/input_components/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import { parse, isDate } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import {
  addLinkedContact,
  initalizeLinkedContacts,
  handleMaritalStatusChange,
  setIdEditLinkedContact,
} from "../../../features/personalinfoSlice";
import RelationshipsChildCard from "./RelationshipsChildCard";
import axiosInstance from "../../../axios/axiosConfig";
import moment from "moment";
import AddIconButton from "../../../components/input_components/AddIconButton";
import { Persist } from "formik-persist";
import { Element } from "react-scroll";
import {
  setAlertMessage,
  setIsFailAlertOpen,
  setIsSuccessAlertOpen,
} from "../../../features/alertMessageSlice";
import {
  RelationshipFailAlert,
  RelationshipSuccessAlert,
} from "../../../components/AlertMessages";

function RelationshipsParentCard(props) {
  // get personalinfo state
  const personlInfoSate = useSelector((state) => state.personalinfo);

  // create dispatch variable to access state reducers later
  const dispatch = useDispatch();

  // define validaiton schema for form
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Required!"),
    emc: Yup.boolean(),
    dateOfBirth: Yup.date()
      .transform(function (currentValue, originalValue) {
        // isDate checks if value is a date value (if not it tries to convert the string)
        const parsedDate = isDate(currentValue)
          ? currentValue
          : parse(currentValue, "dd-MM-yyyy", new Date());

        return parsedDate;
      })
      .typeError("Invalid format! Expected: dd-mm-yyyy")
      .required("Required!"),
    relation: Yup.string().required("Required!"),
    phoneEMC: Yup.string().when("emc", {
      is: true,
      then: Yup.string().required("Required!"),
      // otherwise: Yup.string().nullable("Required!"),
    }),
  });

  // define function that handles the edit of language skill card
  const handleLinkedContactEditOrDelete = (
    id,
    linkedContactObject,
    formik,
    method
  ) => {
    if (method === "EDIT") {
      // remove item that gets edited from array
      let updatedArray = personlInfoSate.linkedContacts.filter((contact) => {
        return contact.id !== id;
      });

      // update state
      dispatch(initalizeLinkedContacts(updatedArray));

      // store primary key of object that gets currently edited
      dispatch(setIdEditLinkedContact(id));

      // we need to transform date from string (how it is stored in Django database, to JavaScript date object that is compatible with Material-UI datepicker)
      // we check whether .dateOfBirth is string or not. If it is a string we transform it to a date object
      const transformedDate = isDate(linkedContactObject.dateOfBirth)
        ? linkedContactObject.dateOfBirth
        : parse(linkedContactObject.dateOfBirth, "dd-MM-yyyy", new Date());

      // set form values via formik
      formik.setValues({
        fullName: linkedContactObject.fullName,
        dateOfBirth: transformedDate,
        relation: linkedContactObject.relation,
        accompanyToEvents: linkedContactObject.willAccompanyToEvents,
        phoneEMC:
          linkedContactObject.phoneEMC === null
            ? ""
            : linkedContactObject.phoneEMC,
        emc: linkedContactObject.isEMC,
      });
    } else {
      // send a delete request to database
      axiosInstance
        .delete("/cvapp/linked-contacts/", {
          data: {
            id: id,
          },
        })
        .then((response) => {
          // response contains the remaining language skills objects
          dispatch(initalizeLinkedContacts(response.data));
        })
        .catch((error) => {
          console.log("error delete request: ", error);
        });
    }
  };

  useEffect(() => {
    // use effect that gets available linked contacts from db when component mounts
    axiosInstance
      .get("/cvapp/linked-contacts/")
      .then((response) => {
        dispatch(initalizeLinkedContacts(response.data));
      })
      .catch((error) => {
        console.log("error GET request: ", error);
      });

    // make request to employee profile for marital status
    axiosInstance
      .get("/cvapp/employee-profile/")
      .then((response) => {
        dispatch(handleMaritalStatusChange(response.data.maritalStatus));
      })
      .catch((error) => {
        console.log("error GET request employee-profile");
      });
  }, []);

  return (
    <div className="content-card" ref={props.innerRef}>
      <h1 className="content-card-title">Relationships</h1>
      <div className="marital-status-flexbox-wrapper">
        <div className="non-draggable marital-status-flex-item-1">
          <SelectCustomized
            label="Marital Status"
            select_options={[
              "Single",
              "Married",
              "Divorced",
              "Widowed",
              "Separated",
            ]}
            values={["Single", "Married", "Divorced", "Widowed", "Separated"]}
            value={personlInfoSate.maritalStatus}
            onChange={(e) => {
              axiosInstance
                .put("/cvapp/employee-profile/", {
                  maritalStatus: e.target.value,
                })
                .then((response) => {
                  dispatch(
                    handleMaritalStatusChange(response.data.maritalStatus)
                  );
                })
                .catch((error) => {
                  console.log("error put marital status request: ", error);
                });
            }}
            fullWidth={true}
            letterSpacing={0}
          />
        </div>
        {/* these two empty divs are need to make marital status the same length as the Full Name field */}
        <div className="non-draggable marital-status-flex-item-2"></div>
        <div className="non-draggable marital-status-flex-item-3"></div>
      </div>
      <h1 className="content-card-title add-a-person">
        Add a person (Emergency Contact, Child, Partner, ...)
      </h1>
      <Formik
        initialValues={{
          fullName: "",
          dateOfBirth: null,
          relation: "",
          phoneEMC: "",
          accompanyToEvents: false,
          emc: false,
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          // try to

          axiosInstance
            .put("/cvapp/linked-contacts/", {
              fullName: values.fullName,
              dateOfBirth: moment(values.dateOfBirth).format("DD-MM-YYYY"), //we need to transform javascript date object to date string, as Django expects the date in a string format
              relation: values.relation,
              willAccompanyToEvents: values.accompanyToEvents,
              isEMC: values.emc,
              phoneEMC: values.phoneEMC,
              id: personlInfoSate.idEditLinkedContact,
            })
            .then((response) => {
              dispatch(setIdEditLinkedContact(null));

              // open success alert for degrees
              dispatch(setIsSuccessAlertOpen(true));

              // set success alert message
              dispatch(setAlertMessage(RelationshipSuccessAlert));

              dispatch(addLinkedContact(response.data));
              actions.resetForm();
              // this is a workaround for formik-persists that sometimes fails to reset initial values; by manually setting the setSubmitting property we make sure that formik-persist object in local storage gets reset
              setTimeout(() => {
                actions.setSubmitting(false);
              }, 1000);
            })
            .catch((error) => {
              console.log("error of post linked contact: ", error);
              dispatch(setIsFailAlertOpen(true));
              dispatch(
                setAlertMessage(
                  `${RelationshipFailAlert} Error: ${error.response.status} ${error.response.statusText}`
                )
              );
            });
        }}>
        {(formik) => (
          <Element name="relationships-form-scroll-goal">
            <form onSubmit={formik.handleSubmit}>
              <div className="add-a-person-flexbox-wrapper">
                <div className="non-draggable add-person-flex-item-1">
                  <TextFieldCustomized
                    label="Full Name"
                    name="fullName"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    placeholder="Max Mustermann"
                    error={
                      formik.errors.fullName && formik.touched.fullName
                        ? true
                        : false
                    }
                    helperText={
                      formik.errors.fullName && formik.touched.fullName
                        ? formik.errors.fullName
                        : " "
                    }
                  />
                </div>
                <div className="non-draggable add-person-flex-item-2">
                  <DatePicker
                    onChange={(newValue) => {
                      formik.setFieldValue("dateOfBirth", newValue);
                    }}
                    label="Date of Birth"
                    name="dateOfBirth"
                    value={formik.values.dateOfBirth}
                    inputFormat="dd-MM-yyyy"
                    error={
                      formik.errors.dateOfBirth && formik.touched.dateOfBirth
                        ? true
                        : false
                    }
                    helperText={
                      formik.errors.dateOfBirth && formik.touched.dateOfBirth
                        ? formik.errors.dateOfBirth
                        : "dd-mm-yyyy"
                    }
                  />
                </div>
                <div className="non-draggable add-person-flex-item-3">
                  {" "}
                  <SelectCustomized
                    label="Relation"
                    error={
                      formik.errors.relation && formik.touched.relation
                        ? true
                        : false
                    }
                    helperText={
                      formik.errors.relation && formik.touched.relation
                        ? formik.errors.relation
                        : " "
                    }
                    select_options={[
                      "Spouse",
                      "Parent",
                      "Friend",
                      "Partner",
                      "Child",
                      "Other",
                    ]}
                    values={[
                      "Spouse",
                      "Parent",
                      "Friend",
                      "Partner",
                      "Child",
                      "Other",
                    ]}
                    value={formik.values.relation}
                    onChange={formik.handleChange}
                    name="relation"
                    // value={10}
                    fullWidth={true}
                    letterSpacing={0}
                  />
                </div>
                {formik.values.emc && (
                  <div className="non-draggable add-person-flex-item-3">
                    <TextFieldCustomized
                      label="Phone EMC"
                      name="phoneEMC"
                      // disabled={!formik.values.accompanyToEvents}
                      value={formik.values.phoneEMC}
                      onChange={formik.handleChange}
                      placeholder="+41797930763"
                      error={
                        formik.errors.phoneEMC && formik.touched.phoneEMC
                          ? true
                          : false
                      }
                      helperText={
                        formik.errors.phoneEMC && formik.touched.phoneEMC
                          ? formik.errors.phoneEMC
                          : " "
                      }
                    />
                  </div>
                )}
              </div>
              <div className="add-a-person-flexbox-wrapper-footer">
                <div className="non-draggable add-a-person-flex-footer-item-1">
                  <FormGroup
                    style={{ marginTop: "8px", marginBottom: "-10px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.accompanyToEvents}
                          onChange={formik.handleChange}
                          name="accompanyToEvents"
                        />
                      }
                      label="Will accompany me to events"
                      style={{ lineHeight: 1 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.emc}
                          onChange={formik.handleChange}
                          name="emc"
                        />
                      }
                      label="Emergency Contact (EMC)"
                      style={{ marginTop: "-12px" }}
                    />
                  </FormGroup>
                </div>
                <div className="non-draggable add-a-person-flex-footer-item-2">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<AddIconButton />}>
                    CONTACT
                  </Button>
                </div>
              </div>
              {personlInfoSate.linkedContacts.length > 0 ? (
                <div className="flex-box-wrapper-relationship-child-cards">
                  {personlInfoSate.linkedContacts.map((contactObject) => {
                    return (
                      <RelationshipsChildCard
                        key={contactObject.id}
                        linkedContactObject={contactObject}
                        handleEditOrDelete={handleLinkedContactEditOrDelete}
                        formik={formik}
                      />
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
              <Persist name="relationships-form" />
            </form>
          </Element>
        )}
      </Formik>
    </div>
  );
}

export default RelationshipsParentCard;
