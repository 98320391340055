import { createSlice } from "@reduxjs/toolkit";
import { convertToRaw, EditorState } from "draft-js";
import { resetPageLayout } from "../components/CardsLayoutsHelperFunctions";
import axiosInstance from "../axios/axiosConfig";

const initialState = {
  summaryType: "CV",
  textValueCvSummary: "",
  textValueWebSummary: "",
  cvSummaries: [],
  webSummaries: [],
  layout: [],
};

export const descriptionSlice = createSlice({
  name: "description",
  initialState,
  reducers: {
    changeRadioButton: (state, action) => {
      state.summaryType = action.payload;
    },
    setTextValueCvSummary: (state, action) => {
      state.textValueCvSummary = action.payload;
    },
    setTextValueWebSummary: (state, action) => {
      state.textValueWebSummary = action.payload;
    },
    resetTextValueCvSummary: (state, action) => {
      state.textValueCvSummary = JSON.stringify(
        convertToRaw(EditorState.createEmpty().getCurrentContent())
      );
    },
    resetTextValueWebSummary: (state, action) => {
      state.textValueWebSummary = JSON.stringify(
        convertToRaw(EditorState.createEmpty().getCurrentContent())
      );
    },
    addCvSummary: (state, action) => {
      state.cvSummaries.push(action.payload);
    },
    addWebSummary: (state, action) => {
      state.webSummaries.push(action.payload);
    },
    initalizeCVSummaries: (state, action) => {
      state.cvSummaries = action.payload;
    },
    initalizeWebSummaries: (state, action) => {
      state.webSummaries = action.payload;
    },
    initializeLayout: (state, action) => {
      state.layout = action.payload;
    },
    resetLayout: (state, action) => {
      let newLayout = resetPageLayout(state.layout);

      // reset local storage item that indicates whether error occurs or not
      localStorage.setItem("errorResetLayout", JSON.stringify(false));

      // update the cards via PUT request
      for (let j = 0; j < newLayout.length; j++) {
        axiosInstance
          .put("/cvapp/cards-layout/", newLayout[j])
          .catch((error) => {
            localStorage.setItem("errorResetLayout", JSON.stringify(true));
            console.log("error reset layout personalInfoSlice: ", error);
          });
      }

      state.layout = newLayout;
    },
  },
});

export const {
  changeRadioButton,
  setTextValueCvSummary,
  resetTextValueCvSummary,
  addCvSummary,
  initalizeCVSummaries,
  initalizeWebSummaries,
  setTextValueWebSummary,
  resetTextValueWebSummary,
  addWebSummary,
  initializeLayout,
  resetLayout,
} = descriptionSlice.actions;

export default descriptionSlice.reducer;
