import { useMaterialReactTable } from "material-react-table";
import moment from "moment";

export const tableColumns = () => {
  const columnsDefinition = [
    {
      header: "Date",
      accessorKey: "date",
      size: 20,
      sortingFn: "customDateOrdering",
    },
    {
      header: "Name",
      accessorKey: "name",
      size: 20,
      enableEditing: false,
    },
  ];

  return columnsDefinition;
};

export const useReactTable = (columns, data) => {
  const table = useMaterialReactTable({
    columns,
    data,
    sortingFns: {
      customDateOrdering: (rowA, rowB, columnId) => {
        let dateA = moment(rowA.original.date, "DD-MM-YYYY");
        let dateB = moment(rowB.original.date, "DD-MM-YYYY");

        //sorting docs: https://tanstack.com/table/v8/docs/api/features/sorting#sorting-functions
        return dateA < dateB ? -1 : 1;
      },
    },
    renderColumnActionsMenuItems: ({ internalColumnMenuItems, additional }) => {
      internalColumnMenuItems = internalColumnMenuItems.filter(
        (menuItemObj) => {
          return (
            !menuItemObj.props.label.includes("Sort by") &&
            !menuItemObj.props.label.includes("Hide")
          );
        }
      );

      return [...internalColumnMenuItems];
    },

    editDisplayMode: "modal",
    enableGrouping: true,
    enableSorting: true,
    enableColumnDragging: false,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableRowActions: false,
    initialState: {
      sorting: [
        {
          id: "date", //sort by age by default on page load
          desc: false,
        },
      ],
    },
    muiTableContainerProps: { sx: { margin: "0px", flexGrow: 1 } },
  });

  return table;
};
