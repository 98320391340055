import React from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

function ActionGroupElement(props) {
  return (
    <div
      className="grid-item-action-group"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "6px",
        cursor: "pointer",
      }}>
      {props.showProgressIcon ? (
        <CircularProgress
          //   style={{ width: "24px", height: "24px", marginRight: "16px" }}
          style={{ padding: "13px" }}
        />
      ) : (
        <IconButton
          style={{
            position: "relative",
            padding: "16px",
          }}
          onClick={props.handleClick}
        >
          <img className="action-group-img" src={props.icon} />
        </IconButton>
      )}
      {/* <div style={{ height: "40px" }}>
        {" "}
        <img src={props.icon} />
      </div> */}
      <div
        style={{
          marginTop: "-8px",
          fontSize: "13px",
          maxWidth: "120px",
          textAlign: "center",
          textWrap: "wrap",
        }}>
        {props.title}
      </div>
    </div>
  );
}

export default ActionGroupElement;
