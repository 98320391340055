import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  changeAddressComponent,
  changeContactDetails,
  changeNationality,
  changeLinkedinUrl,
  initalizeAddressFields,
  initalizeContactDetails,
  initializeCompleteAddressSet,
  isAddressSet,
  syncCompleteAddress,
} from "../../../../features/profileinfoSlice";
import axiosInstance from "../../../../axios/axiosConfig";
import { parse } from "date-fns";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MuiTelInput } from "mui-tel-input";
import { changeCompleteAddress } from "../../../../features/profileinfoSlice";
import { extractGooglePlacesAddress } from "../../../../components/extractGooglePlacesAddress";
import { handlePositionChange } from "../../../../features/internalinfoSlice";

function PersonalInfo() {
  const profileInfoState = useSelector((state) => state.profileinfo);

  // we set a timeout to wait until page is fully rendered until we compute length of text fields
  const timeoutWidthComputation = 300;

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const nationalityRef = useRef(null);
  const linkedinUrlRef = useRef(null);
  const birthdayRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const completeAddressRef = useRef(null);
  const streetRef = useRef(null);
  const streetNrRef = useRef(null);
  const zipRef = useRef(null);
  const cityRef = useRef(null);
  const countryRef = useRef(null);
  const positionRef = useRef(null);
  const googlePlacesRef = useRef(null);
  const googlePlacesTextFieldRef = useRef(null);

  const [firstNameWidth, setFirstNameWidth] = useState(0);
  const [lastNameWidth, setlastNameWidth] = useState(0);
  const [nationalityWidth, setNationalityWidth] = useState(0);
  const [linkedinUrlWidth, setLinkedinUrlWidth] = useState(0);
  const [emailWidth, setEmailWidth] = useState(0);
  const [birthDateWidth, setBirthDateWidth] = useState(0);
  const [positionWidth, setPositionWidth] = useState(0);
  const [phoneWidth, setPhoneWidth] = useState(0);
  const [completeAddressWidth, setCompleteAddressWidth] = useState(0);
  const [streetWidth, setStreetWidth] = useState(0);
  const [streetNrWidth, setStreetNrWidth] = useState(0);
  const [zipWidth, setZipWidth] = useState(0);
  const [cityWidth, setCityWidth] = useState(0);
  const [countryWidth, setCountryWidth] = useState(0);

  const dispatch = useDispatch();

  // define search options for google places autocomplete
  const options = {
    componentRestrictions: { country: ["ch", "de", "fr"] },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };

  // define Google-API-Keys for places autocomplete and geolocation
  const apiKey = "AIzaSyCf27teJ8e4rglWcKY00wJWGhDYouvbf9g";
  const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

  // create listener to google places autocomplete when component mounts
  useEffect(() => {
    googlePlacesRef.current = new window.google.maps.places.Autocomplete(
      googlePlacesTextFieldRef.current,
      options
    );

    // add a listener to autocomplete that we notice when address changes
    googlePlacesRef.current.addListener("place_changed", () => {
      const place = googlePlacesRef.current.getPlace();
      if (typeof place !== "undefined") {
        var address = extractGooglePlacesAddress(place.address_components);
        console.log("this is google address: ", address);
        axiosInstance
          .put("/cvapp/employee-profile/", {
            ...address,
          })
          .then((response) => {
            //   this will remove google places field
            // setIsAddressPresent(true);

            // destructure the array with only the properties that you need; we create an arrow function which we then directly call with the response.data object as an argument
            const addressFields = (({
              city,
              country,
              street,
              streetNr,
              zip,
            }) => ({ city, country, street, streetNr, zip }))(response.data);

            dispatch(initalizeAddressFields(addressFields));
            dispatch(initializeCompleteAddressSet(addressFields));
          });
      }
    });
    // eslint-disable-next-line
  }, []);

  // create a hook that syncs complete address fields and individual fields
  useEffect(() => {
    dispatch(syncCompleteAddress("sync"));

    //   check if address is set
    if (
      profileInfoState.address.street ||
      profileInfoState.address.zip ||
      profileInfoState.address.streetNr ||
      profileInfoState.address.city ||
      profileInfoState.address.country
    ) {
      dispatch(isAddressSet(true));
      // we need this condition profileInfoState.isAddressSet, otherwise on initial render, when address is not yet set, we override db with empty address
    } else if (profileInfoState.isAddressSet) {
      dispatch(isAddressSet(false));

      // as we write to the db onBlur for address field, when content of last filled address field is erased, fields are no longer rendered --> onBlur will not trigger; therefore we need to write here to db
      axiosInstance
        .put("/cvapp/employee-profile/", {
          ...profileInfoState.address,
        })
        .catch((error) => {
          console.log("error resseting address, addressCard.jsx: ", error);
        });
    }
  }, [profileInfoState.address]);

  useLayoutEffect(() => {
    // get contact details information
    axiosInstance
      .get("/cvapp/employee-profile/")
      .then((response) => {
        // destructure the array with only the properties that you need; we create an arrow function which we then directly call with the response.data object as an argument
        const contactDetails = (({
          firstName,
          lastName,
          email,
          phone,
          countryCode,
          birthDate,
        }) => ({
          firstName,
          lastName,
          email,
          phone,
          countryCode,
          birthDate,
        }))(response.data);
        // initalize the contact details state with the db values
        dispatch(initalizeContactDetails(contactDetails));
        dispatch(changeNationality(response.data.nationality));
        dispatch(changeLinkedinUrl(response.data.linkedinUrl));
        dispatch(handlePositionChange(response.data.positionWithinPhilico));
      })
      .catch((error) => {
        console.log("error updating first name contact details: ", error);
      });
  }, []);

  // use the useLayoutEffect in order to make sure that this gets executed before the other useEffect (can happen that if the other useEffect gets executed first and no state is set yet, it will execute the empty PUT request before the get request)
  useLayoutEffect(() => {
    axiosInstance
      .get("/cvapp/employee-profile/")
      .then((response) => {
        // destructure the array with only the properties that you need; we create an arrow function which we then directly call with the response.data object as an argument
        const addressFields = (({ city, country, street, streetNr, zip }) => ({
          city,
          country,
          street,
          streetNr,
          zip,
        }))(response.data);

        dispatch(initalizeAddressFields(addressFields));
        dispatch(initializeCompleteAddressSet(addressFields));
      })
      .catch((error) => {
        console.log("error GET request: ", error);
      });
  }, []);

  //   const regexPhoneNumberCountryCode = new RegExp("^[+][0-9]{2,5}");
  const phoneNumberCountryCodePattern = /^[+][0-9]{2,5}/;

  // layout effect that listens to changes in first name in order to scale the input field accordingly
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setNationalityWidth(nationalityRef.current.offsetWidth + 2 + 1);
    }, timeoutWidthComputation);

    // console.log("we are in useLayoutEffect nationality");
    setNationalityWidth(nationalityRef.current.offsetWidth + 2 + 1); //4 is the padding-left + padding-right

    return () => clearTimeout(timer);
  }, [profileInfoState.nationality]);

  // layout effect that listens to changes in linkedin profile url in order to scale the input field accordingly
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setLinkedinUrlWidth(linkedinUrlRef.current.offsetWidth + 2 + 1);
    }, timeoutWidthComputation);

    // console.log("we are in useLayoutEffect nationality");
    setLinkedinUrlWidth(linkedinUrlRef.current.offsetWidth + 2 + 1); //4 is the padding-left + padding-right

    return () => clearTimeout(timer);
  }, [profileInfoState.linkedinUrl]);

  // layout effect that listens to changes in first name in order to scale the input field accordingly
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setBirthDateWidth(birthdayRef.current.offsetWidth + 4);
    }, timeoutWidthComputation);

    setBirthDateWidth(birthdayRef.current.offsetWidth + 4); //4 is the padding-left + padding-right
    return () => clearTimeout(timer);
  }, [profileInfoState.contactDetails.birthDate]);

  // layout effect that listens to changes in first name in order to scale the input field accordingly
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setPhoneWidth(phoneRef.current.offsetWidth + 4 + 1 + 70);
    }, 150);

    setPhoneWidth(phoneRef.current.offsetWidth + 4 + 1 + 70); //4 is the padding-left + padding-right
    return () => clearTimeout(timer);
  }, [profileInfoState.contactDetails.phone]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setEmailWidth(emailRef.current.offsetWidth + 4 + 1);
    }, timeoutWidthComputation);

    setEmailWidth(emailRef.current.offsetWidth + 4 + 1); //4 is the padding-left + padding-right
    return () => clearTimeout(timer);
  }, [profileInfoState.contactDetails.email]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setCompleteAddressWidth(completeAddressRef.current.offsetWidth + 2);
      setStreetWidth(streetRef.current.offsetWidth + 2);
      setStreetNrWidth(streetNrRef.current.offsetWidth + 2);
      setZipWidth(zipRef.current.offsetWidth + 2);
      setCityWidth(cityRef.current.offsetWidth + 2);
      setCountryWidth(countryRef.current.offsetWidth + 2);
    }, timeoutWidthComputation);

    setCompleteAddressWidth(completeAddressRef.current.offsetWidth + 2);
    setStreetWidth(streetRef.current.offsetWidth + 2);
    setStreetNrWidth(streetNrRef.current.offsetWidth + 2);
    setZipWidth(zipRef.current.offsetWidth + 2);
    setCityWidth(cityRef.current.offsetWidth + 2);
    setCountryWidth(countryRef.current.offsetWidth + 2); //4 is the padding-left + padding-right
    return () => clearTimeout(timer);
  }, [profileInfoState.address]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "32px",
          marginTop: "-16px",
        }}>
        {" "}
        <div className="dynamic-cv-personal-info-grid-wrapper">
          <div className="left-col-nationality-2nd">Nationality</div>
          <div className="right-col-nationality">
            <OutlinedInput
              placeholder="Nationality"
              className="outlinedinput-dynamic-cv header-text"
              value={profileInfoState.nationality}
              sx={{ width: `${nationalityWidth}px` }}
              onChange={(e) => dispatch(changeNationality(e.target.value))}
              onBlur={(e) => {
                console.log("on blur nationaliy");
                axiosInstance
                  .put("/cvapp/employee-profile/", {
                    nationality: e.target.value, //we need to transform javascript date object to date string, as Django expects the date in a string format
                  })
                  .then((response) => {
                    dispatch(changeNationality(response.data.nationality));
                  })
                  .catch((error) => {
                    console.log("error change nationality: ", error);
                  });
              }}
            />
          </div>
          <div className="left-col-birth-2nd">Date of Birth</div>
          <div className="right-col-birth">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                className="outlinedinput-dynamic-cv header-text"
                value={parse(
                  profileInfoState.contactDetails.birthDate,
                  "yyyy-MM-dd",
                  new Date()
                )}
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                disableMaskedInput={false}
                onChange={(newValue) => {
                  let transformedDate = moment(newValue).format("YYYY-MM-DD");
                  if (transformedDate !== "Invalid date") {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        birthDate: transformedDate,
                      })
                      .then((response) => {
                        dispatch(
                          changeContactDetails({
                            property: "birthDate",
                            value: response.data.birthDate,
                          })
                        );
                      })
                      .catch((error) => {
                        console.log(
                          "error updating birth date contact details: ",
                          error
                        );
                      });
                  }
                }}
                onBlur={(e) => {
                  // check if string is not empty
                  if (e.target.value) {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        birthDate: e.target.value, //we need to transform javascript date object to date string, as Django expects the date in a string format
                      })
                      .then((response) => {
                        dispatch(
                          changeContactDetails({
                            property: "birthDate",
                            value: response.data.birthDate,
                          })
                        );
                      })
                      .catch((error) => {
                        console.log(
                          "error put start date philico request: ",
                          error
                        );
                      });
                  }
                }}
                disableOpenPicker
                renderInput={(params) => {
                  //add style prop to increase font-size
                  return (
                    <OutlinedInput
                      {...params}
                      inputRef={params.inputRef}
                      //   helperText="dd.mm.yyyy"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd.mm.yyyy",
                      }}
                      sx={{ width: `${birthDateWidth}px` }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="linkedin-left-col">LinkedIn URL</div>
          <div className="right-col-linkedin">
            <OutlinedInput
              placeholder="URL LinkedIn Profile"
              className="outlinedinput-dynamic-cv header-text"
              value={
                profileInfoState.linkedinUrl ? profileInfoState.linkedinUrl : ""
              }
              sx={{ width: `${linkedinUrlWidth}px` }}
              onChange={(e) => dispatch(changeLinkedinUrl(e.target.value))}
              onBlur={(e) => {
                axiosInstance
                  .put("/cvapp/employee-profile/", {
                    linkedinUrl: e.target.value, //we need to transform javascript date object to date string, as Django expects the date in a string format
                  })
                  .then((response) => {
                    dispatch(changeLinkedinUrl(response.data.linkedinUrl));
                  })
                  .catch((error) => {
                    console.log("error change linkedInUrl: ", error);
                  });
              }}
            />
          </div>
          <div className="left-col-contact-2nd">Contact</div>
          <div className="right-col-contact">
            {" "}
            <MuiTelInput
              className="textfield-dynamic-cv mobile-phone-input"
              flagSize="small"
              sx={{ width: `${phoneWidth}px` }}
              placeholder="79 793 07 63" //careful with changing this placeholder; css style depends on it input[placeholder="79 793 07 63"]
              forceCallingCode
              preferredCountries={["CH", "DE", "FR"]}
              value={profileInfoState.contactDetails.phone}
              onChange={(newValue, info) => {
                dispatch(
                  changeContactDetails({
                    property: "phone",
                    value: newValue,
                  })
                );

                dispatch(
                  changeContactDetails({
                    property: "countryCode",
                    value: info.countryCode,
                  })
                );
              }}
              variant="outlined"
              defaultCountry="CH"
              onBlur={(e) => {
                axiosInstance
                  .put("/cvapp/employee-profile/", {
                    phone: profileInfoState.contactDetails.phone,
                    countryCode: profileInfoState.contactDetails.countryCode,
                  })
                  .then((response) => {
                    dispatch(
                      changeContactDetails({
                        property: "phone",
                        value: response.data.phone,
                      })
                    );
                    dispatch(
                      changeContactDetails({
                        property: "countryCode",
                        value: response.data.countryCode,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log(
                      "error updating first name contact details: ",
                      error
                    );
                  });
              }}
            />
          </div>
          <div className="right-col2">
            <OutlinedInput
              placeholder="max.mustermann@philico.com"
              className="outlinedinput-dynamic-cv header-text"
              value={profileInfoState.contactDetails.email}
              sx={{ width: `${emailWidth}px` }}
              onChange={(e) =>
                dispatch(
                  changeContactDetails({
                    property: "email",
                    value: e.target.value,
                  })
                )
              }
              onBlur={(e) => {
                axiosInstance
                  .put("/cvapp/employee-profile/", {
                    email: e.target.value,
                  })
                  .then((response) => {
                    dispatch(
                      changeContactDetails({
                        property: "email",
                        value: response.data.email,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log(
                      "error updating first name contact details: ",
                      error
                    );
                  });
              }}
            />
          </div>
          <div className="right-col3">
            <OutlinedInput
              placeholder="Start typing your address ..."
              className="outlinedinput-dynamic-cv header-text"
              sx={{ width: `${completeAddressWidth}px` }}
              inputRef={googlePlacesTextFieldRef}
              value={profileInfoState.addressComplete}
              onChange={(e) => {
                dispatch(changeCompleteAddress(e.target.value));
              }}
              onBlur={() =>
                setTimeout(() => {
                  // we set timeout in order to give google places time to update;
                  // in case that the entered address and individual fields are not the same it means they are out of sync; in that case we reset them
                  const constructedAddress = profileInfoState.address.street
                    .trim()
                    .concat(
                      " ",
                      profileInfoState.address.streetNr,
                      ", ",
                      profileInfoState.address.city,
                      ", ",
                      profileInfoState.address.country
                    );

                  if (constructedAddress !== profileInfoState.addressComplete) {
                    console.log("we reset address:");
                    dispatch(syncCompleteAddress("syncing"));
                  }
                }, 300)
              }
            />
          </div>
          <div className="right-col4">
            {profileInfoState.isAddressSet && (
              <>
                <OutlinedInput
                  className="outlinedinput-dynamic-cv header-text"
                  placeholder="Street"
                  value={profileInfoState.address.street}
                  onChange={(e) =>
                    dispatch(
                      changeAddressComponent({
                        property: "street",
                        value: e.target.value,
                      })
                    )
                  }
                  onBlur={(e) => {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        street: e.target.value,
                      })
                      .then((response) => {
                        dispatch(
                          changeAddressComponent({
                            property: "street",
                            value: e.target.value,
                          })
                        );
                      })
                      .catch((error) => {
                        console.log("error updating street: ", error);
                      });
                  }}
                  sx={{ width: `${streetWidth}px` }}
                />
                <OutlinedInput
                  className="outlinedinput-dynamic-cv header-text"
                  placeholder="Street Nr."
                  value={profileInfoState.address.streetNr}
                  onChange={(e) =>
                    dispatch(
                      changeAddressComponent({
                        property: "streetNr",
                        value: e.target.value,
                      })
                    )
                  }
                  onBlur={(e) => {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        streetNr: e.target.value,
                      })
                      .then((response) => {
                        dispatch(
                          changeAddressComponent({
                            property: "streetNr",
                            value: e.target.value,
                          })
                        );
                      })
                      .catch((error) => {
                        console.log("error updating street nr: ", error);
                      });
                  }}
                  sx={{ width: `${streetNrWidth}px` }}
                />
              </>
            )}
          </div>
          <div className="right-col5">
            {profileInfoState.isAddressSet && (
              <>
                <OutlinedInput
                  className="outlinedinput-dynamic-cv header-text"
                  placeholder="ZIP"
                  value={profileInfoState.address.zip}
                  onChange={(e) =>
                    dispatch(
                      changeAddressComponent({
                        property: "zip",
                        value: e.target.value,
                      })
                    )
                  }
                  onBlur={(e) => {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        zip: e.target.value,
                      })
                      .then((response) => {
                        dispatch(
                          changeAddressComponent({
                            property: "zip",
                            value: e.target.value,
                          })
                        );
                      })
                      .catch((error) => {
                        console.log("error updating zip: ", error);
                      });
                  }}
                  sx={{ width: `${zipWidth}px` }}
                />
                <OutlinedInput
                  className="outlinedinput-dynamic-cv header-text"
                  placeholder="City"
                  value={profileInfoState.address.city}
                  onChange={(e) =>
                    dispatch(
                      changeAddressComponent({
                        property: "city",
                        value: e.target.value,
                      })
                    )
                  }
                  onBlur={(e) => {
                    axiosInstance
                      .put("/cvapp/employee-profile/", {
                        city: e.target.value,
                      })
                      .then((response) => {
                        dispatch(
                          changeAddressComponent({
                            property: "city",
                            value: e.target.value,
                          })
                        );
                      })
                      .catch((error) => {
                        console.log("error updating city: ", error);
                      });
                  }}
                  sx={{ width: `${cityWidth}px` }}
                />
              </>
            )}
          </div>
          <div className="right-col6">
            {profileInfoState.isAddressSet && (
              <OutlinedInput
                className="outlinedinput-dynamic-cv header-text"
                placeholder="Country"
                value={profileInfoState.address.country}
                onChange={(e) =>
                  dispatch(
                    changeAddressComponent({
                      property: "country",
                      value: e.target.value,
                    })
                  )
                }
                onBlur={(e) => {
                  axiosInstance
                    .put("/cvapp/employee-profile/", {
                      country: e.target.value,
                    })
                    .then((response) => {
                      dispatch(
                        changeAddressComponent({
                          property: "country",
                          value: e.target.value,
                        })
                      );
                    })
                    .catch((error) => {
                      console.log("error updating country: ", error);
                    });
                }}
                sx={{ width: `${countryWidth}px` }}
              />
            )}
          </div>
        </div>
      </div>
      <div ref={nationalityRef} id="nationality-measure">
        {profileInfoState.nationality
          ? profileInfoState.nationality
          : "Nationality"}
      </div>
      <div ref={linkedinUrlRef} id="linkedinUrl-measure">
        {profileInfoState.linkedinUrl
          ? profileInfoState.linkedinUrl
          : "URL LinkedIn Profile"}
      </div>
      <div ref={birthdayRef} id="birthday-measure">
        {profileInfoState.contactDetails.birthDate
          ? profileInfoState.contactDetails.birthDate
          : "dd.mm.yyyy"}
      </div>
      <div ref={phoneRef} id="phone-measure">
        {profileInfoState.contactDetails.phone &&
        phoneNumberCountryCodePattern.test(
          profileInfoState.contactDetails.phone
        ) === false
          ? profileInfoState.contactDetails.phone
          : "79 793 07 63"}
      </div>
      <div ref={emailRef} id="email-measure">
        {profileInfoState.contactDetails.email
          ? profileInfoState.contactDetails.email
          : "max.mustermann@philico.com"}
      </div>
      <div ref={completeAddressRef} id="complete-address-measure">
        {profileInfoState.addressComplete
          ? profileInfoState.addressComplete
          : "Start typing your address ..."}
      </div>
      <div ref={streetRef} id="street-measure">
        {profileInfoState.address.street
          ? profileInfoState.address.street
          : "Street"}
      </div>
      <div ref={streetNrRef} id="street-nr-measure">
        {profileInfoState.address.streetNr
          ? profileInfoState.address.streetNr
          : "Street Nr."}
      </div>
      <div ref={zipRef} id="zip-measure">
        {profileInfoState.address.zip ? profileInfoState.address.zip : "ZIP"}
      </div>
      <div ref={cityRef} id="city-measure">
        {profileInfoState.address.city ? profileInfoState.address.city : "ZIP"}
      </div>
      <div ref={countryRef} id="country-measure">
        {profileInfoState.address.country
          ? profileInfoState.address.country
          : "Country"}
      </div>
    </>
  );
}

export default PersonalInfo;
