import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataWorkflow: {},
  isDataApproving: false,
  isDataUpdating: false,
};

export const cvDataWorkflowSlice = createSlice({
  name: "cvdataworkflow",
  initialState,
  reducers: {
    initalizeDataWorkflowObject: (state, action) => {
      state.dataWorkflow = action.payload;
    },
    setIsDataApproving: (state, action) => {
      state.isDataApproving = action.payload;
    },
    setIsDataUpdating: (state, action) => {
      state.isDataUpdating = action.payload;
    },
  },
});

export const {
  initalizeDataWorkflowObject,
  setIsDataApproving,
  setIsDataUpdating,
} = cvDataWorkflowSlice.actions;

export default cvDataWorkflowSlice.reducer;
