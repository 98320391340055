import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pdfURL: "",
};

export const createcvSlice = createSlice({
  name: "createcv",
  initialState,
  reducers: {
    changePdfURL: (state, action) => {
      state.pdfURL = action.payload;
    },
  },
});

export const { changePdfURL } = createcvSlice.actions;

export default createcvSlice.reducer;
