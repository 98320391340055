import React from "react";
import Header from "../../components/Header";
import ContentSkillsMatrixPage from "./ContentSkillsMatrixPage";

function SkillsMatrixPage() {
  return (
    <div className="outer-div-content-wrapper">
      <div className="back-button-navigation-grid-item-1"></div>
      <div className="main-content-wrapper">
        <Header />
        <ContentSkillsMatrixPage />
      </div>
    </div>
  );
}

export default SkillsMatrixPage;
