import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
function FailAlert(props) {
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  const dispatch = useDispatch();

  return (
    <Snackbar
      open={props.openState}
      autoHideDuration={6000}
      disableWindowBlurListener={true}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={TransitionUp}
      onClose={(event, reason) => {
        console.log("reason fail snackbar on close: ", reason);
        if (reason === "clickaway") {
          return;
        }
        dispatch(props.stateHandlerFunction(false));
      }}
      style={{ maxWidth: "300px" }}>
      <Alert severity="error" className="error-alert">
        {/* <AlertTitle>Error</AlertTitle> */}
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export default FailAlert;
