import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setSelectedYear,
  setTriggerLeaveRequestDataFetch,
} from "../../../../features/leaveRequestSlice";
import { useSelector } from "react-redux";
import { ButtonGroup, Button, Tooltip } from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import SubmitDialog from "../HistoryCard/SubmitDialog";
import axiosInstance from "../../../../axios/axiosConfig";

function HistoryCard(props) {
  const leaveRequestState = useSelector((state) => state.leaverequest);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);

  // get current year
  let currentYear = new Date().getFullYear();
  const dispatch = useDispatch();

  // create array with current year, current year -1 and current year + 1
  let years = [currentYear + 1, currentYear];

  useEffect(() => {
    dispatch(setSelectedYear(new Date().getFullYear()));
  }, []);

  const handleDialogClose = () => {
    setOpenSubmitDialog(false);
  };

  const handleSubmitCallback = () => {
    // submit leave requests
    axiosInstance
      .put(
        "/timemate/leaverequest-status/",
        {
          status: "Submitted",
        },
        {
          params: {
            year: leaveRequestState.selectedYear,
          },
        }
      )
      .then((response) => {
        // reload leave request data
        dispatch(
          setTriggerLeaveRequestDataFetch(
            !leaveRequestState.triggerLeaveRequestDataFetch
          )
        );
        setOpenSubmitDialog(false);
      })
      .catch((error) => {
        console.log("error submitting data");
      });
  };

  return (
    <div className="history-button-group">
      <span className="title-text-history">History</span>
      {years.map((year, index) => {
        return (
          <ButtonGroup fullWidth={true} variant="contained" key={index}>
            <Button
              variant="contained"
              className={
                "history-button " +
                (leaveRequestState.selectedYear === year
                  ? "active-history-button"
                  : "inactive-history-button")
              }
              onClick={() => dispatch(setSelectedYear(year))}>
              {year}
            </Button>
            <Tooltip
              disableFocusListener
              title="Submit"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [60, -50],
                    },
                  },
                ],
              }}>
              <Button
                disabled={
                  leaveRequestState.selectedYear !== year ||
                  props.disableSubmitButton
                }
                size="small"
                className={
                  "history-button " +
                  (leaveRequestState.selectedYear === year
                    ? "active-history-button"
                    : "inactive-history-button")
                }
                onClick={() => setOpenSubmitDialog(true)}
                sx={{
                  display: "flex",
                  width: 0.25,
                }}>
                <ForwardIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        );
      })}
      <SubmitDialog
        open={openSubmitDialog}
        handleClose={handleDialogClose}
        title="Submit Leave Requests?"
        body="Submitting the leave requests will submit all pending and rejected leave requests and notify your approver. You will not be able to make any more adjustments to your leave requests."
        handleSubmit={handleSubmitCallback}
      />
    </div>
  );
}

export default HistoryCard;
