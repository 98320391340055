import { defaultLayouts } from "./CardsDefaultLayout";
import { resetLayout as resetLayoutPage1 } from "../features/profileinfoSlice";
import { resetLayout as resetLayoutPage2 } from "../features/personalinfoSlice";
import { resetLayout as resetLayoutPage3 } from "../features/internalinfoSlice";
import { resetLayout as resetLayoutPage4 } from "../features/educationSlice";
import { resetLayout as resetLayoutPage5 } from "../features/experienceSlice";
import { resetLayout as resetLayoutPage6 } from "../features/descriptionSlice";

function initializeCardLayouts(dbLayouts, cardNames) {
  /* Function that initialize the card layouts for the different pages

para dbLayouts:
    - type: array of layout objects
    - descn: array of layouts that are already present in database; this is response of the following GET request: axiosInstance.get("/cvapp/cards-layout/");

para cardNames:
    - type: array of strings
    - descn: array of card names that should be initalized; Example: ['contractInfoCard', 'ioDocumentCard', 'bankInfoCard']
*/
  var initializedLayout = dbLayouts.filter((card) => {
    // check if element in present in the cardNames array; in case the return value of indexOf is not equal to -1 means that string is present in cardNames array
    let index = cardNames.indexOf(card.i);
    if (index !== -1) {
      // remove card name that was found in db data
      cardNames.splice(index, 1);
      return card;
    }
  });

  // check which cardnames are not yet present in db data but are requested in cardNames
  for (var i = 0; i < cardNames.length; i++) {
    initializedLayout.push(defaultLayouts[cardNames[i]]);
  }

  return initializedLayout;
}

function adjustCardHeights(layout, adjustedHeights) {
  /* Function that adjusts the card height to the content of the card
    
    para layout:
        - type: array of layout object
        - descn: contains layout objects ({i, x, y, ..}) where the h, minH, maxH will be adjusted
    
    para adjustedHeights:
        - type: object
        - descn: contains the desired heights of the cards; example: {contractInfoCard: 457, bankInfoCard: 341, idDocumentCard: 383}
    */

  var adjustedLayout = layout.map((layoutObj) => {
    // check if card is present in given adjustedHeights object
    if (adjustedHeights.hasOwnProperty(layoutObj.i)) {
      return {
        ...layoutObj,
        h: adjustedHeights[layoutObj.i],
        minH: adjustedHeights[layoutObj.i],
        maxH: adjustedHeights[layoutObj.i],
      };
    }
  });

  return adjustedLayout;
}
function resetPageLayout(layout) {
  /* Function that resets layout for certain page. This function is called within the slices (see features folders) */
  let resetLayout = layout.map((card) => {
    console.log("card.i: ", card.i);
    return {
      ...card,
      x: defaultLayouts[card.i].x,
      y: defaultLayouts[card.i].y,
      w: defaultLayouts[card.i].w,
    };
  });
  return resetLayout;
}

function SelectResetFunction(page) {
  let resetFunction;
  switch (page) {
    case "/profile":
      // resetFunction = resetLayoutPage1;
      resetFunction = resetLayoutPage3;
      break;
    case "/create-cv":
      resetFunction = resetLayoutPage3;
      break;
    // case "/personal":
    //   resetFunction = resetLayoutPage2;
    //   break;
    // case "/internal":
    //   resetFunction = resetLayoutPage3;
    //   break;
    // case "/education":
    //   resetFunction = resetLayoutPage4;
    //   break;
    // case "/experience":
    //   resetFunction = resetLayoutPage5;
    //   break;
    // case "/description":
    //   resetFunction = resetLayoutPage6;
    //   break;
  }

  // return [resettedLayout, resetFunction];
  return resetFunction;
}

// function getCardHeights(page) {

// }

export {
  initializeCardLayouts,
  adjustCardHeights,
  SelectResetFunction,
  resetPageLayout,
};
