import React, { useEffect, useState } from "react";
import { useStylesApprovalCard } from "../EmployeeApprovalCard/EmployeeApprovalCard";
import Typography from "@mui/material/Typography";
import ApprovedIcon from "../EmployeeApprovalCard/ApprovedIconInverted.svg";
import PendingIcon from "../EmployeeApprovalCard/PendingIcon.svg";
import SubmittedIcon from "../EmployeeApprovalCard/SubmittedIcon.svg";
import RejectedIcon from "../EmployeeApprovalCard/RejectedIcon.svg";
import { getUserFullName } from "../../helpers";
import axiosInstance from "../../../../axios/axiosConfig";
import ApprovalDialogWindow from "./ApprovalDialogWindow";
import { useDispatch } from "react-redux";
import {
  initalizeApprovedCheckedLeaveReq,
  initalizePendingCheckedLeaveReq,
  initalizeRejectedCheckedLeaveReq,
  setApprovedLeaveReqData,
  setRejectedLeaveReqData,
  setSubmittedLeaveReqData,
} from "../../../../features/leaveRequestSlice";
import { useSelector } from "react-redux";

function ApprovalCard(props) {
  const leaveRequestState = useSelector((state) => state.leaverequest);

  const [employeeFullName, setEmployeeFullName] = useState();
  const [isApproved, setIsApproved] = useState(false);
  const [cardIcon, setCardIcon] = useState(PendingIcon);
  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getUserFullName(setEmployeeFullName, props.employeeId);
  }, []);

  useEffect(() => {
    axiosInstance
      .get("/timemate/leaverequest-status/", {
        params: {
          year: props.year,
          managed_employee: props.employeeId,
        },
      })
      .then((response) => {
        switch (response.data.status.toLowerCase()) {
          case "approved":
            setIsApproved(true);
            setCardIcon(ApprovedIcon);
            break;
          case "submitted":
            setIsApproved(false);
            setCardIcon(SubmittedIcon);
            break;
          case "rejected":
            setIsApproved(false);
            setCardIcon(RejectedIcon);
            break;
          default:
            setIsApproved(false);
            setCardIcon(PendingIcon);
            break;
        }
      });
  }, [props.year, leaveRequestState.approverAction]);

  const classes = useStylesApprovalCard();

  const openDialogCallback = () => {
    setOpenDialog(true);

    axiosInstance
      .get("/timemate/leaverequests/", {
        params: {
          year: props.year,
          managed_employee: props.employeeId,
        },
      })
      .then((response) => {
        //   create subarrays for status Submitted, Approved, Rejected
        let submitted = response.data.filter((leave_req) => {
          return leave_req.status.trim() === "Submitted";
        });
        let approved = response.data.filter((leave_req) => {
          return leave_req.status.trim() === "Approved";
        });
        let rejected = response.data.filter((leave_req) => {
          return leave_req.status.trim() === "Rejected";
        });

        //extract ids of submitted entries
        let submitted_ids = submitted.map((el) => el.leave_request_id);
        let approved_ids = approved.map((el) => el.leave_request_id);
        let rejected_ids = rejected.map((el) => el.leave_request_id);

        dispatch(initalizePendingCheckedLeaveReq(submitted_ids));
        dispatch(initalizeApprovedCheckedLeaveReq(approved_ids));
        dispatch(initalizeRejectedCheckedLeaveReq(rejected_ids));

        dispatch(setSubmittedLeaveReqData(submitted));
        dispatch(setApprovedLeaveReqData(approved));
        dispatch(setRejectedLeaveReqData(rejected));
      })
      .catch((error) => {
        console.log("error GET /timemate/leaverequests/: ", error);
      });
  };

  const closeDialogCallback = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <div
        className={`${classes.toggleButton} ${isApproved ? "approved" : ""}`}
        onClick={openDialogCallback}
        style={{ cursor: "pointer" }}>
        <div>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            className={classes.nonSelectable}>
            {employeeFullName}
          </Typography>
          <Typography variant="body2" className={classes.nonSelectable}>
            {props.year}
          </Typography>
        </div>
        <img src={cardIcon} />
      </div>
      <ApprovalDialogWindow
        employeeFullName={employeeFullName}
        closeDialogCallback={closeDialogCallback}
        open={openDialog}
        year={props.year}
        employeeId={props.employeeId}
      />
    </>
  );
}

export default ApprovalCard;
