import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./LeaveRequest.css";
import HistoryCard from "./components/LeaveRequest/HistoryCard";
import CalendarLeaveRequest from "./components/LeaveRequest/Calendar";
import LeaveRequestForm from "./components/LeaveRequest/LeaveRequestForm";
import axiosInstance from "../../axios/axiosConfig";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setData } from "../../features/leaveRequestSlice";
import DataView from "./components/LeaveRequest/DataView";

function LeaveRequest() {
  const leaveRequestState = useSelector((state) => state.leaverequest);
  const adminState = useSelector((state) => state.admin);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const dispatch = useDispatch();
  // fetch leave request data of current year
  useEffect(() => {
    // get current year
    let currentYear = new Date().getFullYear();

    axiosInstance
      .get("/timemate/leaverequests/", {
        params: {
          year: leaveRequestState.selectedYear,
        },
      })
      .then((response) => {
        dispatch(setData(response.data));
        // check if we have entries in rejected or pending; if not we will disable submit button
        let submitted = response.data.filter((leave_req) => {
          return leave_req.status.trim() === "Pending";
        });

        let rejected = response.data.filter((leave_req) => {
          return leave_req.status.trim() === "Rejected";
        });

        if (submitted.length === 0 && rejected.length === 0) {
          setDisableSubmitButton(true);
        } else {
          setDisableSubmitButton(false);
        }
      })
      .catch((error) => {
        console.log("error GET /timemate/leaverequests/: ", error);
      });
  }, [
    leaveRequestState.selectedYear,
    leaveRequestState.triggerLeaveRequestDataFetch,
    adminState.activeUser,
  ]);

  return (
    <div className="outer-div-content-wrapper">
      <div className="back-button-navigation-grid-item-1"></div>
      <div className="main-content-wrapper">
        <Header />
        <div className="main-body-wrapper-flex">
          <HistoryCard disableSubmitButton={disableSubmitButton} />
          <CalendarLeaveRequest />
          <LeaveRequestForm />
        </div>
        <div className="leave-request-data-container">
          <div className="data-view-header">
            <div className="table-title-leave-requests">
              {`Leave Requests ${leaveRequestState.selectedYear}`}
            </div>
          </div>
          <DataView data={leaveRequestState.data} state={leaveRequestState} />
        </div>
      </div>
    </div>
  );
}

export default LeaveRequest;
