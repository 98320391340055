import React, { useRef } from "react";
import Header from "../../components/Header";
import illustration from "../../assets/release_notes_card.svg";
import TitleCard from "../../components/TitleCard";
import ReleaseNotes from "./ReleaseNotes";

function ContentReleaseNotes() {
  // create ref hook to track width of title div; cards below should have same size as title card div
  const ref = useRef(null);

  return (
    <>
      <div className="outer-div-content-wrapper">
        <div className="back-button-navigation-grid-item-1"></div>
        <div className="main-content-wrapper" ref={ref}>
          <Header />
          <TitleCard
            title="Release Notes"
            subtitle="Newest features and bug fixes"
            illustration={illustration}
            scale="0.55"
          />
          {/* <h1>HERE COMES THE IFRAME</h1> */}
          <ReleaseNotes />
          {/* <CVGuidelines /> */}
        </div>
        <div className="next-button-navigation-grid-item-3"> </div>
      </div>
    </>
  );
}

export default ContentReleaseNotes;
