import { useEffect } from "react";
import axiosInstance from "../../../../axios/axiosConfig";
import moment from "moment";
import {
  setActivities,
  setActivity,
} from "../../../../features/timesheetSlice";
import { current } from "@reduxjs/toolkit";

export function useGetDomainsClients({ setPhiliClients, setPhiliDomains }) {
  useEffect(() => {
    const fetchData = async () => {
      // retrieve PhiliClients
      try {
        const responseDjango = await axiosInstance.get(
          "/timemate/philiclients/"
        );
        setPhiliClients(responseDjango.data);
      } catch (error) {
        console.error("Error fetching PhiliClients data from Django:", error);
      }
      // retrieve PhiliDomain
      try {
        const responseDjango = await axiosInstance.get(
          "/timemate/philidomains/"
        );
        setPhiliDomains(responseDjango.data);
      } catch (error) {
        console.error("Error fetching PhiliDomains data from Django:", error);
      }
    };
    fetchData();
  }, [setPhiliClients, setPhiliDomains]);
  return;
}

export function useGetActivities(dispatch) {
  useEffect(() => {
    const fetchData = async () => {
      // retrieve PhiliClients
      try {
        const responseDjango = await axiosInstance.get(
          "/timemate/timesheetactivities/"
        );
        dispatch(setActivities(responseDjango.data));
      } catch (error) {
        console.error("Error fetching PhiliClients data from Django:", error);
      }
    };
    fetchData();
  }, []);
  return;
}

export const updateTimesheet = async (
  employeeId,
  activityType,
  activity,
  date,
  duration,
  comment,
  site
) => {
  const data = {
    employee: employeeId,
    activity: {
      activity_type: activityType,
      activity_name: activity,
    },
    date: date,
    duration: duration,
    comment: comment,
    site: site,
  };
  const response = await axiosInstance.post(
    "/timemate/employeetimesheet/",
    data
  );
  // console.log(response);
};

export const dateFormatter = (dates) => {
  // Check if the dates array is null or empty
  if (!dates || dates.length !== 2) {
    return "Please select a date";
  }

  const [startDate, endDate] = dates;

  // Check if the start date is not provided
  if (!startDate) {
    return "Please select a start date";
  }

  // Check if the end date is not provided, format and return the start date
  if (!endDate) {
    const formattedStartDate = new Date(startDate).toDateString();
    return `${formattedStartDate}`;
  }

  // If both start and end dates are provided, format and return the range
  const formattedStartDate = new Date(startDate).toDateString();
  const formattedEndDate = new Date(endDate).toDateString();

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate;
  }
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getDatesInRange = (dates, includeWeekends) => {
  // Check if the dates array is null or doesn't have two entries
  if (!dates || dates.length !== 2) {
    return null;
  }

  const [startDate, endDate] = dates;

  // Check if the start date is not provided
  if (!startDate) {
    return null;
  }

  // If only one date is provided, return an array with that date
  if (!endDate) {
    const start = new Date(startDate);
    // return [start.toLocaleDateString("GB")];
    return [moment(start).format("DD/MM/YYYY")];
  }

  // If both start and end dates are provided, calculate the dates in the range
  const start = new Date(startDate);
  const end = new Date(endDate);

  const datesInRange = [];
  let currentDate = start;

  while (currentDate <= end) {
    if (includeWeekends === false) {
      // check if date corresponds to sturday or sunday
      if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) {
        datesInRange.push(moment(currentDate).format("DD/MM/YYYY"));
      }
    } else {
      datesInRange.push(moment(currentDate).format("DD/MM/YYYY"));
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesInRange;
};

export function isValidTimeString(timeStringSample) {
  // Check if the string is null or empty
  if (!timeStringSample) {
    return false;
  }
  const timeString = timeStringSample;
  // const timeString = timeStringSample.toLocaleTimeString();
  // Regular expression to match hh:mm:ss format
  var timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):00$/;

  // Check if the string matches the regex
  if (!timeRegex.test(timeString)) {
    return false;
  }

  // Split the string into hours, minutes, and seconds
  var [hours, minutes] = timeString.split(":").map(Number);

  // Check if hours and minutes are within the valid range
  if (hours < 0 || hours >= 24 || minutes < 0 || minutes >= 60) {
    return false;
  }

  // If all checks pass, return true
  return true;
}
