import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

export default function RejectDialogWindow({ open, setOpen, rejectCallback }) {
  const [rejectionComment, setRejectionComment] = useState("")
  const handleClose = () => {
    setOpen(false);
  };

  const handleCommentChange = (e) => {
    setRejectionComment(e.target.value)
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>

        <Typography
            sx={{ ml: 0, flex: 1, color: "#004170", fontWeight: "bold" }}
            variant="h5"
            component="div"
          >
            Reject Reason
          </Typography>

        </DialogTitle>
        <DialogContent>
          <TextField
            // id="outlined-multiline-flexible"
            margin="dense"
            autoFocus
            required
            multiline
            rows={5}
            fullWidth
            onChange={handleCommentChange}
            value={rejectionComment}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button type="submit" 
          disabled = {rejectionComment.length===0}
          onClick={()=>{
            rejectCallback(rejectionComment)
            handleClose()
            }} variant="contained">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
