import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  languageSkills: [],
  textAreaDraggable: true,
};

export const languageSkillSlice = createSlice({
  name: "languageskill",
  initialState,
  reducers: {
    initalizeLanguageSkills: (state, action) => {
      state.languageSkills = action.payload;
    },
    addLanguageSkill: (state, action) => {
      state.languageSkills.push(action.payload);
    },
    changeTextAreaFocused: (state, action) => {
      state.textAreaDraggable = action.payload;
    },
    handleDragEndLanguageSkills: (state, action) => {
      state.languageSkills = action.payload;
    },
    changeLanguageObject: (state, action) => {
      var newState = state.languageSkills.map((language) => {
        if (language.id === action.payload.id) {
          return {
            ...language,
            [action.payload.property]: action.payload.value,
          };
        }
        return language;
      });

      state.languageSkills = newState;
    },
  },
});

export const {
  handleDragEndLanguageSkills,
  changeTextAreaFocused,
  initalizeLanguageSkills,
  addLanguageSkill,
  changeLanguageObject,
} = languageSkillSlice.actions;

export default languageSkillSlice.reducer;
