import React, { useState, useRef, useEffect } from "react";
import ConfettiEffect from "../../../components/ConfettiEffect";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import DynamicCv from "./DynamicCv";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axiosInstance from "../../../axios/axiosConfig";
import { saveAs } from "file-saver";
import Tooltip from "@mui/material/Tooltip";
import {
  handleDocRaptorTestModeChange,
  handleIsGeneralCVChange,
} from "../../../features/internalinfoSlice";
import { useNavigate, Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { initializeCvSummary } from "../../../features/cvSummarySlice";
import Backdrop from "@mui/material/Backdrop";
import { initalizeExperiences } from "../../../features/cvProjectExperience";
import { initializeOtherExperience } from "../../../features/otherExperience";
import { initializeSelectedExperience } from "../../../features/selectedExperiences";
import DownloadCvButtonGroup from "./DownloadCvButtonGroup";
import CvActionsGroup from "./CvActionsGroup";
import UpdateAndApprove from "./UpdateAndApprove";

function ContentCreateCVPageDynamic() {
  const [zIndexGuidelines, setZIndexGuidelines] = useState(5);

  const [isCvDownloading, setIsCvDownloading] = useState(false);
  const profileInfoState = useSelector((state) => state.profileinfo);
  const internalInfoState = useSelector((state) => state.internalinfo);

  const [backDropOpen, setBackDropOpen] = useState(false);

  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  const navigate = useNavigate();

  const imgRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageWidth(imgRef.current.offsetWidth);
      setImageHeight(imgRef.current.offsetHeight);
    }, 1000);

    return () => clearTimeout(timer);
  }, [profileInfoState.image]);

  const handleDownloadCV = () => {
    setIsCvDownloading(true);

    axiosInstance
      .get("/cvapp/employee-name")
      .then((responseName) => {
        axiosInstance
          .get("/cvapp/create-pdf/", {
            responseType: "blob",
            params: {
              isTest: internalInfoState.isDocRaptorTestMode,
            },
          })
          .then((response) => {
            const blob = new Blob([response.data], { type: "application/pdf" });
            saveAs(
              blob,
              `CV ${responseName.data.first_name} ${responseName.data.last_name} - Philico.pdf`
            );
            setIsCvDownloading(false);
          })
          .catch((error) => {
            console.log("error download cv create-pdf: ", error);
            setIsCvDownloading(false);
          });
      })
      .catch((error) => {
        console.log("error download cv in employee-name: ", error);
        setIsCvDownloading(false);
      });
  };

  useEffect(() => {
    // fetch cv summaries data
    axiosInstance
      .get("/cvapp/cv-summaries/")
      .then((response) => {
        dispatch(initializeCvSummary(response.data));
      })
      .catch((error) => {
        console.log("error fetch cv summaries: ", error);
      });

    // fetch project experiences
    axiosInstance
      .get("/cvapp/experiences/")
      .then((response) => {
        dispatch(initalizeExperiences(response.data));
      })
      .catch((error) => {
        console.log("error fetch experiences: ", error);
      });

    axiosInstance.get("/cvapp/skills/").then((response) => {
      let keySkills = [];
      let otherSkills = [];

      response.data.map((skill) => {
        if (skill.skillType === "Key") {
          keySkills.push(skill);
        } else if (skill.skillType === "Other") {
          otherSkills.push(skill);
        }
      });

      dispatch(initializeOtherExperience(otherSkills));
      dispatch(initializeSelectedExperience(keySkills));
    });
  }, [internalInfoState.isGeneralCV]);

  useEffect(() => {
    if (internalInfoState.openBackdropPhilicoLogo) {
      setZIndexGuidelines(1);
    } else {
      const timeout = setTimeout(() => {
        setZIndexGuidelines(5);
      }, 300);

      // clearTimeout(timeout);
    }
  }, [internalInfoState.openBackdropPhilicoLogo]);

  return (
    <div>
      <ConfettiEffect runConfetti={isCvDownloading} />
      <div className="wrapper">
        <div className="title-and-cv-download-button-wrapper">
          <div style={{ display: "flex", alignItems: "center" }}>
            <CvActionsGroup />
            <UpdateAndApprove />
            {/* <Link to="/cv-guidelines" target="_blank">
              <Button
                variant="outlined"
                className="cv-guidelines"
                style={{
                  position: "relative",
                  zIndex: zIndexGuidelines, //need to set zindex of guidelines button dynamically; otherwise dropdown menu of philico logo overlays and prevents clicking the button
                }}>
                GUIDELINES
              </Button>
            </Link> */}
            {/* <Tooltip
              title="The goal is to have two CV versions: One that will be sent to Avaloq clients, which ideally uses Avaloq specific terminology. The 'General' CV will be sent to non Avaloq clients and therefore Avaloq specific terms should be avoided."
              arrow>
              <div
                style={{
                  alignSelf: "center",
                  marginTop: "8px",
                  marginLeft: "32px",
                }}>
                <span
                  className="toggle-button-label"
                  style={{
                    color: internalInfoState.isGeneralCV
                      ? "#B0B0AF"
                      : "#004170",
                  }}>
                  Avaloq
                </span>
                <Switch
                  sx={{ marginTop: "-6px" }}
                  className="cv-mode-toggle-button"
                  checked={internalInfoState.isGeneralCV}
                  onChange={(e) => {
                    localStorage.setItem(
                      "cv-type",
                      e.target.checked ? "General" : "Avaloq"
                    );
                    dispatch(handleIsGeneralCVChange(e.target.checked));
                  }}
                />
                <span
                  className="toggle-button-label"
                  style={{
                    color: internalInfoState.isGeneralCV
                      ? "#7c6050"
                      : "#B0B0AF",
                  }}>
                  General
                </span>
              </div>
            </Tooltip> */}
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title="The goal is to have two CV versions: One that will be sent to Avaloq clients, which ideally uses Avaloq specific terminology. The 'General' CV will be sent to non Avaloq clients and therefore Avaloq specific terms should be avoided."
              arrow>
              <div
                style={{
                  alignSelf: "center",
                  marginTop: "8px",
                  marginRight: "32px",
                }}>
                <span
                  className="toggle-button-label"
                  style={{
                    color: internalInfoState.isGeneralCV
                      ? "#B0B0AF"
                      : "#004170",
                  }}>
                  Avaloq
                </span>
                <Switch
                  sx={{ marginTop: "-6px" }}
                  className="cv-mode-toggle-button"
                  checked={internalInfoState.isGeneralCV}
                  onChange={(e) => {
                    localStorage.setItem(
                      "cv-type",
                      e.target.checked ? "General" : "Avaloq"
                    );
                    dispatch(handleIsGeneralCVChange(e.target.checked));
                  }}
                />
                <span
                  className="toggle-button-label"
                  style={{
                    color: internalInfoState.isGeneralCV
                      ? "#7c6050"
                      : "#B0B0AF",
                  }}>
                  General
                </span>
              </div>
            </Tooltip>
            {/* <Tooltip
              title="The number of PDF's, that can be generated free of charge, are limited. Test CV's, which contain a watermark, can be generated free of charge."
              arrow>
              <FormGroup
                style={{
                  marginTop: "6px",
                  marginBottom: "0px",
                  marginRight: "16px",
                }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={internalInfoState.isDocRaptorTestMode}
                      onChange={(e) => {
                        dispatch(
                          handleDocRaptorTestModeChange(e.target.checked)
                        );
                      }}
                      name="testcv"
                      style={{ marginTop: "0px" }}
                    />
                  }
                  label="Create test CV"
                  style={{ lineHeight: 1 }}
                />
              </FormGroup>
            </Tooltip> */}
            <DownloadCvButtonGroup />
            {/* <Button
              variant="contained"
              onClick={handleDownloadCV}
              startIcon={
                isCvDownloading ? (
                  <CircularProgress
                    className="circular-progress-download-cv"
                    style={{
                      width: "24px",
                      height: "24px",
                      marginLeft: "-4px",
                    }}
                  />
                ) : (
                  <DownloadIcon />
                )
              }
              style={{ position: "relative", zIndex: "2" }}>
              Download cv
            </Button> */}
          </div>
        </div>
      </div>
      <DynamicCv />
      <div id="image-measure">
        <img src={profileInfoState.image} alt="" ref={imgRef} />
      </div>
      <Backdrop
        style={{ zIndex: "3" }}
        sx={{ backgroundColor: "rgba(156, 156, 156, 0.5)" }}
        open={backDropOpen}>
        <CircularProgress sx={{ zIndex: "2001" }} />
      </Backdrop>
    </div>
  );
}

export default ContentCreateCVPageDynamic;
