import React, { useMemo } from "react";
import { tableColumns, useReactTable } from "./helpers";
import { useSelector } from "react-redux";
import { MaterialReactTable } from "material-react-table";
import { useDispatch } from "react-redux";

function DataView(props) {
  const leaveRequestState = useSelector((state) => state.leaverequest);
  const dispatch = useDispatch();
  // load columns
  const columns = useMemo(
    () =>
      tableColumns(
        props.data,
        dispatch,
        leaveRequestState.durationEditValidationError,
        leaveRequestState.dateEditValidationError
      ),
    [
      props.data,
      leaveRequestState.durationEditValidationError,
      leaveRequestState.dateEditValidationError,
      leaveRequestState.pendingCheckedLeaveReq,
    ]
  );

  // create table objects
  const table = useReactTable(
    columns,
    props.data,
    dispatch,
    // props.state,
    leaveRequestState,
    props.enableRowActions,
    props.showEditButton,
    props.showDeleteButton,
    props.showIncludeCheckbox,
    props.checkboxState
  );

  return <MaterialReactTable table={table} />;
}

export default DataView;
