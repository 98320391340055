import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  otherExperiences: [],
  textAreaDraggable: true,
};

export const otherexperiencesSlice = createSlice({
  name: "otherexperiences",
  initialState,
  reducers: {
    initializeOtherExperience: (state, action) => {
      state.otherExperiences = action.payload;
    },
    handleAddOtherExperience: (state, action) => {
      state.otherExperiences.push(action.payload);
    },
    handleDeleteOtherExperience: (state, action) => {
      let filteredArray = state.otherExperiences.filter((experience) => {
        return experience.id !== action.payload;
      });
      state.otherExperiences = filteredArray;
    },
    changeTextAreaFocused: (state, action) => {
      state.textAreaDraggable = action.payload;
    },
    handleDragEndOtherExperiences: (state, action) => {
      state.otherExperiences = action.payload;
    },
    changeOtherExperienceObject: (state, action) => {
      let newState = state.otherExperiences.map((experience) => {
        if (experience.id === action.payload.id) {
          return {
            ...experience,
            [action.payload.property]: action.payload.value,
          };
        }
        return experience;
      });

      state.otherExperiences = newState;
    },
    handleTextChangeOtherExperience: (state, action) => {
      let newState = state.otherExperiences.map((experience) => {
        if (experience.id === action.payload.id) {
          experience.experienceText = action.payload.text;
        }
        return experience;
      });
      state.otherExperiences = newState;
    },
    removeOtherExperience: (state, action) => {
      let newState = state.otherExperiences.filter(
        (experience) => experience.id !== action.payload
      );
      state.otherExperiences = newState;
    },
  },
});

export const {
  handleAddOtherExperience,
  handleDeleteOtherExperience,
  removeOtherExperience,
  initializeOtherExperience,
  changeOtherExperienceObject,
  changeTextAreaFocused,
  handleDragEndOtherExperiences,
} = otherexperiencesSlice.actions;

export default otherexperiencesSlice.reducer;
