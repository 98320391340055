import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedExperiences: [],
  textAreaDraggable: true,
};

export const selectedexperiencesSlice = createSlice({
  name: "selectedexperiences",
  initialState,
  reducers: {
    initializeSelectedExperience: (state, action) => {
      state.selectedExperiences = action.payload;
    },
    handleAddExperience: (state, action) => {
      state.selectedExperiences.push(action.payload);
    },
    changeTextAreaFocused: (state, action) => {
      state.textAreaDraggable = action.payload;
    },
    handleDeleteExperience: (state, action) => {
      let filteredArray = state.selectedExperiences.filter((experience) => {
        return experience.id !== action.payload;
      });
      state.selectedExperiences = filteredArray;
    },
    handleDragEnd: (state, action) => {
      state.selectedExperiences = action.payload;
    },
    handleTextChange: (state, action) => {
      let newState = state.selectedExperiences.map((experience) => {
        if (experience.id === action.payload.id) {
          experience.experienceText = action.payload.text;
        }
        return experience;
      });
      state.selectedExperiences = newState;
    },
    removeSelectedExperience: (state, action) => {
      let newState = state.selectedExperiences.filter(
        (experience) => experience.id !== action.payload
      );
      state.selectedExperiences = newState;
    },
    handleKeySkillObjectChange: (state, action) => {
      var newState = state.selectedExperiences.map((keySkill) => {
        if (keySkill.id === action.payload.id) {
          return {
            ...keySkill,
            [action.payload.property]: action.payload.value,
          };
        }
        return keySkill;
      });

      state.selectedExperiences = newState;
    },
  },
});

export const {
  handleAddExperience,
  handleDeleteExperience,
  handleTextChange,
  initializeSelectedExperience,
  removeSelectedExperience,
  handleKeySkillObjectChange,
  changeTextAreaFocused,
  handleDragEnd,
} = selectedexperiencesSlice.actions;

export default selectedexperiencesSlice.reducer;
