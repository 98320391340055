import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeStep: -1,
  linkPaths: [
    "/profile",
    "/personal",
    "/internal",
    "/education",
    "/experience",
    "/description",
    "/create-cv",
    // "/testing"
  ],
  steps: [
    "Profile",
    "Personal Information",
    "Internal Information",
    "Education",
    "Experience",
    "Description",
    "Create CV",
    // "Testing"
  ],
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.activeStep = action.payload;
    },
    increment: (state) => {
      // console.log(
      //   "this is link_paths[state.activeStep + 1]: ",
      //   link_paths[state.activeStep + 1]
      // );
      // action.payload(link_paths[state.activeStep + 1]);
      state.activeStep += 1;
    },
    decrement: (state) => {
      state.activeStep -= 1;
    },
  },
});

export const { changePage, increment, decrement } = navigationSlice.actions;
export default navigationSlice.reducer;
