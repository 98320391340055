import React, { useEffect, useRef, useState } from "react";
import SelectCustomized from "../../../../components/input_components/SelectCustomized";
import { useSelector } from "react-redux";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button, TextField } from "@mui/material";
import fr from "date-fns/locale/fr";
import { parse, isDate } from "date-fns";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  setCalendarKeyState,
  setSelectedDates,
  setTriggerLeaveRequestDataFetch,
} from "../../../../features/leaveRequestSlice";
import { dateFormatter, getDatesInRange } from "../TimesheetCard/helpers";
import axiosInstance from "../../../../axios/axiosConfig";

function LeaveRequestForm() {
  const timesheetState = useSelector((state) => state.timesheet);
  const leaveRequestState = useSelector((state) => state.leaverequest);
  const adminState = useSelector((state) => state.admin);

  const [activityTypeList, setActivityTypeList] = useState([]);
  const [activityList, setActivityList] = useState([]);

  const timePickerInputRef = useRef(null);
  const dispatch = useDispatch();

  // get activity types
  useEffect(() => {
    //fetch activity types
    axiosInstance.get("/timemate/timesheetactivities/").then((response) => {
      var absenceActivities = response.data.find((group) => {
        return group.activity_type === "Absence";
      });

      setActivityTypeList([absenceActivities.activity_type]);

      setActivityList(
        absenceActivities.activities.map((activity) => activity.activity_name)
      );
    });
  }, []);

  useEffect(() => {
    try {
      setActivityList(
        timesheetState.activities
          .find((group) => group.activity_type === "Absence")
          .activities.map((activity) => activity.activity_name)
      );
    } catch {
      console.log("Error");
    }
  }, [timesheetState.activityType]);

  const WORKSITES = ["Home", "Client", "Philico"];

  const validationSchema = Yup.object({
    type: Yup.string().required("Required!"),
    activity: Yup.string().required("Required!"),
    comment: Yup.string().required("Required!"),
    site: Yup.string().required("Required!"),
    duration: Yup.date()
      .transform(function (currentValue, originalValue) {
        if (isDate(currentValue)) {
          return currentValue;
        }
      })
      .typeError("Invalid format! Expected: HH:mm")
      .required("Required!"),
  });

  const handleDurationBlur = (value, formik) => {
    // check if value contains : . This is done because integers >= 10 entered in duration field include :
    if (value.includes(":")) {
      let splitString = value.split(":");

      //check if second part of string is empty (so that single integer was entered)
      if (splitString[1].length === 0) {
        //if integer between 24 and 0 then we transform it
        if (Number(splitString[0]) < 24 && Number(splitString[0]) > 0) {
          formik.setFieldValue(
            "duration",
            parse(
              splitString[0].padStart(2, "0") + ":00:00",
              "HH:mm:ss",
              new Date()
            )
          );
        }
      }
    } else {
      if (Number(value) < 24 && Number(value) > 0) {
        formik.setFieldValue(
          "duration",
          parse(value.padStart(2, "0") + ":00:00", "HH:mm:ss", new Date())
        );
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          type: "Absence",
          activity: "",
          comment: "",
          site: "Home",
          duration: null,
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          const list_of_selected_dates = getDatesInRange(
            leaveRequestState.selectedDates,
            false
          );

          let durationValue;

          // transform duration value
          if (values.duration instanceof Date) {
            durationValue = moment(values.duration).format("HH:mm:ss");
          } else {
            durationValue = values.duration;
          }

          // loo list_of_selected_dates.forEach(async (date) => {p through dates to make new entries in timesheet table
          list_of_selected_dates.forEach(async (date) => {
            const data = {
              employee: adminState.activeUser,
              activity: {
                activity_type: values.type,
                activity_name: values.activity,
              },
              date: date,
              duration: durationValue,
              comment: values.comment,
              site: values.site,
              is_leave_request: true,
            };

            const response = await axiosInstance.post(
              "/timemate/employeetimesheet/",
              data
            );
            actions.resetForm();
            dispatch(setSelectedDates([null, null]));
            dispatch(
              setTriggerLeaveRequestDataFetch(
                !leaveRequestState.triggerLeaveRequestDataFetch
              )
            );
            // trigger rerender of calendar
            dispatch(
              setCalendarKeyState(leaveRequestState.calendarKeyState + 1)
            );
          });
        }}>
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            className="leave-request-form-grid-wrapper">
            <div className="grid-item-title title-text-history">
              {dateFormatter(leaveRequestState.selectedDates)}
            </div>
            <div className="grid-item-type">
              <SelectCustomized
                label="Type"
                name="type"
                select_options={activityTypeList}
                values={activityTypeList}
                value={formik.values.type}
                onChange={formik.handleChange}
                fullWidth={true}
                letterSpacing={0}
              />
            </div>
            <div className="grid-item-activity">
              <SelectCustomized
                label="Activity *"
                name="activity"
                select_options={activityList}
                values={activityList}
                value={formik.values.activity}
                onChange={formik.handleChange}
                fullWidth={true}
                letterSpacing={0}
                disabled={activityList.length === 0}
                error={
                  formik.errors.activity && formik.touched.activity
                    ? true
                    : false
                }
                helperText={
                  formik.errors.activity && formik.touched.activity
                    ? formik.errors.activity
                    : " "
                }
              />
            </div>
            <div className="grid-item-duration">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={fr}>
                <TimePicker
                  label="Duration *"
                  name="duration"
                  inputRef={(current) => {
                    if (current) {
                      timePickerInputRef.current = current;
                    }
                    return current?.getAttribute("value");
                  }}
                  value={formik.values.duration}
                  onChange={(newValue) => {
                    formik.setFieldValue("duration", newValue);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        error={
                          formik.errors.duration && formik.touched.duration
                            ? true
                            : false
                        }
                        helperText={
                          formik.errors.duration && formik.touched.duration
                            ? formik.errors.duration
                            : ""
                        }
                        onBlur={() => {
                          // if date is currently not valid and value is integer between 1 to 24 we transform it to HH:00. For example 7 will be transformed to 07:00
                          if (
                            !moment(
                              timePickerInputRef.current?.getAttribute("value"),
                              "HH:mm",
                              true
                            ).isValid()
                          ) {
                            handleDurationBlur(
                              timePickerInputRef.current?.getAttribute("value"),
                              formik
                            );
                          }
                        }}
                      />
                    );
                  }}
                  disableOpenPicker={true}
                  views={["hours", "minutes"]}
                  format="hh:mm"
                />
              </LocalizationProvider>
            </div>
            <div className="grid-item-location">
              <SelectCustomized
                name="site"
                label="Location"
                select_options={WORKSITES}
                values={WORKSITES}
                defaultValue={"Home"}
                disabled={formik.values.type === "Absence"}
                fullWidth={true}
                letterSpacing={0}
                displayHelperText={false}
                marginTop="23px" // Add this line
              />
            </div>
            <div className="grid-item-comment">
              <TextField
                id="outlined-multiline-flexible"
                name="comment"
                label="Comment *"
                className="comment-leave-request"
                multiline
                value={formik.values.comment}
                onChange={formik.handleChange}
                rows={4}
                fullWidth={true}
                error={
                  formik.errors.comment && formik.touched.comment ? true : false
                }
                helperText={
                  formik.errors.comment && formik.touched.comment
                    ? formik.errors.comment
                    : ""
                }
              />
            </div>
            <div className="grid-item-submit">
              <Button
                variant="contained"
                className="submit-leave-request"
                type="submit">
                Add Leave Request
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default LeaveRequestForm;
