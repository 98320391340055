export const defaultLayouts = {
  contactCard: {
    i: "contactCard",
    x: 0,
    y: 0,
    w: 5.9,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
  addressCard: {
    i: "addressCard",
    x: 6,
    y: 0,
    w: 5.95,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
  educationCard: {
    i: "educationCard",
    x: 0,
    y: 0,
    w: 8,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
  experienceCard: {
    i: "experienceCard",
    x: 0,
    y: 0,
    w: 8,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
  relationshipCard: {
    i: "relationshipCard",
    x: 0,
    y: 20,
    w: 5.9,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
  languageSkillsCard: {
    i: "languageSkillsCard",
    x: 8,
    y: 0,
    w: 3.9,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
  contractInfoCard: {
    i: "contractInfoCard",
    x: 6,
    y: 20,
    w: 5.9,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
  idDocumentCard: {
    i: "idDocumentCard",
    x: 6,
    y: 0,
    w: 5.9,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
  bankInfoCard: {
    i: "bankInfoCard",
    x: 0,
    y: 0,
    w: 5.9,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
  descriptionCard: {
    i: "descriptionCard",
    x: 0,
    y: 0,
    w: 8,
    h: 1,
    minH: 1,
    maxH: 1,
    resizeHandles: ["ne"],
  },
};

// defines default layout of cards
export const contactCardDefaultLayout = {
  i: "contactCard",
  x: 0,
  y: 0,
  w: 5.9,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

export const addressCardDefaultLayout = {
  i: "addressCard",
  x: 6,
  y: 0,
  w: 5.95,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

export const educationCardDefaultLayout = {
  i: "educationCard",
  x: 0,
  y: 0,
  w: 8,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

export const experienceCardDefaultLayout = {
  i: "experienceCard",
  x: 0,
  y: 0,
  w: 8,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

export const relationshipCardDefaultLayout = {
  i: "relationshipCard",
  x: 0,
  y: 20,
  w: 7.9,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

export const languageskillCardDefaultLayout = {
  i: "languageSkillsCard",
  x: 8,
  y: 0,
  w: 3.9,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

export const contractInfoCardDefaultLayout = {
  i: "contractInfoCard",
  x: 8,
  y: 20,
  w: 5.9,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

export const idDocumentCardDefaultLayout = {
  i: "idDocumentCard",
  x: 6,
  y: 0,
  w: 5.9,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

export const bankInfoCardDefaultLayout = {
  i: "bankInfoCard",
  x: 0,
  y: 0,
  w: 5.9,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

export const descriptionCardDefaultLayout = {
  i: "descriptionCard",
  x: 0,
  y: 0,
  w: 8,
  h: 1,
  minH: 1,
  maxH: 1,
  resizeHandles: ["ne"],
};

// old card layouts
// export const defaultLayouts = {
//   contactCard: {
//     i: "contactCard",
//     x: 0,
//     y: 0,
//     w: 5.9,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
//   addressCard: {
//     i: "addressCard",
//     x: 6,
//     y: 0,
//     w: 5.95,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
//   educationCard: {
//     i: "educationCard",
//     x: 0,
//     y: 0,
//     w: 8,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
//   experienceCard: {
//     i: "experienceCard",
//     x: 0,
//     y: 0,
//     w: 8,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
//   relationshipCard: {
//     i: "relationshipCard",
//     x: 0,
//     y: 0,
//     w: 7.9,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
//   languageSkillsCard: {
//     i: "languageSkillsCard",
//     x: 8,
//     y: 0,
//     w: 3.9,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
//   contractInfoCard: {
//     i: "contractInfoCard",
//     x: 0,
//     y: 0,
//     w: 5.9,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
//   idDocumentCard: {
//     i: "idDocumentCard",
//     x: 6,
//     y: 0,
//     w: 5.95,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
//   bankInfoCard: {
//     i: "bankInfoCard",
//     x: 0,
//     y: 0,
//     w: 5.9,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
//   descriptionCard: {
//     i: "descriptionCard",
//     x: 0,
//     y: 0,
//     w: 8,
//     h: 1,
//     minH: 1,
//     maxH: 1,
//     resizeHandles: ["ne"],
//   },
// };

// // defines default layout of cards
// export const contactCardDefaultLayout = {
//   i: "contactCard",
//   x: 0,
//   y: 0,
//   w: 5.9,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };

// export const addressCardDefaultLayout = {
//   i: "addressCard",
//   x: 6,
//   y: 0,
//   w: 5.95,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };

// export const educationCardDefaultLayout = {
//   i: "educationCard",
//   x: 0,
//   y: 0,
//   w: 8,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };

// export const experienceCardDefaultLayout = {
//   i: "experienceCard",
//   x: 0,
//   y: 0,
//   w: 8,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };

// export const relationshipCardDefaultLayout = {
//   i: "relationshipCard",
//   x: 0,
//   y: 0,
//   w: 7.9,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };

// export const languageskillCardDefaultLayout = {
//   i: "languageSkillsCard",
//   x: 8,
//   y: 0,
//   w: 3.9,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };

// export const contractInfoCardDefaultLayout = {
//   i: "contractInfoCard",
//   x: 0,
//   y: 0,
//   w: 5.9,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };

// export const idDocumentCardDefaultLayout = {
//   i: "idDocumentCard",
//   x: 6,
//   y: 0,
//   w: 5.95,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };

// export const bankInfoCardDefaultLayout = {
//   i: "bankInfoCard",
//   x: 0,
//   y: 0,
//   w: 5.9,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };

// export const descriptionCardDefaultLayout = {
//   i: "descriptionCard",
//   x: 0,
//   y: 0,
//   w: 8,
//   h: 1,
//   minH: 1,
//   maxH: 1,
//   resizeHandles: ["ne"],
// };
