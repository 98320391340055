import React from "react";
import TextField from "@mui/material/TextField";

function TextFieldCustomized(props) {
  return (
    <div>
      <TextField
        label={props.label}
        name={props.name}
        placeholder={props.placeholder}
        variant="outlined"
        fullWidth={true}
        InputProps={props.InputProps}
        inputProps={props.inputProps}
        inputRef={props.inputRef}
        InputLabelProps={props.InputLabelProps}
        // InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
        // InputLabelProps={{ shrink: true }}
        onChange={props.onChange}
        value={props.value || ""}
        error={props.error}
        helperText={props.helperText}
        onBlur={props.onBlur}
        disabled={props.disabled}
        sx={{
          input: { cursor: "text" },
          // define border element of textfield
          "& .MuiOutlinedInput-notchedOutline": {
            // borderColor: "#79747E",
            borderColor: "philicoblue.main",
          },
          // define hover state of border element of textfield
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "philicoblue.main",
            cursor: "text",
          },
          "& .MuiOutlinedInput-input": {
            fontFamily: "Montserrat",
          },
          // define hover state of area of input element
          "&:hover .MuiOutlinedInput-input": {
            backgroundColor: "textfield_background_color.main",
          },
          // set the border-color and background color when input is focused
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "philicoblue.main",
            },
            "&.Mui-focused input": {
              backgroundColor: "textfield_background_color.main",
            },
            "&:hover fieldset": {
              cursor: "text",
            },
          },
          // set color of the label in upper-border when focused
          "& .MuiInputLabel-root": {
            fontFamily: "Montserrat",
            "& .Mui-focused": {
              color: "red",
              fontFamily: "Montserrat",
            },
          },
          // define letter spacing of label; letterspacing = 0.9 gives enough space to label when focused
          "& legend": {
            letterSpacing: 1, //if label in focus state does not have enough space increase this number
          },
        }}
      />
    </div>
  );
}

TextFieldCustomized.defaultProps = {
  name: "",
  error: false,
  helperText: " ",
  onBlur: (e) => {},
  disabled: false,
  // InputLabelProps: { shrink: props.value },
};

export default TextFieldCustomized;
