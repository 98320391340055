import React, { useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import axiosInstance from "../../../axios/axiosConfig";
import { saveAs } from "file-saver";

const buttonGroupOptions = ["Download Test CV", "Download CV"];

function DownloadCvButtonGroup() {
  const [open, setOpen] = useState(false);
  const [isCvDownloading, setIsCvDownloading] = useState(false);

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  //   const handleClick = () => {
  //     console.info(`You clicked ${buttonGroupOptions[selectedIndex]}`);
  //   };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleDownloadCV = (isTestCv) => {
    console.log("in handledownloadCv this is isTest vaiarbale: ", isTestCv);
    setIsCvDownloading(true);

    axiosInstance
      .get("/cvapp/employee-name")
      .then((responseName) => {
        axiosInstance
          .get("/cvapp/create-pdf/", {
            responseType: "blob",
            params: {
              isTest: isTestCv,
            },
          })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/pdf",
            });
            saveAs(
              blob,
              `CV ${responseName.data.first_name} ${responseName.data.last_name} - Philico.pdf`
            );
            setIsCvDownloading(false);
          })
          .catch((error) => {
            console.log("error download cv create-pdf: ", error);
            setIsCvDownloading(false);
          });
      })
      .catch((error) => {
        console.log("error download cv in employee-name: ", error);
        setIsCvDownloading(false);
      });
  };

  return (
    <>
      {" "}
      <ButtonGroup
        variant="contained"
        className="download-cv-button-group"
        ref={anchorRef}
        aria-label="split button">
        {selectedIndex === 0 ? (
          <Tooltip
            title="The number of PDF's, that can be generated free of charge, are limited. Test CV's, which contain a watermark, can be generated free of charge."
            arrow>
            <Button
              className="download-cv-button-individual"
              onClick={() => handleDownloadCV(true)}
              startIcon={
                isCvDownloading ? (
                  <CircularProgress
                    className="circular-progress-download-cv"
                    style={{
                      width: "24px",
                      height: "24px",
                      marginLeft: "-4px",
                    }}
                  />
                ) : (
                  <DownloadIcon />
                )
              }>
              {buttonGroupOptions[selectedIndex]}
            </Button>
          </Tooltip>
        ) : (
          <Button
            onClick={() => handleDownloadCV(false)}
            startIcon={
              isCvDownloading ? (
                <CircularProgress
                  className="circular-progress-download-cv"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginLeft: "-4px",
                  }}
                />
              ) : (
                <DownloadIcon />
              )
            }>
            {buttonGroupOptions[selectedIndex]}
          </Button>
        )}
        <Button
          className="download-cv-button-individual-arrow-down"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          width: anchorRef.current && anchorRef.current.offsetWidth,
          //   width: "350px",
          //   width: `${anchorRef.current.offsetWidth}px`,
        }}
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        //   sx={{}}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={
              {
                //   transformOrigin: "bottom-start",
                // placement === "bottom" ? "center top" : "center bottom",
              }
            }>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {buttonGroupOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      //   disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default DownloadCvButtonGroup;
