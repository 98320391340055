// Degree alert messages
export const DegreeSuccessAlert = "Degree was successfully added!";
export const DegreeFailAlert = "Degree could not be added!";

// certificate alert messages
export const CertificateSuccessAlert = "Certificate was sucessfully added!";
export const CertificateFailAlert = "Certificate could not be added!";

// experience alert messages
export const ExperienceSuccessAlert = "Experience was sucessfully added!";
export const ExperienceFailAlert = "Experience could not be added!";

// cvsummaries alert messages
export const CvSummarySuccessAlert = "CV summary was sucessfully added!";
export const CvSummaryFailAlert = "CV summary could not be added!";

// websummaries alert messages
export const WebSummarySuccessAlert = "Website summary was sucessfully added!";
export const WebSummaryFailAlert = "Website summary could not be added!";

// identity document alert messages
export const IDSuccessAlert = "Identity document was sucessfully added!";
export const IDFailAlert = "Identity document could not be added!";

// related contact alert messages
export const RelationshipSuccessAlert =
  "Related contact was sucessfully added!";
export const RelationshipFailAlert = "Related contact could not be added!";

// language skills alert messages
export const LanguageSuccessAlert = "Language skill was sucessfully added!";
export const LanguageFailAlert = "Language skill could not be added!";

// image upload alert messages
export const imageSuccessAlert = "Image is successfully changed!";
export const imageFailAlert = "Image could not be changed!";

// copy email signature to clip board alert message
export const htmlToClipboard =
  "Your email signature has been copied to your clipboard.";
export const htmlToClipboardWWWInvalidUrl =
  "Your entered linked URL is invalid it must contain 'www'";

// reset layout on paye
export const resetLayoutSuccessAlert = "Cards layout was successfully reset!";
export const resetLayoutFailAlert =
  "An error occurred while resetting the layout!";

// google geolocation
export const geoLocationSuccess = "Your position was successfully located!";
export const geoLocationFail = "An error occured while locating your position!";

// login fail
export const loginFail = "An error occured during the login! Please try again.";

// cv data updated success
export const cvDataUpdatedSuccess =
  "Successfully updated. The CV-Approvers are informed via E-Mail about your recent updates.";
export const cvDataUpdatedFail = "Error during update. Please contact P-TECH.";
export const cvDataApprovePartialSuccess =
  "Successfully updated approval status but there was an issue with upload the CV to the Google Drive. Please contact P-TECH.";
export const cvDataApproveSuccess =
  "Successfully updated approval status. The approved CV has been upload to Google drive <a href='https://drive.google.com/drive/u/2/folders/1C3kQFg6H1wwaeUpLJqshbxHevBPLylIN' target='_blank' style='color:white'> here</a>.";
export const cvDataApproveFail = "Error during update. Please contact P-TECH.";
