import React, { useState } from "react";
import icon from "../../../assets/identity_document_child_card_icon.svg";
import editIcon from "../../../assets/language_child_card_edit_icon.svg";
import binIcon from "../../../assets/language_child_card_bin_icon.svg";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { scroller } from "react-scroll";
import PopoverDeleteConfirmation from "../../../components/PopoverDeleteConfirmation";

function IdentityDocumentChildCard(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isToolTipDisabled, setIsToolTipDisabled] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handlePopoverClose = (e, reason) => {
    setAnchorEl(null);
    setOpen(false);
    setIsToolTipDisabled(!isToolTipDisabled);
  };

  const handleDelete = (e) => {
    props.handleEditOrDelete(props.identityDocumentObject.id);
  };

  const handleCancel = (e) => {
    setAnchorEl(null);
    setOpen(false);
    setIsToolTipDisabled(!isToolTipDisabled);
  };

  return (
    <>
      <PopoverDeleteConfirmation
        open={open}
        anchorEl={anchorEl}
        handleClose={handlePopoverClose}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
      />
      <div className="relationships-child-card-wrapper">
        <div className="relationships-child-card-grid-wrapper">
          <div className="relationships-child-card-grid-item-1">
            <img src={icon} alt="relationship icon" />
          </div>
          <div className="relationships-child-card-grid-item-2">
            <span className="language-skills-child-card-title">
              {props.identityDocumentObject.documentType}
            </span>
          </div>
          <div className="non-draggable relationships-child-card-grid-item-3">
            <Tooltip title="Edit Document" arrow>
              <IconButton
                onClick={() => {
                  props.handleEditOrDelete(
                    props.identityDocumentObject.id,
                    props.identityDocumentObject,
                    props.formik,
                    "EDIT"
                  );
                  scroller.scrollTo("identity-document-form-scroll-goal", {
                    duration: 900,
                    delay: 0,
                    smooth: true,
                    offset: -95,
                  });
                }}>
                <img
                  src={editIcon}
                  alt="pencil icon"
                  //   style={{ marginRight: "5px" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Delete Document"
              arrow
              open={!isToolTipDisabled && isHover}>
              <IconButton
                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={(e) => {
                  setIsToolTipDisabled(!isToolTipDisabled);
                  setAnchorEl(e.currentTarget);
                  setOpen((previousState) => !previousState);
                }}>
                <img src={binIcon} alt="bin icon" />
              </IconButton>
            </Tooltip>
          </div>
          <div className="relationships-child-card-grid-item-4">
            <span className="language-skills-child-card-content">
              {`${props.identityDocumentObject.issuingCountry}, ${props.identityDocumentObject.documentNumber}`}
            </span>
          </div>
          <div className="relationships-child-card-grid-item-5">
            <span className="relationships-child-card-birthdate">
              {`Expiry date: ${props.identityDocumentObject.expiryDate}`}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default IdentityDocumentChildCard;
