import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDates: [null, null],
  selectedYear: new Date().getFullYear(),
  data: [],
  dataPublicHolidays: [],
  dataStatuses: [],
  triggerLeaveRequestDataFetch: false,
  durationEditValidationError: false,
  dateEditValidationError: false,
  submittedLeaveReqData: [],
  approvedLeaveReqData: [],
  rejectedLeaveReqData: [],
  approverAction: false, //tracks if the approver has performed an action like Approved, Reject, Reopen
  calendarKeyState: 0,
  pendingCheckedLeaveReq: [],
  approvedCheckedLeaveReq: [],
  rejectedCheckedLeaveReq: [],
};

export const leaveRequestSlice = createSlice({
  name: "leaverequest",
  initialState,
  reducers: {
    setSelectedDates: (state, action) => {
      state.selectedDates = action.payload;
    },
    setSelectedYear: (state, action) => {
      state.selectedYear = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDataPublicHolidays: (state, action) => {
      state.dataPublicHolidays = action.payload;
    },
    setDataStatuses: (state, action) => {
      state.dataStatuses.push(action.payload);
    },
    setTriggerLeaveRequestDataFetch: (state, action) => {
      state.triggerLeaveRequestDataFetch = action.payload;
    },
    setDurationEditValidationError: (state, action) => {
      state.durationEditValidationError = action.payload;
    },
    setDateValidationError: (state, action) => {
      state.dateEditValidationError = action.payload;
    },
    setSubmittedLeaveReqData: (state, action) => {
      state.submittedLeaveReqData = action.payload;
    },
    setApprovedLeaveReqData: (state, action) => {
      state.approvedLeaveReqData = action.payload;
    },
    setRejectedLeaveReqData: (state, action) => {
      state.rejectedLeaveReqData = action.payload;
    },
    setApproverAction: (state, action) => {
      state.approverAction = action.payload;
    },
    setCalendarKeyState: (state, action) => {
      state.calendarKeyState = action.payload;
    },
    initalizePendingCheckedLeaveReq: (state, action) => {
      state.pendingCheckedLeaveReq = action.payload;
    },
    initalizeApprovedCheckedLeaveReq: (state, action) => {
      state.approvedCheckedLeaveReq = action.payload;
    },
    initalizeRejectedCheckedLeaveReq: (state, action) => {
      state.rejectedCheckedLeaveReq = action.payload;
    },
    handlePendingCheckboxChange: (state, action) => {
      //check if number is included; if yes, remove it
      if (state.pendingCheckedLeaveReq.includes(action.payload)) {
        var filteredArray = state.pendingCheckedLeaveReq.filter((el) => {
          return el !== action.payload;
        });
        state.pendingCheckedLeaveReq = filteredArray;
      } else {
        state.pendingCheckedLeaveReq.push(action.payload);
      }
    },
    handleApprovedCheckboxChange: (state, action) => {
      //check if number is included; if yes, remove it
      if (state.approvedCheckedLeaveReq.includes(action.payload)) {
        var filteredArray = state.approvedCheckedLeaveReq.filter((el) => {
          return el !== action.payload;
        });
        state.approvedCheckedLeaveReq = filteredArray;
      } else {
        state.approvedCheckedLeaveReq.push(action.payload);
      }
    },
    handleRejectedCheckboxChange: (state, action) => {
      //check if number is included; if yes, remove it
      if (state.rejectedCheckedLeaveReq.includes(action.payload)) {
        var filteredArray = state.rejectedCheckedLeaveReq.filter((el) => {
          return el !== action.payload;
        });
        state.rejectedCheckedLeaveReq = filteredArray;
      } else {
        state.rejectedCheckedLeaveReq.push(action.payload);
      }
    },
  },
});

export const {
  setSelectedDates,
  setSelectedYear,
  setDataPublicHolidays,
  setData,
  setTriggerLeaveRequestDataFetch,
  setDurationEditValidationError,
  setDateValidationError,
  setSubmittedLeaveReqData,
  setApprovedLeaveReqData,
  setRejectedLeaveReqData,
  setApproverAction,
  setCalendarKeyState,
  initalizePendingCheckedLeaveReq,
  initalizeApprovedCheckedLeaveReq,
  initalizeRejectedCheckedLeaveReq,
  handleApprovedCheckboxChange,
  handlePendingCheckboxChange,
  handleRejectedCheckboxChange,
} = leaveRequestSlice.actions;

export default leaveRequestSlice.reducer;
