import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  experiences: [],
};

export const projectExperiencesSlice = createSlice({
  name: "projectexperience",
  initialState,
  reducers: {
    initalizeExperiences: (state, action) => {
      state.experiences = action.payload;
    },
    addExperience: (state, action) => {
      state.experiences.push(action.payload);
    },
    changeProjectTitle: (state, action) => {
      //   action.payload contains id of experience object
      var newState = state.experiences.map((experience, index) => {
        if (index === action.payload.id) {
          return {
            ...experience,
            projectTitle: action.payload.projectTitle,
          };
        }

        return experience;
      });

      state.experiences = newState;
    },
    changeProjectObject: (state, action) => {
      var newState = state.experiences.map((experience) => {
        if (experience.id === action.payload.id) {
          return {
            ...experience,
            [action.payload.property]: action.payload.value,
          };
        }
        return experience;
      });
      state.experiences = newState;
    },
  },
});

export const {
  addExperience,
  changeProjectObject,
  changeProjectTitle,
  initalizeExperiences,
} = projectExperiencesSlice.actions;
export default projectExperiencesSlice.reducer;
