export function timeDeltaToHours(deltaStr) {
  const [hours, minutes, seconds] = deltaStr.split(":").map(Number);
  const totalHours = hours + minutes / 60 + seconds / 3600;
  return totalHours;
}

export const addClearDatesButton = (
  parentElement,
  buttonText,
  handleClear,
  className,
  elementId
) => {
  // create button element
  const button = document.createElement("button");

  // add attributes to button element
  button.textContent = buttonText;
  button.onclick = handleClear;
  button.setAttribute("class", className);
  button.setAttribute("id", elementId);

  // append button to parent element
  parentElement.appendChild(button);
};
