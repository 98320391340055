import React, { useLayoutEffect } from "react";
import Button from "@mui/material/Button";
import AddIconButton from "../../../../components/input_components/AddIconButton";
import ProjectExperience from "./ProjectExperience";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../axios/axiosConfig";
import {
  initalizeExperiences,
  addExperience,
} from "../../../../features/cvProjectExperience";
import { convertToRaw, convertFromHTML, ContentState } from "draft-js";

function DynamicCvProjectExperience() {
  const projectExperienceState = useSelector(
    (state) => state.projectExperience
  );

  // create dispatch variable to access state reducers later
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    axiosInstance.get("/cvapp/experiences/").then((response) => {
      dispatch(initalizeExperiences(response.data));
    });
  }, []);

  const handleDelete = (id) => {
    axiosInstance
      .delete("/cvapp/experiences/", {
        data: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(initalizeExperiences(response.data));
      })
      .catch((error) => {
        console.log("error deleting experience");
      });
  };

  return (
    <div className="bounding-box-cv-summary-section">
      <div className="cv-project-experience-section-grid-wrapper">
        {" "}
        <div className="left-col-section-title">PROJECT EXPERIENCE</div>
        <div>
          {projectExperienceState.experiences.map((experience, index) => {
            return (
              <ProjectExperience
                key={experience.id}
                index={index}
                // projectTitle={experience.projectTitle}
                projectObject={experience}
                handleDelete={handleDelete}
                experienceObject={experience}
              />
            );
          })}
          <div>
            <Button
              variant="contained"
              startIcon={<AddIconButton />}
              className="dynamic-cv"
              onClick={(e) => {
                axiosInstance
                  .post("/cvapp/experiences/", {
                    position: "",
                    title: "",
                    company: "",
                    location: "",
                    description: "",
                    projectType: "",
                    // startDate: null,
                    // endDate: null,
                  })
                  .then((response) => {
                    console.log("response post request: ", response.data);
                    dispatch(addExperience(response.data));
                  })
                  .catch((error) => {
                    console.log("error adding experience");
                  });
                // dispatch(addExperience({ projectTitle: "" }));
              }}>
              Experience
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicCvProjectExperience;
