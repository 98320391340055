import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSuccessAlertOpen: false,
  isFailAlertOpen: false,
  alertMessage: "",
};

export const altermessageSlice = createSlice({
  name: "alertmessage",
  initialState,
  reducers: {
    setIsSuccessAlertOpen: (state, action) => {
      state.isSuccessAlertOpen = action.payload;
    },
    setIsFailAlertOpen: (state, action) => {
      state.isFailAlertOpen = action.payload;
    },
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
  },
});

export const { setIsSuccessAlertOpen, setIsFailAlertOpen, setAlertMessage } =
  altermessageSlice.actions;

export default altermessageSlice.reducer;
