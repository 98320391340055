import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ApprovedIcon from "./ApprovedIconInverted.svg";
import PendingIcon from "./PendingIcon.svg";
import SubmittedIcon from "./SubmittedIcon.svg";
import RejectedIcon from "./RejectedIcon.svg";
import { timeDeltaToHours } from "./helpers";
import ApprovalDialogWindow from "./ApprovalDialogWindow";

import {
  getTimesheetData,
  getUserFullName,
  getMonthStatus,
  updateMonthStatus,
} from "../../helpers";
import parseDate from "../HistoryCard/helpers";

import { useSelector } from "react-redux";

export const useStylesApprovalCard = makeStyles((theme) => ({
  toggleButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    borderRadius: "8px",
    marginBottom: theme.spacing(1),
    transition: "background-color 0.3s",
    color: "#004170",
    border: "3px solid #004170",
    "&.approved": {
      backgroundColor: "#004170",
      color: theme.palette.common.white,
    },
  },
  nonSelectable: {
    userSelect: "none",
  },
}));

const EmployeeApprovalCard = ({ employeeId, monthYear }) => {
  const classes = useStylesApprovalCard();
  const adminState = useSelector((state) => state.admin);

  const [isApproved, setIsApproved] = useState(false);
  const [cardIcon, setCardIcon] = useState(PendingIcon);
  const [cardReload, setCardReload] = useState(false);
  const [totalHours, setTotalHours] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [timesheetData, setTimesheetData] = useState([]);
  const [timesheetStatus, setTimesheetStatus] = useState("");
  const [employeeFullName, setEmployeeFullName] = useState("");
  const [statusFetched, setStatusFetched] = useState(false);

  //I think this one is causing the issue
  useEffect(() => {
    getUserFullName(setEmployeeFullName, employeeId);
    getMonthStatus(parseDate(monthYear), employeeId, setTimesheetStatus);
    getTimesheetData(setTimesheetData, monthYear, employeeId);
  }, [employeeId]);
  // [employeeId, adminState]);

  useEffect(() => {
    getMonthStatus(parseDate(monthYear), employeeId, setTimesheetStatus);
    setCardReload(!cardReload);
  }, [setTimesheetStatus, monthYear]);

  useEffect(() => {
    if (!statusFetched) {
      getMonthStatus(parseDate(monthYear), employeeId, setTimesheetStatus);
      setStatusFetched(true);
    }

    if (timesheetStatus === null) {
      setTimesheetStatus("Pending");
      setIsApproved(false);
    } else {
      switch (timesheetStatus.toLowerCase()) {
        case "approved":
          setIsApproved(true);
          setCardIcon(ApprovedIcon);
          break;

        case "submitted":
          setIsApproved(false);
          setCardIcon(SubmittedIcon);
          break;

        case "rejected":
          setIsApproved(false);
          setCardIcon(RejectedIcon);
          break;
        default:
          setIsApproved(false);
          setCardIcon(PendingIcon);
          break;
      }
    }
  }, [setTimesheetStatus, cardReload, timesheetStatus, statusFetched]);

  useEffect(() => {
    const totalHours = timesheetData.reduce((acc, item) => {
      return acc + timeDeltaToHours(item.duration);
    }, 0);

    const roundedTotalHours = Math.round(totalHours * 100) / 100;
    setTotalHours(roundedTotalHours);
  }, [timesheetData]);

  const openDialogCallback = () => {
    setOpenDialog(true);
    getTimesheetData(setTimesheetData, monthYear, employeeId);
  };

  const closeDialogCallback = () => {
    setOpenDialog(false);
  };

  const rejectCallback = async (rejection_comment) => {
    await updateMonthStatus(
      parseDate(monthYear),
      "Rejected",
      employeeId,
      rejection_comment,
      cardReload,
      setCardReload
    );
    getMonthStatus(parseDate(monthYear), employeeId, setTimesheetStatus);
    setOpenDialog(false);
  };

  const approveCallback = async () => {
    await updateMonthStatus(
      parseDate(monthYear),
      "Approved",
      employeeId,
      "",
      cardReload,
      setCardReload
    );
    getMonthStatus(parseDate(monthYear), employeeId, setTimesheetStatus);
    setOpenDialog(false);
  };

  const reopenCallback = async () => {
    await updateMonthStatus(
      parseDate(monthYear),
      "Submitted",
      employeeId,
      "",
      cardReload,
      setCardReload,
      true
    );
    getMonthStatus(parseDate(monthYear), employeeId, setTimesheetStatus);
    setOpenDialog(false);
  };

  return (
    <>
      <div
        className={`${classes.toggleButton} ${isApproved ? "approved" : ""}`}
        onClick={openDialogCallback}
        style={{ cursor: "pointer" }}>
        <div>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            className={classes.nonSelectable}>
            {employeeFullName}
          </Typography>
          <Typography variant="body2" className={classes.nonSelectable}>
            {monthYear}
          </Typography>
        </div>
        <img src={cardIcon} />
      </div>
      <ApprovalDialogWindow
        employeeFullName={employeeFullName}
        employeeId={employeeId}
        totalHours={totalHours}
        timesheetStatus={timesheetStatus}
        openDialog={openDialog}
        closeDialogCallback={closeDialogCallback}
        timesheetData={timesheetData}
        setTimesheetData={setTimesheetData}
        rejectCallback={rejectCallback}
        approveCallback={approveCallback}
        reopenCallback={reopenCallback}
        monthYear={monthYear}
      />
    </>
  );
};

export default EmployeeApprovalCard;
