import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import useWindowDimensions from "../../components/useWindowDimensions";
import TitleCard from "../../components/TitleCard";
import illustration from "../../assets/page7_createcv_illustration.svg";
import Header from "../../components/Header";
// import ContentCreateCVPage from "./components/ContentCreateCVPage";
import { useSelector } from "react-redux";
import FailAlert from "../../components/FailAlert";
import SuccessAlert from "../../components/SuccessAlert";
import {
  setIsSuccessAlertOpen,
  setIsFailAlertOpen,
} from "../../features/alertMessageSlice";
import ContentCreateCVPageDynamic from "./components/ContentCreateCVPageDynamic";
import axiosInstance from "../../axios/axiosConfig";
import { useDispatch } from "react-redux";
import {
  initalizeActiveUsers,
  initalizeAdmin,
  initalizeInactiveUsers,
  initalizeManagerUsers,
  initalizeUserRoles,
  initalizeUsers,
  initalizeUsersComplete,
  initalizeUsersId,
  initalizeUsersName,
} from "../../features/adminSlice";
import {
  initalizeAvqSkillsOverview,
  initalizeBusinessSkillsOverview,
  initalizeConsultingSkillsOverview,
  initalizeNamesAndImages,
  initalizeTechSkillsOverview,
  initalizeThirdPartySkilsOverview,
} from "../../features/skillsMatrixSlice";
import Parser from "html-react-parser";

function CreateCvPage(props) {
  // get alertmessage state
  const alertmessageState = useSelector((state) => state.alertmessage);

  const dispatch = useDispatch();

  // get height and width of browser window
  const { height, width } = useWindowDimensions();

  // create ref hook to track width of title div; cards below should have same size as title card div
  const ref = useRef(null);

  // create state to store the width of the parent div; the width of this div will set bounadires for card
  const [divwidth, setDivWidth] = useState(0);

  // runs immediately after React has updated the DOM
  useLayoutEffect(() => {
    // note that useLayoutEffect is a version of useEffect that fires before the browser repaints the screen. This is in contrast to useEffect that runs after react renders your component
    setDivWidth(ref.current.offsetWidth);
  }, [width]);

  useEffect(() => {
    // make request to retrieve employee names and profile images
    axiosInstance.get("/cvapp/employee-names-and-images/").then((response) => {
      dispatch(initalizeNamesAndImages(response.data));
    });

    // make request to retrieve skills of employees
    axiosInstance.get("cvapp/skills-matrix-overview/").then((response) => {
      // dispatch(initalizeEmployeeSkillsPerCategory(response.data));
      let avqSkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("AVQ");
      });

      dispatch(initalizeAvqSkillsOverview(avqSkills));

      let techSkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("TEC");
      });

      dispatch(initalizeTechSkillsOverview(techSkills));

      let thirdPartySkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("3rd");
      });

      dispatch(initalizeThirdPartySkilsOverview(thirdPartySkills));

      let businessSkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("BUS");
      });

      dispatch(initalizeBusinessSkillsOverview(businessSkills));

      let consultingSkills = response.data.filter((skill) => {
        return skill.skillCategory.includes("Consulting");
      });

      //   initalize Business skills
      dispatch(initalizeConsultingSkillsOverview(consultingSkills));
    });
  }, []);

  // make request to obtain role of user (employee or admin)
  useEffect(() => {
    axiosInstance.get("/base/users").then((response) => {
      dispatch(initalizeAdmin(response.data));
    });

    axiosInstance.get("/cvapp/all-employee-names").then((response) => {
      const userNames = [];
      const userIds = [];

      dispatch(initalizeUsersComplete(response.data));

      response.data.map((user) => {
        userNames.push(user.name);
        userIds.push(user.id);
      });

      dispatch(initalizeUsersName(userNames));
      dispatch(initalizeUsersId(userIds));
    });

    // get employees and their user roles
    axiosInstance
      .get("/cvapp/user-roles/")
      .then((response) => {
        dispatch(initalizeUserRoles(response.data));

        var managers = [];

        // iterate through data to find manager user roles
        response.data.map((user) => {
          if (user.userRole.includes("Manager")) {
            managers.push({ id: user.id, name: user.name });
          }
        });

        dispatch(initalizeManagerUsers(managers));
      })
      .catch((error) => {
        console.log("error GET /cvapp/user-roles/: ", error);
      });

  }, []);

  return (
    <>
      <div className="outer-div-content-wrapper">
        {/* <BackButton /> */}
        <div className="back-button-navigation-grid-item-1"></div>
        <div className="main-content-wrapper" ref={ref}>
          <Header />
          {/* <TitleCard
            title="Create your CV"
            subtitle="Download your CV"
            illustration={illustration}
            scale="0.5"
          /> */}
          <ContentCreateCVPageDynamic />
        </div>
        <div className="next-button-navigation-grid-item-3"> </div>
      </div>
      <SuccessAlert
        openState={alertmessageState.isSuccessAlertOpen}
        message={alertmessageState.alertMessage}
        stateHandlerFunction={setIsSuccessAlertOpen}
      />
      <FailAlert
        openState={alertmessageState.isFailAlertOpen}
        message={alertmessageState.alertMessage}
        stateHandlerFunction={setIsFailAlertOpen}
      />
    </>
  );
}

export default CreateCvPage;
