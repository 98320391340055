import React from "react";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import arrowDownIcon from "../../../../assets/arrow_down_icon.svg";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import bulletPoint from "../../../../assets/bullet_point_cv.svg";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import {
  changeTextAreaFocused,
  handleKeySkillObjectChange,
} from "../../../../features/selectedExperiences";
import axiosInstance from "../../../../axios/axiosConfig";

function SelectedExperience(props) {
  const dispatch = useDispatch();

  return (
    <div style={{ display: "flex", marginTop: "8px" }}>
      <img
        src={bulletPoint}
        alt="bullet point"
        style={{
          scale: "0.8",
          marginLeft: "5px",
          marginRight: "12px",
          alignSelf: "flex-start",
          marginTop: "7px",
        }}
      />
      <OutlinedInput
        className="outlinedinput-dynamic-cv"
        value={props.experienceObject.skill}
        onClick={(e) => {
          dispatch(changeTextAreaFocused(false));
          const { target } = e;
          target.focus();
        }}
        onChange={(e) => {
          dispatch(
            handleKeySkillObjectChange({
              id: props.experienceObject.id,
              property: "skill",
              value: e.target.value,
            })
          );
        }}
        onBlur={(e) => {
          dispatch(changeTextAreaFocused(true));
          axiosInstance
            .put("/cvapp/skills/", {
              id: props.experienceObject.id,
              skill: e.target.value,
            })
            .then((response) => {
              dispatch(
                handleKeySkillObjectChange({
                  id: props.experienceObject.id,
                  property: "skill",
                  value: response.data.skill,
                })
              );
            })
            .catch((error) => {
              console.log("error updating degree: ", error);
            });
        }}
        sx={{ width: "90%" }}
        placeholder="write your key experience here ..."
        multiline
      />
      <Tooltip title="Move to Other section" arrow>
        <IconButton
          style={{ marginTop: "-8px", maxHeight: "42px" }}
          onClick={() => {
            props.handleMove(props.experienceObject.id, props.experienceObject);
          }}>
          <img src={arrowDownIcon} alt="arrow down icon" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Include in CV" arrow>
        <Checkbox
          checked={props.experienceObject.isIncludedInCv}
          onChange={(e) => {
            axiosInstance
              .put("/cvapp/skills/", {
                id: props.experienceObject.id,
                isIncludedInCv: e.target.checked,
              })
              .then((response) => {
                dispatch(
                  handleKeySkillObjectChange({
                    id: response.data.id,
                    property: "isIncludedInCv",
                    value: response.data.isIncludedInCv,
                  })
                );
              })
              .catch((error) => {
                console.log("error updating isIncludedInCv key skill: ", error);
              });
          }}
          style={{ maxHeight: "42px", marginTop: "-8px" }}
        />
      </Tooltip>
      <Tooltip
        title="Delete key experience"
        arrow
        //   open={!isToolTipDisabled && isHover}
      >
        <IconButton
          style={{ marginTop: "-8px", maxHeight: "42px" }}
          onClick={() => {
            props.handleDelete(props.experienceObject.id);
          }}>
          <img src={binIcon} alt="bin icon" />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default SelectedExperience;
