import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./LeaveRequest.css";
import axiosInstance from "../../axios/axiosConfig";
import SelectCustomized from "../../components/input_components/SelectCustomized";
import { useDispatch } from "react-redux";
import { setDataPublicHolidays } from "../../features/leaveRequestSlice";
import DataView from "./components/PublicHolidays/DataView";
import { useSelector } from "react-redux";

function PublicHolidays() {
  const leaveRequestState = useSelector((state) => state.leaverequest);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const dispatch = useDispatch();

  useEffect(() => {
    axiosInstance
      .get("/timemate/public-holidays/", {
        params: {
          year: selectedYear,
        },
      })
      .then((response) => {
        dispatch(setDataPublicHolidays(response.data));
      });
  }, [selectedYear]);

  return (
    <div className="outer-div-content-wrapper">
      <div className="back-button-navigation-grid-item-1"></div>
      <div className="main-content-wrapper">
        <Header />
        <div className="leave-request-data-container">
          <div className="data-view-header">
            <div className="table-title-leave-requests">
              {`Public Holidays ${selectedYear}`}
            </div>
            <div>
              <SelectCustomized
                label="year"
                sx={{ width: "100%" }}
                onChange={(e) => setSelectedYear(e.target.value)}
                select_options={[
                  selectedYear - 1,
                  selectedYear,
                  selectedYear + 1,
                  selectedYear + 2,
                ]}
                values={[
                  selectedYear - 1,
                  selectedYear,
                  selectedYear + 1,
                  selectedYear + 2,
                ]}
                value={selectedYear}
                name="year"
                letterSpacing={0}
              />
            </div>
          </div>
          <DataView
            data={leaveRequestState.dataPublicHolidays}
            state={leaveRequestState}
          />
        </div>
      </div>
    </div>
  );
}

export default PublicHolidays;
