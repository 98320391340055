import React, { useState, useLayoutEffect } from "react";
import Button from "@mui/material/Button";
import AddIconButton from "../../../../components/input_components/AddIconButton";
import { useSelector, useDispatch } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import {
  changeCvSummary,
  initializeCvSummary,
} from "../../../../features/cvSummarySlice";
import axiosInstance from "../../../../axios/axiosConfig";
import TextField from "@mui/material/TextField";

function DynamicCvSummary() {
  const internalInfoState = useSelector((state) => state.internalinfo);

  const cvSummaryState = useSelector((state) => state.cvsummary);
  const [addSummaryClicked, setAddSummaryClicked] = useState(false);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    // set local storage item
    localStorage.setItem(
      "cv-type",
      internalInfoState.isGeneralCV ? "General" : "Avaloq"
    );

    axiosInstance.get("/cvapp/cv-summaries/").then((response) => {
      dispatch(initializeCvSummary(response.data));
    });
  }, []);

  return (
    <div className="bounding-box-cv-summary-section">
      <div className="cv-summary-section-grid-wrapper">
        {" "}
        <div className="left-col-section-title">SUMMARY</div>
        <div>
          {cvSummaryState.cvSummary.length > 0 || addSummaryClicked ? (
            <div className="cv-summary-content-flexbox-wrapper">
              <OutlinedInput
                className="outlinedinput-dynamic-cv flex-item-1"
                placeholder="Enter your CV summary here ..."
                multiline
                value={cvSummaryState.cvSummary[0].summary}
                onChange={(e) => {
                  dispatch(changeCvSummary(e.target.value));
                }}
                onBlur={(e) => {
                  axiosInstance
                    .put("/cvapp/cv-summaries/", {
                      id: cvSummaryState.cvSummary[0].id,
                      summary: e.target.value,
                    })
                    .then((response) => {
                      dispatch(initializeCvSummary([response.data]));
                    })
                    .catch((error) => {
                      console.log("error updating degree: ", error);
                    });
                }}
                onFocus={(e) => {
                  console.log("we focus summary field");
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
              {/* <textarea /> */}
              <Tooltip title="Delete Summary" arrow>
                <IconButton
                  style={{ maxHeight: "42px", marginTop: "-8px" }}
                  onClick={(e) =>
                    axiosInstance
                      .delete("/cvapp/cv-summaries/", {
                        data: {
                          id: cvSummaryState.cvSummary[0].id,
                        },
                      })
                      .then((response) => {
                        dispatch(initializeCvSummary(response.data));
                      })
                      .catch((error) => {
                        console.log("error deleting experience");
                      })
                  }>
                  <img src={binIcon} alt="bin icon" />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div>
              <Button
                variant="contained"
                startIcon={<AddIconButton />}
                className="dynamic-cv"
                onClick={(e) => {
                  axiosInstance
                    .post("/cvapp/cv-summaries/", {
                      title: "",
                      summary: "",
                    })
                    .then((response) => {
                      setAddSummaryClicked(true);
                      console.log("response post request: ", response.data);
                      dispatch(initializeCvSummary([response.data]));
                    })
                    .catch((error) => {
                      console.log("error adding cv summary");
                    });
                }}>
                Summary
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DynamicCvSummary;
