import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function MenuCategory(props) {
  const [openCollapse, setOpenCollapse] = React.useState(props.initialOpen);
  const adminState = useSelector((state) => state.admin);
  const navigate = useNavigate();

  function handleClick() {
    setOpenCollapse(!openCollapse);
  }

  function handleMenuClick(url) {
    navigate(url);
  }

  return (
    <>
      {props.userRoles === "" ||
      adminState.cvAppUserRole.includes(props.userRoles) ? (
        <List>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon className="list-item-icon-ham-menu">
              {props.titleIcon}
            </ListItemIcon>
            <ListItemText primary={props.title} />
            {openCollapse ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            {props.subcategories.map((subcategory) => {
              return (
                <>
                  {subcategory.userRoles === "" ||
                  adminState.cvAppUserRole.includes(subcategory.userRoles) ? (
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleMenuClick(subcategory.url)}>
                        <ListItemIcon className="list-item-icon-ham-menu">
                          {subcategory.icon}
                        </ListItemIcon>
                        <ListItemText primary={subcategory.title} />
                      </ListItemButton>
                    </List>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </Collapse>
        </List>
      ) : (
        <></>
      )}
    </>
  );
}

export default MenuCategory;
