import { useMaterialReactTable } from "material-react-table";
import { Box, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import axiosInstance from "../../../../axios/axiosConfig";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { parse } from "date-fns";
import moment from "moment";
import {
  initalizeSomething,
  remvDailyGrouping,
  remvGrouping,
  setActivity,
  setActivityList,
  setActivityType,
  setComment,
  setDateValidationError,
  setDuration,
  setDurationEditValidationError,
  setSite,
  setLastChipSet,
  setSelectedDates,
  setDensityState,
} from "../../../../features/timesheetSlice";

const useStyles = makeStyles((theme) => ({
  EditButton: {
    color: "var(--philico-color)",
    "&:disabled": {
      color: "var(--philico-color)",
      //color: 'light-grey', // Change color for disabled state
    },
  },
  DeleteButton: {
    color: "var(--rejected-color)",
    "&:disabled": {
      color: "var(--rejected-color)",
      //color: 'light-grey', // Change color for disabled state
    },
  },
}));

export const useTable = (
  columns,
  data,
  monitorTimesheet,
  setMonitorTimesheet,
  desactivateEditing,
  dispatch,
  groupingState,
  timesheetState,
  enableRowActions = true
) => {
  const classes = useStyles();

  // DELETE action
  const deleteCallback = (
    table,
    row,
    monitorTimesheet,
    setMonitorTimesheet
  ) => {
    const data = {
      timesheet_entry_id: row.original.timesheet_entry_id,
    };

    axiosInstance
      .delete(
        `/timemate/employeetimesheet/?timesheet_entry_id=${row.original.timesheet_entry_id}`,
        data
      )
      .then((response) => {
        console.log("Resource deleted successfully:", response.data);
        setMonitorTimesheet(!monitorTimesheet);
      })
      .catch((error) => {
        console.error("Error deleting resource:", error);
      });
  };

  //UPDATE action
  const handleSaveUser = ({ values, table, row }) => {
    let errorFound = false;

    // check if duration is in valid format
    if (moment(values.duration, "HH:mm:ss", true).isValid()) {
      dispatch(setDurationEditValidationError(false));
    } else {
      errorFound = true;
      dispatch(setDurationEditValidationError(true));
      return;
    }

    // check if date is in valid format
    if (moment(values.date, "DD-MM-YYYY", true).isValid()) {
      dispatch(setDateValidationError(false));
    } else {
      errorFound = true;
      dispatch(setDateValidationError(true));
      return;
    }

    if (!errorFound) {
      const newRow = {
        ...row.original,
        ...values,
        activity: {
          activity_name: values.activity_name,
          activity_type: values.activity_type,
        },
      };
      delete newRow.activity_name;
      delete newRow.activity_type;

      axiosInstance
        .put("/timemate/employeetimesheet/", newRow)
        .then((response) => {
          console.log("Resource Updated successfully:", response.data);
          setMonitorTimesheet(!monitorTimesheet);
        })
        .catch((error) => {
          console.error("Error Updating resource:", error);
        });

      table.setEditingRow(null); //exit editing mode
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    renderColumnActionsMenuItems: ({ internalColumnMenuItems, additional }) => {
      internalColumnMenuItems = internalColumnMenuItems.filter(
        (menuItemObj) => {
          return (
            !menuItemObj.props.label.includes("Sort by") &&
            !menuItemObj.props.label.includes("Hide")
          );
        }
      );

      return [
        ...internalColumnMenuItems, //optionally include the internal menu items above or below your custom menu items
        // <MenuItem key="custom-menu-item-1">Custom Menu Item 1</MenuItem>,
        // <MenuItem key="custom-menu-item-2">Custom Menu Item 2</MenuItem>,
      ];
    },

    editDisplayMode: "modal",
    enableDensityToggle: true,
    enableGrouping: true,
    enableColumnDragging: false,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableRowActions: enableRowActions,
    onDensityChange: (densityState) => {
      dispatch(setDensityState(densityState));
    },
    state: {
      density: timesheetState.densityState,
    },
    initialState: {
      sorting: [
        {
          id: "date", //sort by age by default on page load
          desc: false,
        },
        {
          id: "duration", //then sort by lastName if age is the same
          desc: true,
        },
      ],
      density: timesheetState.densityState,
      grouping: groupingState,
    },
    muiTableBodyRowProps: ({ row }) => {
      // add different background color for public holiday entries
      return {
        sx: {
          backgroundColor:
            row.original?.activity_name?.toLowerCase() === "public holiday"
              ? "rgba(0,65,112, 0.05)"
              : "white",
        },
      };
    },
    //ADD HERE GROUPING CONTROL VIA STATE
    // state: {
    //   ...(groupingState?.length > 0 && { grouping: groupingState }),
    // },
    onEditingRowSave: handleSaveUser,
    onEditingRowCancel: () => {
      // clear all validation errors when editing is cancelled
      console.log("we cancel edit");
      dispatch(setDurationEditValidationError(false));
    },

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton
            style={{
              color: desactivateEditing ? "grey" : "var(--philico-blue)",
            }}
            onClick={() => table.setEditingRow(row)}
            disabled={desactivateEditing}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy Entry">
          <IconButton
            style={{
              color: desactivateEditing ? "grey" : "var(--philico-blue)",
            }}
            // onClick={() => table.setEditingRow(row)}
            onClick={async () => {
              console.log("row on click button: ", row);
              await dispatch(setActivityType(row.original.activity_type));
              await dispatch(setActivityList(row.original.activity_type));
              dispatch(setActivity(row.original.activity_name));
              dispatch(setSite(row.original.site));
              dispatch(setComment(row.original.comment));
              dispatch(
                setSelectedDates([
                  parse(row.original.date, "dd-MM-yyyy", new Date()),
                  parse(row.original.date, "dd-MM-yyyy", new Date()),
                ])
              );
              dispatch(setDuration(row.original.duration));
            }}
            disabled={desactivateEditing}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            style={{
              color: desactivateEditing ? "grey" : "var(--philico-blue)",
            }}
            onClick={() =>
              deleteCallback(table, row, monitorTimesheet, setMonitorTimesheet)
            }
            disabled={desactivateEditing}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    muiTableContainerProps: { sx: { margin: "0px", flexGrow: 1 } },
  });

  return table;
};
