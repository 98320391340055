import React from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

function SelectCustomized(props) {
  return (
    // <div>
    <FormControl
      fullWidth={props.fullWidth}
      error={props.error}
      disabled={props.disabled ? props.disabled : false}
      style={{ marginTop: props.marginTop }}>
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      <Select
        //   value={age}
        required={props.required}
        name={props.name}
        value={props.value}
        label={props.label}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        sx={{
          // width: "40%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "philicoblue.main",
          },
          "& .MuiOutlinedInput-input": {
            fontFamily: "Montserrat",
          },
          // define hover state of area of input element
          "&:hover .MuiOutlinedInput-input": {
            backgroundColor: "textfield_background_color.main",
          },

          // define letter spacing of label; letterspacing = 0.9 gives enough space to label when focused
          "& legend": {
            letterSpacing: props.letterSpacing, //if label in focus state does not have enough space increase this number
            fontFamily: "Montserrat",
          },
        }}
        onChange={props.onChange}
        onBlur={props.onBlur}>
        {props.select_options.map((item, index) => {
          return (
            <MenuItem key={index} value={props.values[index]}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
      {props.displayHelperText && (
        <FormHelperText>{props.helperText}</FormHelperText>
      )}
    </FormControl>
    // </div>
  );
}

SelectCustomized.defaultProps = {
  onChange: (e) => {},
  onBlur: (e) => {},
  name: "",
  helperText: " ",
  displayHelperText: true,
  defaultValue: "",
  disabled: false,
  required: false,
};

export default SelectCustomized;
