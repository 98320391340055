import { MaterialReactTable } from "material-react-table";
import React, { useMemo } from "react";
import { tableColumns, useReactTable } from "./helpers";

function DataView(props) {
  const columns = useMemo(() => tableColumns(), [props.data]);

  // create table objects
  const table = useReactTable(columns, props.data);

  return <MaterialReactTable table={table} />;
}

export default DataView;
