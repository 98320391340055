import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  avqSkills: [],
  avqSkillsOverview: [],

  techSkills: [],
  techSkillsOverview: [],

  thirdPartySkills: [],
  thirdPartySkillsOverview: [],

  businessSkills: [],
  businessSkillsOverview: [],

  consultingSkills: [],
  consultingSkillsOverview: [],

  namesAndImages: [],
  employeeSkillsPerCategory: [],

  avqSkillsOpen: false,
  avqSkillsOpenOverview: true,

  techSkillsOpen: false,
  techSkillsOpenOverview: false,

  thirdPartySkillsOpen: false,
  thirdPartySkillsOpenOverview: false,

  businessSkillsOpen: false,
  businessSkillsOpenOverview: false,

  consultingSkillsOpen: false,
  consultingSkillsOpenOverview: false,
};

export const skillMatrixSlice = createSlice({
  name: "skillsmatrix",
  initialState,
  reducers: {
    initalizeAvqSkills: (state, action) => {
      state.avqSkills = action.payload;
    },
    initalizeAvqSkillsOverview: (state, action) => {
      state.avqSkillsOverview = action.payload;
    },
    initalizeTechSkills: (state, action) => {
      state.techSkills = action.payload;
    },
    initalizeThirdPartySkils: (state, action) => {
      state.thirdPartySkills = action.payload;
    },
    initalizeConsultingSkills: (state, action) => {
      state.consultingSkills = action.payload;
    },
    initalizeBusinessSkills: (state, action) => {
      state.businessSkills = action.payload;
    },
    initalizeTechSkillsOverview: (state, action) => {
      state.techSkillsOverview = action.payload;
    },
    initalizeConsultingSkillsOverview: (state, action) => {
      state.consultingSkillsOverview = action.payload;
    },
    initalizeBusinessSkillsOverview: (state, action) => {
      state.businessSkillsOverview = action.payload;
    },
    initalizeThirdPartySkilsOverview: (state, action) => {
      state.thirdPartySkillsOverview = action.payload;
    },
    initalizeNamesAndImages: (state, action) => {
      state.namesAndImages = action.payload;
    },
    initalizeEmployeeSkillsPerCategory: (state, action) => {
      state.employeeSkillsPerCategory = action.payload;
    },
    updateAvqSkill: (state, action) => {
      var newState = state.avqSkills.map((skill) => {
        if (
          skill.skillCategory === action.payload.category &&
          skill.skillSubCategory === action.payload.subCategory
        ) {
          return {
            ...skill,
            id: action.payload.id,
            [action.payload.property]: action.payload.value,
          };
        } else {
          return skill;
        }
      });

      state.avqSkills = newState;
    },
    updateTechSkill: (state, action) => {
      var newState = state.techSkills.map((skill) => {
        if (
          skill.skillCategory === action.payload.category &&
          skill.skillSubCategory === action.payload.subCategory
        ) {
          return {
            ...skill,
            id: action.payload.id,
            [action.payload.property]: action.payload.value,
          };
        } else {
          return skill;
        }
      });

      state.techSkills = newState;
    },
    updateThirdPartySkill: (state, action) => {
      var newState = state.thirdPartySkills.map((skill) => {
        if (
          skill.skillCategory === action.payload.category &&
          skill.skillSubCategory === action.payload.subCategory
        ) {
          return {
            ...skill,
            id: action.payload.id,
            [action.payload.property]: action.payload.value,
          };
        } else {
          return skill;
        }
      });

      state.thirdPartySkills = newState;
    },
    updateBusinessSkills: (state, action) => {
      var newState = state.businessSkills.map((skill) => {
        if (
          skill.skillCategory === action.payload.category &&
          skill.skillSubCategory === action.payload.subCategory
        ) {
          return {
            ...skill,
            id: action.payload.id,
            [action.payload.property]: action.payload.value,
          };
        } else {
          return skill;
        }
      });

      state.businessSkills = newState;
    },
    updateConsultingSkill: (state, action) => {
      var newState = state.consultingSkills.map((skill) => {
        if (
          skill.skillCategory === action.payload.category &&
          skill.skillSubCategory === action.payload.subCategory
        ) {
          return {
            ...skill,
            id: action.payload.id,
            [action.payload.property]: action.payload.value,
          };
        } else {
          return skill;
        }
      });

      state.consultingSkills = newState;
    },
    changeAvqSkillsOpen: (state, action) => {
      state.avqSkillsOpen = action.payload;
    },
    changeConsultingSkillsOpen: (state, action) => {
      state.consultingSkillsOpen = action.payload;
    },
    changeConsultingSkillsOpenOverview: (state, action) => {
      state.consultingSkillsOpenOverview = action.payload;
    },
    changeAvqSkillsOpenOverview: (state, action) => {
      state.avqSkillsOpenOverview = action.payload;
    },
    changeBusinessSkillsOpenOverview: (state, action) => {
      state.businessSkillsOpenOverview = action.payload;
    },
    changeTechSkillsOpenOverview: (state, action) => {
      state.techSkillsOpenOverview = action.payload;
    },
    changeThirdPartySkillsOpenOverview: (state, action) => {
      state.thirdPartySkillsOpenOverview = action.payload;
    },
    changeTechSkillsOpen: (state, action) => {
      state.techSkillsOpen = action.payload;
    },
    changeBusinessSkillsOpen: (state, action) => {
      state.businessSkillsOpen = action.payload;
    },
    changeThirdPartyApplicationOpen: (state, action) => {
      state.thirdPartySkillsOpen = action.payload;
    },
  },
});

export const {
  initalizeAvqSkills,
  updateAvqSkill,
  changeAvqSkillsOpenOverview,
  changeTechSkillsOpenOverview,
  changeBusinessSkillsOpenOverview,
  changeBusinessSkillsOpen,
  changeConsultingSkillsOpen,
  changeConsultingSkillsOpenOverview,
  initalizeBusinessSkillsOverview,
  initalizeConsultingSkills,
  updateTechSkill,
  initalizeThirdPartySkilsOverview,
  updateConsultingSkill,
  changeThirdPartySkillsOpenOverview,
  updateBusinessSkills,
  updateThirdPartySkill,
  changeAvqSkillsOpen,
  changeTechSkillsOpen,
  initalizeThirdPartySkils,
  changeThirdPartyApplicationOpen,
  initalizeTechSkills,
  initalizeBusinessSkills,
  initalizeAvqSkillsOverview,
  initalizeTechSkillsOverview,
  initalizeConsultingSkillsOverview,
  initalizeNamesAndImages,
  initalizeEmployeeSkillsPerCategory,
} = skillMatrixSlice.actions;

export default skillMatrixSlice.reducer;
