import { createSlice } from "@reduxjs/toolkit";
import { resetPageLayout } from "../components/CardsLayoutsHelperFunctions";
import axiosInstance from "../axios/axiosConfig";
import { resetReduxState } from "./resetState";

const initialState = {
  positionWithinPhilico: "",
  philicoCoaches: [],
  coach: -1,
  startDatePhilico: null,
  subcontractor: false,
  identityDocuments: [],
  isDocRaptorTestMode: true,
  isGeneralCV: false,
  isSalarySameAsExpenses: true,
  isSalaryAddressSet: false,
  isExpenseAddressSet: false,
  openBackdropPhilicoLogo: false,
  salaryAddressComplete: "",
  expensesAddressComplete: "",
  permit: "",
  ahv: "",
  placeOfOrigin: "",
  withholdingTax: false,
  layout: [],
  salaryAccount: {
    iban: "",
    currency: "",
    bankName: "",
    street: "",
    streetNr: "",
    zip: "",
    city: "",
    country: "",
    bic: "",
  },
  expensesAccount: {
    iban: "",
    currency: "",
    bankName: "",
    street: "",
    streetNr: "",
    zip: "",
    city: "",
    country: "",
    bic: "",
  },
};

export const internalinfoSlice = createSlice({
  name: "internalinfo",
  initialState,
  extraReducers: (builder) =>
    builder.addCase(resetReduxState, () => initialState),
  reducers: {
    handlePositionChange: (state, action) => {
      state.positionWithinPhilico = action.payload;
    },
    handleCoachChange: (state, action) => {
      state.coach = action.payload;
    },
    handleAHVChange: (state, action) => {
      state.ahv = action.payload;
    },
    handleIsGeneralCVChange: (state, action) => {
      state.isGeneralCV = action.payload;
    },
    handlePlaceOfOriginChange: (state, action) => {
      state.placeOfOrigin = action.payload;
    },
    initializePhilicoCoaches: (state, action) => {
      state.philicoCoaches = action.payload;
    },
    handlePermitChange: (state, action) => {
      state.permit = action.payload;
    },
    handleStartDateChange: (state, action) => {
      state.startDatePhilico = action.payload;
    },
    handleSubcontractorChange: (state, action) => {
      state.subcontractor = action.payload;
    },
    handleWitholdingTaxChange: (state, action) => {
      state.withholdingTax = action.payload;
    },
    handleDocRaptorTestModeChange: (state, action) => {
      state.isDocRaptorTestMode = action.payload;
    },
    initalizeIdentityDocuments: (state, action) => {
      state.identityDocuments = action.payload;
    },
    addIdentityDocument: (state, action) => {
      state.identityDocuments.push(action.payload);
    },
    setBackDropOpenPhilicoLogo: (state, action) => {
      state.openBackdropPhilicoLogo = action.payload;
    },
    syncSalaryAccountCompleteAddress: (state, action) => {
      if (
        !state.salaryAccount.street &&
        !state.salaryAccount.streetNr &&
        !state.salaryAccount.city &&
        !state.salaryAccount.country
      ) {
        state.salaryAddressComplete = "";
      } else {
        state.salaryAddressComplete = state.salaryAccount.street
          .trim()
          .concat(
            " ",
            state.salaryAccount.streetNr,
            ", ",
            state.salaryAccount.city,
            ", ",
            state.salaryAccount.country
          );
      }
    },
    syncExpensesAccountCompleteAddress: (state, action) => {
      if (
        !state.expensesAccount.street &&
        !state.expensesAccount.streetNr &&
        !state.expensesAccount.city &&
        !state.expensesAccount.country
      ) {
        state.expensesAddressComplete = "";
      } else {
        state.expensesAddressComplete = state.expensesAccount.street
          .trim()
          .concat(
            " ",
            state.expensesAccount.streetNr,
            ", ",
            state.expensesAccount.city,
            ", ",
            state.expensesAccount.country
          );
      }
    },
    changeCompleteAddressSalaryAccount: (state, action) => {
      state.salaryAddressComplete = action.payload;
    },
    changeCompleteAddressExpensesAccount: (state, action) => {
      state.expensesAddressComplete = action.payload;
    },
    initialAddressSetSalaryAccount: (state, action) => {
      state.salaryAddressComplete = state.salaryAccount.street
        .trim()
        .concat(
          " ",
          state.salaryAccount.streetNr,
          ", ",
          state.salaryAccount.city,
          ", ",
          state.salaryAccount.country
        );
    },
    initialAddressSetExpensesAccount: (state, action) => {
      state.expensesAddressComplete = state.expensesAccount.street
        .trim()
        .concat(
          " ",
          state.expensesAccount.streetNr,
          ", ",
          state.expensesAccount.city,
          ", ",
          state.expensesAccount.country
        );
    },
    isSalaryAccountAddressSet: (state, action) => {
      state.isSalaryAddressSet = action.payload;
    },
    isExpensesAccountAddressSet: (state, action) => {
      state.isExpenseAddressSet = action.payload;
    },
    changeSalaryAccountAddressComponent: (state, action) => {
      state.salaryAccount[action.payload.property] = action.payload.value;
    },
    changeExpensesAccountAddressComponent: (state, action) => {
      state.expensesAccount[action.payload.property] = action.payload.value;
    },
    changeIsSalaySameAsExpenses: (state, action) => {
      state.isSalarySameAsExpenses = action.payload;
    },
    changeSalaryAccount: (state, action) => {
      state.salaryAccount = action.payload;
    },
    changeExpensesAccount: (state, action) => {
      state.expensesAccount = action.payload;
    },
    initializeLayout: (state, action) => {
      state.layout = action.payload;
    },
    updateLayout: (state, action) => {
      var modifiedLayout = state.layout.map((element) => {
        if (element.i === action.payload.cardName) {
          return {
            ...element,
            h: action.payload.height,
            minH: action.payload.height,
            maxH: action.payload.height,
          };
        } else {
          return { ...element };
        }
      });
      state.layout = modifiedLayout;
    },
    resetLayout: (state, action) => {
      let newLayout = resetPageLayout(state.layout);

      // reset local storage item that indicates whether error occurs or not
      localStorage.setItem("errorResetLayout", JSON.stringify(false));

      // update the cards via PUT request
      for (let j = 0; j < newLayout.length; j++) {
        axiosInstance
          .put("/cvapp/cards-layout/", newLayout[j])
          .catch((error) => {
            // set error state
            localStorage.setItem("errorResetLayout", JSON.stringify(true));
            console.log("error contract card update: ", error);
          });
      }

      state.layout = newLayout;
    },
  },
});

export const {
  initializePhilicoCoaches,
  handlePositionChange,
  handleStartDateChange,
  handleSubcontractorChange,
  initalizeIdentityDocuments,
  addIdentityDocument,
  changeCompleteAddressSalaryAccount,
  changeCompleteAddressExpensesAccount,
  initialAddressSetSalaryAccount,
  handleCoachChange,
  syncSalaryAccountCompleteAddress,
  isSalaryAccountAddressSet,
  changeSalaryAccountAddressComponent,
  changeIsSalaySameAsExpenses,
  changeSalaryAccount,
  changeExpensesAccount,
  initialAddressSetExpensesAccount,
  changeExpensesAccountAddressComponent,
  syncExpensesAccountCompleteAddress,
  isExpensesAccountAddressSet,
  initializeLayout,
  updateLayout,
  resetLayout,
  handleAHVChange,
  handlePlaceOfOriginChange,
  handleWitholdingTaxChange,
  handleDocRaptorTestModeChange,
  handlePermitChange,
  handleIsGeneralCVChange,
  setBackDropOpenPhilicoLogo,
} = internalinfoSlice.actions;

export default internalinfoSlice.reducer;
