import { configureStore } from "@reduxjs/toolkit";
import navigationReducer from "../features/navigationSlice";
import profileinfoReducer from "../features/profileinfoSlice";
import personalinfoReducer from "../features/personalinfoSlice";
import languageskillReducer from "../features/languageSkillSlice";
import internalinfoReducer from "../features/internalinfoSlice";
import educationReducer from "../features/educationSlice";
import experienceReducer from "../features/experienceSlice";
import descriptionReducer from "../features/descriptionSlice";
import createcvReducer from "../features/createcvSlice";
import cvsummaryReducer from "../features/cvSummarySlice";
import alertmessageReducer from "../features/alertMessageSlice";
import selectedExperiencesReducer from "../features/selectedExperiences";
import otherExperienceReducer from "../features/otherExperience";
import projectExperienceReducer from "../features/cvProjectExperience";
import employmentHistoryReducer from "../features/employmentHistory";
import degreeReducer from "../features/cvDegrees";
import certificateReducer from "../features/cvCertificates";
import adminReducer from "../features/adminSlice";
import skillMatrixReducer from "../features/skillsMatrixSlice";
import cvDataWorkflowReducer from "../features/cvDataWorkflowSlice";
import timeSheetReducer from "../features/timesheetSlice";
import leaveRequestReducer from "../features/leaveRequestSlice";
import { REGISTER } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"; // ADDED

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import hardSet from "redux-persist/es/stateReconciler/hardSet";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
  // stateReconciler: hardSet,
};

const reducer = combineReducers({
  navigation: navigationReducer,
  profileinfo: profileinfoReducer,
  personalinfo: personalinfoReducer,
  education: educationReducer,
  languageskill: languageskillReducer,
  internalinfo: internalinfoReducer,
  experience: experienceReducer,
  description: descriptionReducer,
  createcv: createcvReducer,
  alertmessage: alertmessageReducer,
  selectedexperience: selectedExperiencesReducer,
  otherexperience: otherExperienceReducer,
  cvsummary: cvsummaryReducer,
  projectExperience: projectExperienceReducer,
  employmenthistory: employmentHistoryReducer,
  degrees: degreeReducer,
  certificates: certificateReducer,
  admin: adminReducer,
  skillmatrix: skillMatrixReducer,
  cvdataworkflow: cvDataWorkflowReducer,
  timesheet: timeSheetReducer,
  leaverequest: leaveRequestReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

export const persistor = persistStore(store);
