export function extractGooglePlacesAddress(address_components) {
  var salaryAccount = {};
  var propertiesNotPresent = ["streetNr", "street", "city", "country", "zip"];
  address_components.map((element) => {
    if (element.types.includes("street_number")) {
      salaryAccount.streetNr = element.long_name;
      propertiesNotPresent = propertiesNotPresent.filter(
        (element) => element !== "streetNr"
      );
    } else if (element.types.includes("route")) {
      salaryAccount.street = element.long_name;
      propertiesNotPresent = propertiesNotPresent.filter(
        (element) => element !== "street"
      );
    } else if (element.types.includes("locality")) {
      salaryAccount.city = element.long_name;
      propertiesNotPresent = propertiesNotPresent.filter(
        (element) => element !== "city"
      );
    } else if (element.types.includes("country")) {
      salaryAccount.country = element.long_name;
      propertiesNotPresent = propertiesNotPresent.filter(
        (element) => element !== "country"
      );
    } else if (element.types.includes("postal_code")) {
      salaryAccount.zip = element.long_name;
      propertiesNotPresent = propertiesNotPresent.filter(
        (element) => element !== "zip"
      );
    }
  });
  propertiesNotPresent.map((property) => {
    salaryAccount[property] = "";
    // delete salaryAccount[property];
  });
  return salaryAccount;
}
