import React from "react";
import "./reports.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import * as Yup from "yup";
import { Formik } from "formik";
import SelectCustomized from "../../../../components/input_components/SelectCustomized";
import Button from "@mui/material/Button";
import axiosInstance from "../../../../axios/axiosConfig";
import { saveAs } from "file-saver";

function TotalHoursByActivAndUsers(props) {
  // define validaiton schema for form
  const validationSchema = Yup.object({
    month: Yup.string().required("Month is required!"),
    year: Yup.string().required("Year is required"),
  });

  return (
    <div className="flex-item-accordion">
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowForwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header">
          <div className="accordion-title"> {props.reportName}</div>
          {/* <Typography></Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Formik
            initialValues={{
              month: "",
              year: "",
              //   issuingCountry: "",
              //   expiryDate: null,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              axiosInstance
                .get(props.endpointUrl, {
                  responseType: "blob",
                  params: {
                    month: values.month,
                    year: values.year,
                  },
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: "",
                  });
                  saveAs(blob, props.fileName);
                })
                .catch((error) => {
                  console.log("error custom report: ", error);
                });
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <div className="flexbox-wrapper-total-hours-report">
                  {" "}
                  <div className="select-element-report">
                    {" "}
                    <SelectCustomized
                      label="month"
                      error={
                        formik.errors.month && formik.touched.month
                          ? true
                          : false
                      }
                      helperText={
                        formik.errors.month && formik.touched.month
                          ? formik.errors.month
                          : " "
                      }
                      select_options={props.months}
                      values={[
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12",
                      ]}
                      value={formik.values.month}
                      onChange={formik.handleChange}
                      name="month"
                      fullWidth={true}
                      letterSpacing={0}
                    />
                  </div>
                  <div className="select-element-report">
                    {" "}
                    <SelectCustomized
                      label="year"
                      error={
                        formik.errors.year && formik.touched.year ? true : false
                      }
                      helperText={
                        formik.errors.year && formik.touched.year
                          ? formik.errors.year
                          : " "
                      }
                      select_options={["2024"]}
                      values={["2024"]}
                      value={formik.values.year}
                      onChange={formik.handleChange}
                      name="year"
                      // value={10}
                      fullWidth={true}
                      letterSpacing={0}
                    />
                  </div>
                </div>
                <div className="submit-button-wrapper">
                  <div className="report-submit-button">
                    <Button type="submit" variant="contained">
                      Download
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TotalHoursByActivAndUsers;
