import React from "react";

function StatusChip(props) {
  return (
    <div className="status-chip" style={{ backgroundColor: props.bg_color }}>
      {props.status}
    </div>
  );
}

export default StatusChip;
