import React, { useEffect, useRef, useReducer, useState } from "react";
import Calendar from "react-calendar";
import "./FilterCalendar.css";
import { useDispatch, useSelector } from "react-redux";
import { setFilterSelectedDates } from "../../../../features/timesheetSlice";
import { getFilteredTimesheetData, getTimesheetData } from "../../helpers";
import { addClearDatesButton } from "./helpers";

function FilterCalendar(props) {
  const timesheetState = useSelector((state) => state.timesheet);
  const [keyState, setKeyState] = useState(0);

  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const [month, year] = props.monthYear.split(" ");
  const firstDayOfMonth = new Date(`${month} 1, ${year}`);

  const selectRange = true;

  const handleDateChange = (newValue) => {
    dispatch(setFilterSelectedDates(newValue));
  };

  const handleClearClick = () => {
    dispatch(setFilterSelectedDates([null, null]));
    setKeyState(keyState + 1); //needed otherwise react-calendar will not rerender
  };

  useEffect(() => {
    // initialize filter selected dates
    dispatch(setFilterSelectedDates([null, null]));
    if (calendarRef?.current) {
      // check if button was already added to the DOM
      var clearButtonExists = !!document.getElementById(
        "clear-button-filter-dates"
      );
      if (!clearButtonExists) {
        addClearDatesButton(
          calendarRef?.current,
          "Clear Date Selection",
          handleClearClick,
          "clear-button",
          "clear-button-filter-dates"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (calendarRef?.current) {
      // check if button was already added to the DOM
      var clearButtonExists = !!document.getElementById(
        "clear-button-filter-dates"
      );
      if (!clearButtonExists) {
        addClearDatesButton(
          calendarRef?.current,
          "Clear Date Selection",
          handleClearClick,
          "clear-button",
          "clear-button-filter-dates"
        );
      }
    }
  }, [keyState]);

  useEffect(() => {
    // if both dates are null we get data for complete month
    if (
      !timesheetState.filterSelectedDates[0] &&
      !timesheetState.filterSelectedDates[1]
    ) {
      getTimesheetData(
        props.setTimesheetData,
        props.monthYear,
        props.employeeId
      );
    } else {
      // get filtered timesheet data
      getFilteredTimesheetData(
        props.setTimesheetData,
        timesheetState.filterSelectedDates,
        props.employeeId
      );
    }
  }, [timesheetState.filterSelectedDates]);

  return (
    <div className="flexbox-wrapper-filter-calendar">
      <Calendar
        key={keyState}
        onChange={handleDateChange}
        selectRange={selectRange}
        allowPartialRange={true}
        showFixedNumberOfWeeks={false}
        maxDetail={"month"}
        showNeighboringMonth={false}
        tileClassName="filter-calendar"
        inputRef={calendarRef}
        value={timesheetState.filterSelectedDates}
        activeStartDate={firstDayOfMonth}
      />
    </div>
  );
}

export default FilterCalendar;
