import React from "react";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";

function SubmitDialog(props) {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <div className="dialog-reset-layout-wrapper">
        <div className="dialog-reset-layouts-title">{props.title}</div>
        <div className="dialog-reset-layouts-subtitle">{props.body}</div>
        <div style={{ marginTop: "12px", float: "right" }}>
          <Button
            onClick={() => props.handleSubmit(props.month)}
            variant="contained">
            SUBMIT
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default SubmitDialog;
