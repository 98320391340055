import React, { useRef } from "react";
import CVGuidelines from "./CVGuidelines";
import Header from "../../components/Header";
import TitleCard from "../../components/TitleCard";
import illustration from "../../assets/page7_createcv_illustration.svg";

function ContentCvGuidelines() {
  // create ref hook to track width of title div; cards below should have same size as title card div
  const ref = useRef(null);

  return (
    <>
      <div className="outer-div-content-wrapper">
        <div className="back-button-navigation-grid-item-1"></div>
        <div className="main-content-wrapper" ref={ref}>
          <Header />
          {/* <TitleCard
            title="CV Guidlines"
            subtitle="Guidelines for filling out your CV"
            illustration={illustration}
            scale="0.5"
          /> */}
          <CVGuidelines />
        </div>
        <div className="next-button-navigation-grid-item-3"> </div>
      </div>
    </>
  );
}

export default ContentCvGuidelines;
