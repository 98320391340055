import React, { forwardRef, useState } from "react";
import { Dialog, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PhilicoLogo from "../../../../assets/philico_logo_initials.png";
import DataView from "./DataView";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  initalizeApprovedCheckedLeaveReq,
  initalizePendingCheckedLeaveReq,
  initalizeRejectedCheckedLeaveReq,
  setApprovedLeaveReqData,
  setApproverAction,
  setRejectedLeaveReqData,
  setSubmittedLeaveReqData,
} from "../../../../features/leaveRequestSlice";
import axiosInstance from "../../../../axios/axiosConfig";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ApprovalDialogWindow(props) {
  const [pendingSelectAll, setPendingSelectAll] = useState(true);
  const [rejectSelectAll, setRejectSelectAll] = useState(true);
  const [approveSelectAll, setApproveSelectAll] = useState(true);
  const [isApprovingLoading, setIsApprovingLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isReopenLoading, setIsReopenLoading] = useState(false);
  const [isReopenRejectLoading, setIsReopenRejectLoading] = useState(false);

  const leaveRequestState = useSelector((state) => state.leaverequest);
  const adminState = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const handleApproverAction = (status, is_reopen, old_status) => {
    var leave_req_ids = [];
    if (status === "Approved" || status === "Rejected") {
      leave_req_ids = leaveRequestState?.pendingCheckedLeaveReq;
    } else if (status === "Submitted" && old_status === "Approved") {
      leave_req_ids = leaveRequestState?.approvedCheckedLeaveReq;
    } else if (status === "Submitted" && old_status === "Rejected") {
      leave_req_ids = leaveRequestState?.rejectedCheckedLeaveReq;
    }

    if (status === "Approved") {
      setIsApprovingLoading(true);
    } else if (status === "Submitted" && old_status === "Approved") {
      setIsReopenLoading(true);
    } else if (status === "Submitted" && old_status === "Rejected") {
      setIsReopenRejectLoading(true);
    } else if (status === "Rejected") {
      setIsRejectLoading(true);
    }
    axiosInstance
      .put(
        "/timemate/leaverequest-status/",
        {
          status: status,
          leave_req_ids: leave_req_ids,
        },
        {
          params: {
            managed_employee: props.employeeId,
            year: props.year,
            is_reopen: is_reopen,
            old_status: old_status,
          },
        }
      )
      .then((response) => {
        dispatch(setApproverAction(!leaveRequestState.approverAction));
        axiosInstance
          .get("/timemate/leaverequests/", {
            params: {
              year: props.year,
              managed_employee: props.employeeId,
            },
          })
          .then((response) => {
            //   create subarrays for status Submitted, Approved, Rejected
            let submitted = response.data.filter((leave_req) => {
              return leave_req.status.trim() === "Submitted";
            });
            let approved = response.data.filter((leave_req) => {
              return leave_req.status.trim() === "Approved";
            });
            let rejected = response.data.filter((leave_req) => {
              return leave_req.status.trim() === "Rejected";
            });

            //extract ids of submitted entries
            let submitted_ids = submitted.map((el) => el.leave_request_id);
            let approved_ids = approved.map((el) => el.leave_request_id);
            let rejected_ids = rejected.map((el) => el.leave_request_id);

            setIsApprovingLoading(false);
            setIsReopenLoading(false);
            setIsReopenRejectLoading(false);
            setIsRejectLoading(false);

            dispatch(initalizePendingCheckedLeaveReq(submitted_ids));
            dispatch(initalizeApprovedCheckedLeaveReq(approved_ids));
            dispatch(initalizeRejectedCheckedLeaveReq(rejected_ids));

            dispatch(setSubmittedLeaveReqData(submitted));
            dispatch(setApprovedLeaveReqData(approved));
            dispatch(setRejectedLeaveReqData(rejected));
          })
          .catch((error) => {
            setIsApprovingLoading(false);
            setIsReopenLoading(false);
            setIsReopenRejectLoading(false);
            setIsRejectLoading(false);

            console.log("error GET /timemate/leaverequests/: ", error);
          });
      })
      .catch((error) => {
        console.log("error submitting data");
      });
  };

  const handlePendingSelectAll = (e) => {
    // if Select all changes from unselected to selected fetch data
    if (e.target.checked) {
      axiosInstance
        .get("/timemate/leaverequests/", {
          params: {
            year: props.year,
            managed_employee: props.employeeId,
          },
        })
        .then((response) => {
          //   create subarrays for status Submitted, Approved, Rejected
          let submitted = response.data.filter((leave_req) => {
            return leave_req.status.trim() === "Submitted";
          });
          //extract ids of submitted entries
          let submitted_ids = submitted.map((el) => el.leave_request_id);

          dispatch(initalizePendingCheckedLeaveReq(submitted_ids));
          setPendingSelectAll(!pendingSelectAll);
        })
        .catch((error) => {
          console.log("error GET /timemate/leaverequests/: ", error);
        });
    } else {
      dispatch(initalizePendingCheckedLeaveReq([]));
      setPendingSelectAll(!pendingSelectAll);
    }
  };

  const handleRejectSelectAll = (e) => {
    // if Select all changes from unselected to selected fetch data
    if (e.target.checked) {
      axiosInstance
        .get("/timemate/leaverequests/", {
          params: {
            year: props.year,
            managed_employee: props.employeeId,
          },
        })
        .then((response) => {
          //   create subarrays for status Submitted, Approved, Rejected
          let rejected = response.data.filter((leave_req) => {
            return leave_req.status.trim() === "Rejected";
          });
          //extract ids of submitted entries
          let rejected_ids = rejected.map((el) => el.leave_request_id);

          dispatch(initalizeRejectedCheckedLeaveReq(rejected_ids));
          setRejectSelectAll(!rejectSelectAll);
        })
        .catch((error) => {
          console.log("error GET /timemate/leaverequests/: ", error);
        });
    } else {
      dispatch(initalizeRejectedCheckedLeaveReq([]));
      setRejectSelectAll(!rejectSelectAll);
    }
  };

  const handleApproveSelectAll = (e) => {
    // if Select all changes from unselected to selected fetch data
    if (e.target.checked) {
      axiosInstance
        .get("/timemate/leaverequests/", {
          params: {
            year: props.year,
            managed_employee: props.employeeId,
          },
        })
        .then((response) => {
          //   create subarrays for status Submitted, Approved, Rejected
          let approved = response.data.filter((leave_req) => {
            return leave_req.status.trim() === "Approved";
          });
          //extract ids of submitted entries
          let approved_ids = approved.map((el) => el.leave_request_id);

          dispatch(initalizeApprovedCheckedLeaveReq(approved_ids));
          setApproveSelectAll(!approveSelectAll);
        })
        .catch((error) => {
          console.log("error GET /timemate/leaverequests/: ", error);
        });
    } else {
      dispatch(initalizeApprovedCheckedLeaveReq([]));
      setApproveSelectAll(!approveSelectAll);
    }
  };

  return (
    <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
      <AppBar
        sx={{ position: "sticky", backgroundColor: "white" }}
        fullScreen={true}>
        <Toolbar sx={{ height: "100px" }}>
          <img
            src={PhilicoLogo}
            style={{ marginLeft: "25px", width: "64px", height: "64px" }}
          />

          <Typography
            sx={{ ml: 2, flex: 1, color: "#004170", fontWeight: "bold" }}
            variant="h5"
            component="div">
            {`Leave Requests ${props.year} - ${props.employeeFullName}`}
          </Typography>
          <IconButton
            autoFocus
            onClick={props.closeDialogCallback}
            sx={{ mr: 0 }}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      ;
      <div style={{ width: "98%", margin: "0 auto" }}>
        <div className="leave-request-data-container">
          <div className="data-view-header">
            <div style={{ marginLeft: "12px" }}>
              <div className="table-title-leave-requests">Pending</div>
              <FormControlLabel
                control={
                  <Checkbox
                    className="checkbox-admin-leave-request"
                    onChange={(e) => handlePendingSelectAll(e)}
                    disabled={
                      leaveRequestState.submittedLeaveReqData.length === 0
                    }
                    checked={
                      leaveRequestState.submittedLeaveReqData.length ===
                        leaveRequestState.pendingCheckedLeaveReq.length &&
                      leaveRequestState.submittedLeaveReqData.length
                    }
                    indeterminate={
                      leaveRequestState.submittedLeaveReqData.length !==
                        leaveRequestState.pendingCheckedLeaveReq.length &&
                      leaveRequestState.pendingCheckedLeaveReq.length > 0
                    }
                  />
                }
                label="Select all"
              />
            </div>
            {adminState.loggedInUser !== props.employeeId && (
              <div
                className="approve-reject-button-group"
                style={{ marginRight: "12px" }}>
                <Button onClick={() => handleApproverAction("Approved")}>
                  {isApprovingLoading ? (
                    <CircularProgress
                      className="circular-progress-download-cv"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginLeft: "-4px",
                      }}
                    />
                  ) : (
                    "Approve"
                  )}
                </Button>
                <Button onClick={() => handleApproverAction("Rejected")}>
                  {isRejectLoading ? (
                    <CircularProgress
                      className="circular-progress-download-cv"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginLeft: "-4px",
                      }}
                    />
                  ) : (
                    "Reject"
                  )}
                </Button>
              </div>
            )}
          </div>
          <DataView
            data={leaveRequestState.submittedLeaveReqData}
            enableRowActions={true}
            showIncludeCheckbox={true}
            checkboxState={leaveRequestState.pendingCheckedLeaveReq}
            showEditButton={false}
            showDeleteButton={false}
          />
        </div>
        <div className="leave-request-data-container">
          <div className="data-view-header">
            <div style={{ marginLeft: "12px" }}>
              <div className="table-title-leave-requests">Rejected</div>
              <FormControlLabel
                control={
                  <Checkbox
                    className="checkbox-admin-leave-request"
                    onChange={(e) => handleRejectSelectAll(e)}
                    disabled={
                      leaveRequestState.rejectedLeaveReqData.length === 0
                    }
                    checked={
                      leaveRequestState.rejectedLeaveReqData.length ===
                        leaveRequestState.rejectedCheckedLeaveReq.length &&
                      leaveRequestState.rejectedLeaveReqData.length > 0
                    }
                    indeterminate={
                      leaveRequestState.rejectedLeaveReqData.length !==
                        leaveRequestState.rejectedCheckedLeaveReq.length &&
                      leaveRequestState.rejectedCheckedLeaveReq.length > 0
                    }
                  />
                }
                label="Select all"
              />
            </div>
            {adminState.loggedInUser !== props.employeeId && (
              <div
                className="approve-reject-button-group"
                style={{ marginRight: "12px" }}>
                <Button
                  onClick={() =>
                    handleApproverAction("Submitted", true, "Rejected")
                  }>
                  {isReopenRejectLoading ? (
                    <CircularProgress
                      className="circular-progress-download-cv"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginLeft: "-4px",
                      }}
                    />
                  ) : (
                    "Reopen"
                  )}
                </Button>
              </div>
            )}
          </div>
          <DataView
            data={leaveRequestState.rejectedLeaveReqData}
            enableRowActions={true}
            showIncludeCheckbox={true}
            checkboxState={leaveRequestState.rejectedCheckedLeaveReq}
            showEditButton={false}
            showDeleteButton={false}
          />
        </div>
        <div className="leave-request-data-container">
          <div className="data-view-header">
            <div style={{ marginLeft: "12px" }}>
              <div
                className="table-title-leave-requests"
                // style={{ marginLeft: "12px" }}
              >
                Approved
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    className="checkbox-admin-leave-request"
                    onChange={(e) => handleApproveSelectAll(e)}
                    disabled={
                      leaveRequestState.approvedLeaveReqData.length === 0
                    }
                    // checked={pendingSelectAll}
                    checked={
                      leaveRequestState.approvedLeaveReqData.length ===
                        leaveRequestState.approvedCheckedLeaveReq.length &&
                      leaveRequestState.approvedLeaveReqData.length
                    }
                    indeterminate={
                      leaveRequestState.approvedLeaveReqData.length !==
                        leaveRequestState.approvedCheckedLeaveReq.length &&
                      leaveRequestState.approvedCheckedLeaveReq.length > 0
                    }
                  />
                }
                label="Select all"
              />
            </div>
            {adminState.loggedInUser !== props.employeeId && (
              <div
                className="approve-reject-button-group"
                style={{ marginRight: "12px" }}>
                <Button
                  onClick={() =>
                    handleApproverAction("Submitted", true, "Approved")
                  }>
                  {isReopenLoading ? (
                    <CircularProgress
                      className="circular-progress-download-cv"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginLeft: "-4px",
                      }}
                    />
                  ) : (
                    "Reopen"
                  )}
                </Button>
              </div>
            )}
          </div>
          <DataView
            data={leaveRequestState.approvedLeaveReqData}
            enableRowActions={true}
            showIncludeCheckbox={true}
            checkboxState={leaveRequestState.approvedCheckedLeaveReq}
            showEditButton={false}
            showDeleteButton={false}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ApprovalDialogWindow;
