import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IframeCvGuidelines from "./IframeCvGuidelines";

function CVGuidelines() {
  const navigate = useNavigate();
  const ref = useRef();
  const [height, setHeight] = useState("0px");

  // const onLoad = () => {
  //   setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  //   console.log(
  //     "height of iframe: ",
  //     ref.current.contentWindow.document.documentElement.scrollHeight
  //   );
  // };
  // useEffect(() => {
  //   onLoad();
  // }, []);

  return (
    <>
      <div className="cv-guideline-button-wrapper">
        <Button
          variant="outlined"
          className="cv-guidelines"
          onClick={() => {
            navigate("/create-cv");
          }}>
          BACK TO CV
        </Button>
      </div>
      <div className="cv-guideline-wrappers release-notes">
        {/* <IframeCvGuidelines /> */}
        <iframe
          // ref={ref}
          // onLoad={onLoad}
          // scrolling="no"
          // style={{ overflow: "auto" }}
          // scrolling="no"
          className="iframe-cv-guidelines"
          src="https://docs.google.com/document/d/e/2PACX-1vRX0tMBi_uqLYIECgVp_cdqGb2JCA608kK9VpXRXWZ_Bz14tM1pStPO7QWa-pk9bXtRiHavR4frS4I6/pub?embedded=true"></iframe>
      </div>
    </>
  );
}

export default CVGuidelines;
