import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cvSummary: [],
};

export const cvsummarySlice = createSlice({
  name: "cvsummary",
  initialState,
  reducers: {
    // changeCvSummary: (state, action) => {
    //   state.cvSummary = action.payload;
    // },
    changeCvSummary: (state, action) => {
      // state.cvSummary.summary = action.payload;
      let newState = state.cvSummary.map((summary) => {
        return {
          ...summary,
          summary: action.payload,
        };
      });
      console.log("new state cv summary: ", newState);
      state.cvSummary = newState;
    },
    initializeCvSummary: (state, action) => {
      state.cvSummary = action.payload;
    },
  },
});

export const { initializeCvSummary, changeCvSummary } = cvsummarySlice.actions;

export default cvsummarySlice.reducer;
