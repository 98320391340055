import React from "react";
import { useLayoutEffect, useRef, useState } from "react";
import TitleCard from "../../components/TitleCard";
import illustration from "../../assets/page1_profile_illustration.svg";
import useWindowDimensions from "../../components/useWindowDimensions";
import Header from "../../components/Header";
import FailAlert from "../../components/FailAlert";
import SuccessAlert from "../../components/SuccessAlert";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsSuccessAlertOpen,
  setIsFailAlertOpen,
} from "../../features/alertMessageSlice";
import ContentInternalInfoPage from "../InternalPage/components/ContentInternalInfoPage";

function ProfilePage() {
  // const [playAnimation, setPlayAnimation] = useState(true);

  // get alertmessage state
  const alertmessageState = useSelector((state) => state.alertmessage);

  // create dispatch hook
  const dispatch = useDispatch();

  // get height and width of browser window
  const { height, width } = useWindowDimensions();

  // create ref hook to track width of title div; cards below should have same size as title card div
  const ref = useRef(null);

  // create state to store the width of the parent div; the width of this div will set bounadires for card
  const [divwidth, setDivWidth] = useState(0);

  // runs immediately after React has updated the DOM
  useLayoutEffect(() => {
    // note that useLayoutEffect is a version of useEffect that fires before the browser repaints the screen. This is in contrast to useEffect that runs after react renders your component
    setDivWidth(ref.current.offsetWidth);

    // when the component unmounts we close success and fail alerts
    return () => {
      dispatch(setIsFailAlertOpen(false));
      dispatch(setIsSuccessAlertOpen(false));
    };
  }, [width]);

  return (
    <>
      <div className="outer-div-content-wrapper">
        {/* we need an empty elment as we have no back button on first page */}
        <div className="back-button-navigation-grid-item-1"></div>
        <div className="main-content-wrapper" ref={ref}>
          <Header />
          {/* <div></div> */}
          {/* <TitleCard
            title="Profile Information"
            subtitle="Enter your profile information"
            illustration={illustration}
            scale="0.65"
          /> */}
          <ContentInternalInfoPage width={divwidth} height={height} />
        </div>
      </div>
      <SuccessAlert
        openState={alertmessageState.isSuccessAlertOpen}
        message={alertmessageState.alertMessage}
        stateHandlerFunction={setIsSuccessAlertOpen}
      />
      <FailAlert
        openState={alertmessageState.isFailAlertOpen}
        message={alertmessageState.alertMessage}
        stateHandlerFunction={setIsFailAlertOpen}
      />
    </>
  );
}

export default ProfilePage;
