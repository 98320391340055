import React, { useRef, useEffect } from "react";
import TextFieldCustomized from "../../../components/input_components/TextFieldCustomized";
import SelectCustomized from "../../../components/input_components/SelectCustomized";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  changeCompleteAddressExpensesAccount,
  changeExpensesAccount,
  changeExpensesAccountAddressComponent,
  changeSalaryAccount,
  initialAddressSetExpensesAccount,
  isExpensesAccountAddressSet,
  syncExpensesAccountCompleteAddress,
} from "../../../features/internalinfoSlice";
import { useSelector, useDispatch } from "react-redux";
import { formatIBAN } from "../../../components/formatIban";
import {
  initialAddressSetSalaryAccount,
  changeCompleteAddressSalaryAccount,
  syncSalaryAccountCompleteAddress,
  isSalaryAccountAddressSet,
  changeSalaryAccountAddressComponent,
  changeIsSalaySameAsExpenses,
} from "../../../features/internalinfoSlice";
import axiosInstance from "../../../axios/axiosConfig";

function BankInfoCard(props) {
  const internalInfoState = useSelector((state) => state.internalinfo);
  const dispatch = useDispatch();

  // two refs for bank address salary account
  const autoCompleteRefSalary = useRef();
  const textfieldSalaryAccountRef = useRef();

  // two refs for bank address of expenses account
  const autoCompleteRefExpenses = useRef();
  const textfieldExpensesAccountRef = useRef();

  // define options for google map
  const options = {
    componentRestrictions: { country: ["ch", "de", "fr"] },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };

  const extractAddress = (address_components) => {
    var salaryAccount = {};
    var propertiesNotPresent = ["streetNr", "street", "city", "country", "zip"];
    address_components.map((element) => {
      if (element.types.includes("street_number")) {
        salaryAccount.streetNr = element.long_name;
        propertiesNotPresent = propertiesNotPresent.filter(
          (element) => element !== "streetNr"
        );
      } else if (element.types.includes("route")) {
        salaryAccount.street = element.long_name;
        propertiesNotPresent = propertiesNotPresent.filter(
          (element) => element !== "street"
        );
      } else if (element.types.includes("locality")) {
        salaryAccount.city = element.long_name;
        propertiesNotPresent = propertiesNotPresent.filter(
          (element) => element !== "city"
        );
      } else if (element.types.includes("country")) {
        salaryAccount.country = element.long_name;
        propertiesNotPresent = propertiesNotPresent.filter(
          (element) => element !== "country"
        );
      } else if (element.types.includes("postal_code")) {
        salaryAccount.zip = element.long_name;
        propertiesNotPresent = propertiesNotPresent.filter(
          (element) => element !== "zip"
        );
      }
    });
    propertiesNotPresent.map((property) => {
      salaryAccount[property] = "";
    });
    return salaryAccount;
  };

  useEffect(() => {
    // initalize google maps places for salary account bank address
    autoCompleteRefSalary.current = new window.google.maps.places.Autocomplete(
      textfieldSalaryAccountRef.current,
      options
    );

    // add a listener to autocomplete that we notice when address changes
    autoCompleteRefSalary.current.addListener("place_changed", () => {
      const place = autoCompleteRefSalary.current.getPlace();
      // console.log("place.geometry: ", place.geometry);

      if (typeof place !== "undefined") {
        var salaryAccount = extractAddress(place.address_components);

        // put request with the newly created address
        axiosInstance
          .put("/cvapp/bank-accounts/", {
            accountType: "Salary",
            ...salaryAccount,
          })
          .then((response) => {
            dispatch(changeSalaryAccount(response.data));
            dispatch(initialAddressSetSalaryAccount(place.address_components));
          })
          .catch((error) => {
            console.log("error updating salary account: ", error);
          });
      }
    });

    // as the expenses account input fields are rendered conditionally (based on value of check), it can happen that textfieldExpensesAccountRef.current is undefined; in this case we do not set the autocomplete
    if (typeof textfieldExpensesAccountRef.current !== "undefined") {
      // initalize google maps places for expenses account bank address
      autoCompleteRefExpenses.current =
        new window.google.maps.places.Autocomplete(
          textfieldExpensesAccountRef.current,
          options
        );

      // add a listener to autocomplete that we notice when address changes
      autoCompleteRefExpenses.current.addListener("place_changed", () => {
        const place = autoCompleteRefExpenses.current.getPlace();
        if (typeof place !== "undefined") {
          // dispatch(initialAddressSet(place.address_components));
          var expensesAccount = extractAddress(place.address_components);

          // put request with the newly created address
          axiosInstance
            .put("/cvapp/bank-accounts/", {
              accountType: "Expenses",
              ...expensesAccount,
            })
            .then((response) => {
              dispatch(changeExpensesAccount(response.data));
              dispatch(
                initialAddressSetExpensesAccount(place.address_components)
              );
              // dispatch(isExpensesAccountAddressSet(true));
            })
            .catch((error) => {
              console.log("error updating salary account: ", error);
            });
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(syncSalaryAccountCompleteAddress("something"));

    if (
      !internalInfoState.salaryAccount.street &&
      !internalInfoState.salaryAccount.zip &&
      !internalInfoState.salaryAccount.streetNr &&
      !internalInfoState.salaryAccount.city &&
      !internalInfoState.salaryAccount.country
    ) {
      dispatch(isSalaryAccountAddressSet(false));
    } else {
      dispatch(isSalaryAccountAddressSet(true));
    }
  }, [internalInfoState.salaryAccount]);

  useEffect(() => {
    // console.log("update complete address");
    dispatch(syncExpensesAccountCompleteAddress("something"));

    //   check if address is set
    if (
      !internalInfoState.expensesAccount.street &&
      !internalInfoState.expensesAccount.zip &&
      !internalInfoState.expensesAccount.streetNr &&
      !internalInfoState.expensesAccount.city &&
      !internalInfoState.expensesAccount.country
    ) {
      dispatch(isExpensesAccountAddressSet(false));
    } else {
      dispatch(isExpensesAccountAddressSet(true));
    }
  }, [internalInfoState.expensesAccount]);

  useEffect(() => {
    // check if autocompleteRefExpenses is undefined; if yes we need to initalize it
    // if (
    //   typeof autoCompleteRefExpenses.current === "undefined" &&
    //   typeof textfieldExpensesAccountRef.current !== "undefined"
    // ) {
    // initalize google maps places for expenses account bank address
    autoCompleteRefExpenses.current =
      new window.google.maps.places.Autocomplete(
        textfieldExpensesAccountRef.current,
        options
      );

    // add a listener to autocomplete that we notice when address changes
    autoCompleteRefExpenses.current.addListener("place_changed", () => {
      const place = autoCompleteRefExpenses.current.getPlace();
      if (typeof place !== "undefined") {
        // dispatch(initialAddressSet(place.address_components));
        var expensesAccount = extractAddress(place.address_components);

        // put request with the newly created address
        axiosInstance
          .put("/cvapp/bank-accounts/", {
            accountType: "Expenses",
            ...expensesAccount,
          })
          .then((response) => {
            dispatch(changeExpensesAccount(response.data));
            dispatch(
              initialAddressSetExpensesAccount(place.address_components)
            );
            // dispatch(isExpensesAccountAddressSet(true));
          })
          .catch((error) => {
            console.log("error updating salary account: ", error);
          });
      }
    });
    // }
  }, [internalInfoState.isSalarySameAsExpenses]);

  useEffect(() => {
    // load the bank account data from the database
    axiosInstance
      .get("/cvapp/bank-accounts/")
      .then((response) => {
        console.log("response bank account GET: ", response.data);

        response.data.map((account) => {
          if (account.accountType === "Salary") {
            dispatch(changeSalaryAccount(account));

            // if (
            //   !account.street &&
            //   !account.zip &&
            //   !account.streetNr &&
            //   !account.city &&
            //   !account.country
            // ) {
            //   dispatch(isSalaryAccountAddressSet(false));
            // } else {
            //   dispatch(isSalaryAccountAddressSet(true));
            // }
          } else if (account.accountType === "Expenses") {
            dispatch(changeExpensesAccount(account));
            dispatch(changeIsSalaySameAsExpenses(account.isExpensesAccount));
            // dispatch(changeIsSalaySameAsExpenses(false));
            // check if address is set
            // if (
            //   !account.street &&
            //   !account.zip &&
            //   !account.streetNr &&
            //   !account.city &&
            //   !account.country
            // ) {
            //   dispatch(isExpensesAccountAddressSet(false));
            // } else {
            //   dispatch(isExpensesAccountAddressSet(true));
            // }
          }
        });
      })
      .catch((error) => {
        console.log("error GET request: ", error);
      });
  }, []);

  return (
    <div className="content-card" ref={props.innerRef}>
      <h1 className="content-card-title">Bank Information</h1>
      <h1 className="content-card-subtitle">Salary Account</h1>
      <div className="bank-info-flexbox-wrapper">
        <div className="non-draggable bank-info-flex-item-1">
          <TextFieldCustomized
            label="IBAN"
            name="iban"
            value={internalInfoState.salaryAccount.iban}
            onChange={(e) =>
              dispatch(
                changeSalaryAccountAddressComponent({
                  property: "iban",
                  value: formatIBAN(e.target.value.replace(/\s+/g, "")),
                })
              )
            }
            onBlur={(e) => {
              axiosInstance
                .put("/cvapp/bank-accounts/", {
                  accountType: "Salary",
                  iban: e.target.value,
                })
                .then((response) => {
                  dispatch(changeSalaryAccount(response.data));
                })
                .catch((error) => {
                  console.log("error updating iban: ", error);
                });
            }}
          />
        </div>
        <div className="non-draggable bank-info-flex-item-2">
          <SelectCustomized
            label="Account Currency"
            select_options={[
              "CHF - Swiss Franc",
              "EUR - Euro",
              "GBP - Pound Sterling",
              "USD - United States Dollar",
            ]}
            values={[
              "CHF - Swiss Franc",
              "EUR - Euro",
              "GBP - Pound Sterling",
              "USD - United States Dollar",
            ]}
            value={internalInfoState.salaryAccount.currency}
            onChange={(e) => {
              axiosInstance
                .put("/cvapp/bank-accounts/", {
                  accountType: "Salary",
                  currency: e.target.value,
                })
                .then((response) => {
                  dispatch(changeSalaryAccount(response.data));
                })
                .catch((error) => {
                  console.log("error updating iban: ", error);
                });
            }}
            fullWidth={true}
            letterSpacing={0}
          />
        </div>
        <div className="non-draggable bank-info-flex-item-3">
          {" "}
          <TextFieldCustomized
            label="Bank Name"
            name="bankName"
            value={internalInfoState.salaryAccount.bankName}
            onChange={(e) =>
              dispatch(
                changeSalaryAccountAddressComponent({
                  property: "bankName",
                  value: e.target.value,
                })
              )
            }
            onBlur={(e) => {
              axiosInstance
                .put("/cvapp/bank-accounts/", {
                  accountType: "Salary",
                  bankName: e.target.value,
                })
                .then((response) => {
                  dispatch(changeSalaryAccount(response.data));
                })
                .catch((error) => {
                  console.log("error updating iban: ", error);
                });
            }}
          />
        </div>
        <div className="non-draggable bank-info-flex-item-4">
          <TextFieldCustomized
            label="BIC"
            name="bic"
            value={internalInfoState.salaryAccount.bic}
            onChange={(e) => {
              dispatch(
                changeSalaryAccountAddressComponent({
                  property: "bic",
                  value: e.target.value,
                })
              );
            }}
            onBlur={(e) => {
              axiosInstance
                .put("/cvapp/bank-accounts/", {
                  accountType: "Salary",
                  bic: e.target.value,
                })
                .then((response) => {
                  dispatch(changeSalaryAccount(response.data));
                })
                .catch((error) => {
                  console.log("error updating bic salary account: ", error);
                });
            }}
          />
        </div>
        {/* <div className="non-draggable bank-info-flex-item-4">
          <TextFieldCustomized
            label="Bank Address"
            name="bankAddress"
            inputRef={textfieldSalaryAccountRef}
            onChange={(e) => {
              dispatch(changeCompleteAddressSalaryAccount(e.target.value));
            }}
            onBlur={() => (
              setTimeout(() => {
                // we set timeout in order to give google places time to update;
                // in case that the entered address and individual fields are not the same it means they are out of sync; in that case we reset them
                const constructedAddress =
                  internalInfoState.salaryAccount.street
                    .trim()
                    .concat(
                      " ",
                      internalInfoState.salaryAccount.streetNr,
                      ", ",
                      internalInfoState.salaryAccount.city,
                      ", ",
                      internalInfoState.salaryAccount.country
                    );
                if (
                  constructedAddress !== internalInfoState.salaryAddressComplete
                ) {
                  console.log("we reset address:");
                  dispatch(syncSalaryAccountCompleteAddress("syncing"));
                }
              }),
              150
            )}
            value={internalInfoState.salaryAddressComplete}
          />
        </div> */}
        {/* {internalInfoState.isSalaryAddressSet ? (
          <>
            <div className="non-draggable bank-info-flex-item-5">
              <TextFieldCustomized
                label="Bank ZIP"
                name="bankZIP"
                value={internalInfoState.salaryAccount.zip}
                onChange={(e) =>
                  dispatch(
                    changeSalaryAccountAddressComponent({
                      property: "zip",
                      value: e.target.value,
                    })
                  )
                }
                onBlur={(e) => {
                  axiosInstance
                    .put("/cvapp/bank-accounts/", {
                      accountType: "Salary",
                      zip: e.target.value,
                    })
                    .then((response) => {
                      dispatch(changeSalaryAccount(response.data));
                    })
                    .catch((error) => {
                      console.log("error updating zip: ", error);
                    });
                }}
              />
            </div>
            <div className="non-draggable bank-info-flex-item-6">
              <TextFieldCustomized
                label="Bank City"
                name="bankCity"
                value={internalInfoState.salaryAccount.city}
                onChange={(e) =>
                  dispatch(
                    changeSalaryAccountAddressComponent({
                      property: "city",
                      value: e.target.value,
                    })
                  )
                }
                onBlur={(e) => {
                  axiosInstance
                    .put("/cvapp/bank-accounts/", {
                      accountType: "Salary",
                      city: e.target.value,
                    })
                    .then((response) => {
                      dispatch(changeSalaryAccount(response.data));
                    })
                    .catch((error) => {
                      console.log("error updating city: ", error);
                    });
                }}
              />
            </div>
            <div className="non-draggable bank-info-flex-item-7">
              <TextFieldCustomized
                label="Bank Street"
                name="bankStreet"
                value={internalInfoState.salaryAccount.street}
                onChange={(e) =>
                  dispatch(
                    changeSalaryAccountAddressComponent({
                      property: "street",
                      value: e.target.value,
                    })
                  )
                }
                onBlur={(e) => {
                  axiosInstance
                    .put("/cvapp/bank-accounts/", {
                      accountType: "Salary",
                      street: e.target.value,
                    })
                    .then((response) => {
                      dispatch(changeSalaryAccount(response.data));
                    })
                    .catch((error) => {
                      console.log("error updating street: ", error);
                    });
                }}
              />
            </div>
            <div className="non-draggable bank-info-flex-item-8">
              <TextFieldCustomized
                label="Bank Street Nr."
                name="bankStreetNr"
                value={internalInfoState.salaryAccount.streetNr}
                onChange={(e) =>
                  dispatch(
                    changeSalaryAccountAddressComponent({
                      property: "streetNr",
                      value: e.target.value,
                    })
                  )
                }
                onBlur={(e) => {
                  axiosInstance
                    .put("/cvapp/bank-accounts/", {
                      accountType: "Salary",
                      streetNr: e.target.value,
                    })
                    .then((response) => {
                      dispatch(changeSalaryAccount(response.data));
                    })
                    .catch((error) => {
                      console.log("error updating street Nr: ", error);
                    });
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )} */}
        <div className="non-draggable bank-info-flex-item-9">
          <FormGroup style={{ marginTop: "0px", marginBottom: "0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={internalInfoState.isSalarySameAsExpenses}
                  onChange={(e) => {
                    console.log("e.target.checked: ", e.target.checked);
                    axiosInstance
                      .put("/cvapp/bank-accounts/", {
                        accountType: "Expenses",
                        isExpensesAccount: e.target.checked,
                      })
                      .then((response) =>
                        dispatch(
                          changeIsSalaySameAsExpenses(
                            response.data.isExpensesAccount
                          )
                        )
                      )
                      .catch((error) =>
                        console.log("error checkbox expenses account: ", error)
                      );
                  }}
                  name="subcontractor"
                  style={{ marginTop: "0px" }}
                />
              }
              label="My expenses account is the same as my salary account"
              style={{ lineHeight: 1 }}
            />
          </FormGroup>
        </div>
      </div>
      {internalInfoState.isSalarySameAsExpenses ? (
        <></>
      ) : (
        <>
          <h1 className="content-card-subtitle" style={{ marginTop: "3.2rem" }}>
            Expenses Account
          </h1>
          <div className="bank-info-flexbox-wrapper">
            <div className="non-draggable bank-info-flex-item-1">
              <TextFieldCustomized
                label="IBAN"
                name="iban"
                value={internalInfoState.expensesAccount.iban}
                onChange={(e) =>
                  dispatch(
                    changeExpensesAccountAddressComponent({
                      property: "iban",
                      value: formatIBAN(e.target.value.replace(/\s+/g, "")),
                    })
                  )
                }
                onBlur={(e) => {
                  axiosInstance
                    .put("/cvapp/bank-accounts/", {
                      accountType: "Expenses",
                      iban: e.target.value,
                    })
                    .then((response) => {
                      dispatch(changeExpensesAccount(response.data));
                    })
                    .catch((error) => {
                      console.log("error updating iban: ", error);
                    });
                }}
              />
            </div>
            <div className="non-draggable bank-info-flex-item-2">
              <SelectCustomized
                label="Account Currency"
                select_options={[
                  "CHF - Swiss Franc",
                  "EUR - Euro",
                  "GBP - Pound Sterling",
                  "USD - United States Dollar",
                ]}
                values={[
                  "CHF - Swiss Franc",
                  "EUR - Euro",
                  "GBP - Pound Sterling",
                  "USD - United States Dollar",
                ]}
                value={internalInfoState.expensesAccount.currency}
                onChange={(e) => {
                  axiosInstance
                    .put("/cvapp/bank-accounts/", {
                      accountType: "Expenses",
                      currency: e.target.value,
                    })
                    .then((response) => {
                      console.log(
                        "response data salary account: ",
                        response.data
                      );
                      dispatch(changeExpensesAccount(response.data));
                    })
                    .catch((error) => {
                      console.log("error updating iban: ", error);
                    });
                }}
                fullWidth={true}
                letterSpacing={0}
              />
            </div>
            <div className="non-draggable bank-info-flex-item-3">
              {" "}
              <TextFieldCustomized
                label="Bank Name"
                name="bankName"
                value={internalInfoState.expensesAccount.bankName}
                onChange={(e) =>
                  dispatch(
                    changeExpensesAccountAddressComponent({
                      property: "bankName",
                      value: e.target.value,
                    })
                  )
                }
                onBlur={(e) => {
                  axiosInstance
                    .put("/cvapp/bank-accounts/", {
                      accountType: "Expenses",
                      bankName: e.target.value,
                    })
                    .then((response) => {
                      dispatch(changeExpensesAccount(response.data));
                    })
                    .catch((error) => {
                      console.log("error updating iban: ", error);
                    });
                }}
              />
            </div>
            <div className="non-draggable bank-info-flex-item-4">
              <TextFieldCustomized
                label="BIC"
                name="bic"
                value={internalInfoState.expensesAccount.bic}
                // inputRef={textfieldExpensesAccountRef}
                onChange={(e) => {
                  dispatch(
                    changeExpensesAccountAddressComponent({
                      property: "bic",
                      value: e.target.value,
                    })
                  );
                }}
                onBlur={(e) => {
                  axiosInstance
                    .put("/cvapp/bank-accounts/", {
                      accountType: "Expenses",
                      bic: e.target.value,
                    })
                    .then((response) => {
                      dispatch(changeExpensesAccount(response.data));
                    })
                    .catch((error) => {
                      console.log(
                        "error updating bic expenses account: ",
                        error
                      );
                    });
                }}
              />
            </div>

            {/* <div className="non-draggable bank-info-flex-item-4">
              <TextFieldCustomized
                label="Bank Address"
                name="bankAddress"
                inputRef={textfieldExpensesAccountRef}
                onChange={(e) => {
                  dispatch(
                    changeCompleteAddressExpensesAccount(e.target.value)
                  );
                }}
                onBlur={() => (
                  setTimeout(() => {
                    // we set timeout in order to give google places time to update;
                    // in case that the entered address and individual fields are not the same it means they are out of sync; in that case we reset them
                    const constructedAddress =
                      internalInfoState.expensesAccount.street
                        .trim()
                        .concat(
                          " ",
                          internalInfoState.expensesAccount.streetNr,
                          ", ",
                          internalInfoState.expensesAccount.city,
                          ", ",
                          internalInfoState.expensesAccount.country
                        );
                    if (
                      constructedAddress !==
                      internalInfoState.expensesAddressComplete
                    ) {
                      console.log("we reset address:");
                      dispatch(syncExpensesAccountCompleteAddress("syncing"));
                    }
                  }),
                  150
                )}
                value={internalInfoState.expensesAddressComplete}
              />
            </div> */}
            {/* {internalInfoState.isExpenseAddressSet ? (
              <>
                <div className="non-draggable bank-info-flex-item-5">
                  <TextFieldCustomized
                    label="Bank ZIP"
                    name="bankZIP"
                    value={internalInfoState.expensesAccount.zip}
                    onChange={(e) =>
                      dispatch(
                        changeExpensesAccountAddressComponent({
                          property: "zip",
                          value: e.target.value,
                        })
                      )
                    }
                    onBlur={(e) => {
                      axiosInstance
                        .put("/cvapp/bank-accounts/", {
                          accountType: "Expenses",
                          zip: e.target.value,
                        })
                        .then((response) => {
                          dispatch(changeExpensesAccount(response.data));
                        })
                        .catch((error) => {
                          console.log("error updating zip: ", error);
                        });
                    }}
                  />
                </div>
                <div className="non-draggable bank-info-flex-item-6">
                  <TextFieldCustomized
                    label="Bank City"
                    name="bankCity"
                    value={internalInfoState.expensesAccount.city}
                    onChange={(e) =>
                      dispatch(
                        changeExpensesAccountAddressComponent({
                          property: "city",
                          value: e.target.value,
                        })
                      )
                    }
                    onBlur={(e) => {
                      axiosInstance
                        .put("/cvapp/bank-accounts/", {
                          accountType: "Expenses",
                          city: e.target.value,
                        })
                        .then((response) => {
                          dispatch(changeExpensesAccount(response.data));
                        })
                        .catch((error) => {
                          console.log("error updating city: ", error);
                        });
                    }}
                  />
                </div>
                <div className="non-draggable bank-info-flex-item-7">
                  <TextFieldCustomized
                    label="Bank Street"
                    name="bankStreet"
                    value={internalInfoState.expensesAccount.street}
                    onChange={(e) =>
                      dispatch(
                        changeExpensesAccountAddressComponent({
                          property: "street",
                          value: e.target.value,
                        })
                      )
                    }
                    onBlur={(e) => {
                      axiosInstance
                        .put("/cvapp/bank-accounts/", {
                          accountType: "Expenses",
                          street: e.target.value,
                        })
                        .then((response) => {
                          dispatch(changeExpensesAccount(response.data));
                        })
                        .catch((error) => {
                          console.log("error updating street: ", error);
                        });
                    }}
                  />
                </div>
                <div className="non-draggable bank-info-flex-item-8">
                  <TextFieldCustomized
                    label="Bank Street Nr."
                    name="bankStreetNr"
                    value={internalInfoState.expensesAccount.streetNr}
                    onChange={(e) =>
                      dispatch(
                        changeExpensesAccountAddressComponent({
                          property: "streetNr",
                          value: e.target.value,
                        })
                      )
                    }
                    onBlur={(e) => {
                      axiosInstance
                        .put("/cvapp/bank-accounts/", {
                          accountType: "Expenses",
                          streetNr: e.target.value,
                        })
                        .then((response) => {
                          dispatch(changeExpensesAccount(response.data));
                        })
                        .catch((error) => {
                          console.log("error updating street Nr: ", error);
                        });
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )} */}
          </div>
        </>
      )}
    </div>
  );
}

export default BankInfoCard;
