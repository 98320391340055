import React from "react";

function ReleaseNotes() {
  return (
    <div className="cv-guideline-wrappers release-notes">
      <iframe
        className="iframe-cv-guidelines"
        src="https://docs.google.com/document/d/e/2PACX-1vQ5Uo7IM_SGAfd_3F5KbIAMlO1knH5wlQ_2KaN6U4OuSx_S_UXRrfnObDIC8NZZvGjPzgLDj3dY-Wd7/pub?embedded=true"></iframe>
    </div>
  );
}

export default ReleaseNotes;
