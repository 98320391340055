import React, { useState } from "react";
import GoogleLogin from "./GoogleLogin";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FailAlert from "../../components/FailAlert";
import { useSelector } from "react-redux";
import {
  setIsFailAlertOpen,
} from "../../features/alertMessageSlice";

function LoginPage() {
  // get alertmessage state
  const alertmessageState = useSelector((state) => state.alertmessage);

  const [backdropOpen, setBackdropOpen] = useState(false);

  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  return (
    <>
      <div className="login-page-waves-complete">
        <div className="login-page-content-container">
          <h1
            style={{
              fontSize: "100px",
              color: "#004170",
              fontFamily: "Lobster",
            }}>
            Philico
          </h1>
          <h3
            style={{
              fontSize: "32px",
              color: "#004170",
              padding: "0",
              margin: "0",
              fontFamily: "Lobster",
              fontWeight: "300",
            }}>
            App
          </h3>
          <GoogleLogin
            handleBackdropOpen={handleBackdropOpen}
            handleBackdropClose={handleBackdropClose}
          />
        </div>
        <Backdrop
          sx={{ backgroundColor: "rgba(156, 156, 156, 0.5)" }}
          open={backdropOpen}
        >
          <CircularProgress sx={{ zIndex: "2001" }} />
        </Backdrop>
        <FailAlert
          openState={alertmessageState.isFailAlertOpen}
          message={alertmessageState.alertMessage}
          stateHandlerFunction={setIsFailAlertOpen}
        />
      </div>
    </>
  );
}

export default LoginPage;
