import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employmentHistory: [],
};

export const employmentHistorySlice = createSlice({
  name: "employmenthistory",
  initialState,
  reducers: {
    initalizeEmploymentHistory: (state, action) => {
      state.employmentHistory = action.payload;
    },
    addEmploymentHistory: (state, action) => {
      state.employmentHistory.push(action.payload);
    },
    changeEmploymentHistoryObject: (state, action) => {
      var newState = state.employmentHistory.map((history) => {
        if (history.id === action.payload.id) {
          return {
            ...history,
            [action.payload.property]: action.payload.value,
          };
        }
        return history;
      });

      state.employmentHistory = newState;
    },
  },
});

export const {
  addEmploymentHistory,
  changeEmploymentHistoryObject,
  initalizeEmploymentHistory,
} = employmentHistorySlice.actions;

export default employmentHistorySlice.reducer;
