import React from "react";
import { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import "./TimesheetOverviewCard.css";
import { getAggregatedTimesheetData } from "../../helpers";

function TimesheetOverviewCard(props) {
  const [timesheetAggrData, setTimesheetAggrData] = useState([]);

  const style = {
    py: 0,
    width: "100%",
    maxWidth: "45%",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "background.paper",
    height: "fit-content",
  };

  useEffect(() => {
    getAggregatedTimesheetData(
      setTimesheetAggrData,
      props.monthYear,
      props.employeeId
    );
  }, []);

  return (
    <List sx={style}>
      <ListItem>
        <div className="list-text-title">Aggregated Hours (HH:mm)</div>
      </ListItem>
      <Divider variant="middle" component="li" />
      {timesheetAggrData.map((entry, index) => {
        return (
          <>
            <ListItem>
              <div
                className={`list-text-wrapper ${
                  index === timesheetAggrData.length - 1 && "total-hours"
                }`}>
                <div>{entry?.activity_type}</div>
                <div>{entry?.duration}</div>
              </div>
            </ListItem>
            {index < timesheetAggrData.length - 1 && (
              <Divider variant="middle" component="li" />
            )}
          </>
        );
      })}
    </List>
  );
}

export default TimesheetOverviewCard;
