import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
import Parser from "html-react-parser";

function SuccessAlert(props) {
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const dispatch = useDispatch();

  return (
    <Snackbar
      open={props.openState}
      autoHideDuration={6500}
      disableWindowBlurListener={true}
      TransitionComponent={TransitionUp}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={(event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        //   reset open state
        dispatch(props.stateHandlerFunction(false));
        // props.setStateFunction(false);
      }}
      style={{ maxWidth: "300px" }}>
      <Alert severity="success" className="success-alert">
        {/* <AlertTitle>{props.title}</AlertTitle> */}
        {Parser(props.message)}
      </Alert>
    </Snackbar>
  );
}

export default SuccessAlert;
