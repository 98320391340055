import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
// import { persistStore } from "redux-persist";
// import {persistor}
import { GoogleOAuthProvider } from "@react-oauth/google";

// let persistor = persistStore(store);
const clientIdGoogleAuth =
  "574929601716-6hb6kpnaio15bh6ukote5hfu4jb8qtbn.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // StrictMode renders components twice (on dev but not production) in order to detect any problems with your code and warn you about them (which can be quite useful).
  // <React.StrictMode>
  <Provider store={store}>
    <HashRouter>
      <PersistGate persistor={persistor}>
        <GoogleOAuthProvider clientId={clientIdGoogleAuth}>
          <App />
        </GoogleOAuthProvider>
      </PersistGate>
    </HashRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
