import React, { useEffect, useLayoutEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./styles.css";
import { OutlinedInput } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { parse } from "date-fns";
import moment from "moment";
import axiosInstance from "../../axios/axiosConfig";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import {
  changeAvqSkillsOpen,
  changeBusinessSkillsOpen,
  changeConsultingSkillsOpen,
  changeTechSkillsOpen,
  changeThirdPartyApplicationOpen,
  initalizeAvqSkills,
  initalizeBusinessSkills,
  initalizeConsultingSkills,
  initalizeTechSkills,
  initalizeThirdPartySkils,
  updateAvqSkill,
  updateBusinessSkills,
  updateConsultingSkill,
  updateTechSkill,
  updateThirdPartySkill,
} from "../../features/skillsMatrixSlice";

function ContentSkillsMatrixPage() {
  const [zIndexSkillMatrixTable, setZIndexSkillsMatrixTable] = useState(5);
  const internalInfoState = useSelector((state) => state.internalinfo);
  const skillMatrixState = useSelector((state) => state.skillmatrix);

  const dispatch = useDispatch();

  useEffect(() => {
    axiosInstance
      .get("/cvapp/skills-matrix/")
      .then((response) => {
        //   extract avq skills
        let avqSkills = response.data.filter((skill) => {
          return skill.skillCategory.includes("AVQ");
        });

        //   initalize avaloq skills
        dispatch(initalizeAvqSkills(avqSkills));

        let techSkills = response.data.filter((skill) => {
          return skill.skillCategory.includes("TEC");
        });

        //   initalize tech skills
        dispatch(initalizeTechSkills(techSkills));

        let thirdPartySkills = response.data.filter((skill) => {
          return skill.skillCategory.includes("3rd");
        });

        //   initalize 3rd party skills
        dispatch(initalizeThirdPartySkils(thirdPartySkills));

        let businessSkills = response.data.filter((skill) => {
          return skill.skillCategory.includes("BUS");
        });

        //   initalize Business skills
        dispatch(initalizeBusinessSkills(businessSkills));

        let consultingSkills = response.data.filter((skill) => {
          return skill.skillCategory.includes("Consulting");
        });

        //   initalize Business skills
        dispatch(initalizeConsultingSkills(consultingSkills));
      })
      .catch((error) => {
        console.log("error GET /cvapp/skills-matrix/: ", error);
      });
  }, []);

  const computeSkillIndex = (duration) => {
    switch (duration) {
      case "None":
        return 0;
      case "Beginner":
        return 0.25;
      case "Intermediate":
        return 0.5;
      case "Advanced":
        return 0.75;
      case "Expert":
        return 1;
      // case "+ 1 year":
      //   return 1;
      default:
        return 0;
    }
  };

  useLayoutEffect(() => {
    if (internalInfoState.openBackdropPhilicoLogo) {
      setZIndexSkillsMatrixTable(1);
    } else {
      const timeout = setTimeout(() => {
        setZIndexSkillsMatrixTable(5);
      }, 300);

      // clearTimeout(timeout);
    }
  }, [internalInfoState.openBackdropPhilicoLogo]);

  return (
    <TableContainer
      component={Paper}
      style={{ marginTop: "32px", maxHeight: "100vh" }}>
      <Table
        aria-label="collapsible table"
        stickyHeader
        style={{ position: "relative", zIndex: zIndexSkillMatrixTable }}>
        <TableHead>
          <TableRow className="header-row-skills-matrix">
            <TableCell colSpan={1} style={{ width: "40px" }} />
            <TableCell
              align="left"
              style={{ width: "200px" }}
              className="header-row-skills-matrix-column-name">
              Category
            </TableCell>
            <TableCell
              style={{ width: "300px" }}
              align="left"
              className="header-row-skills-matrix-column-name">
              Sub-Category
            </TableCell>
            {/* <TableCell
              align="left"
              colSpan={1}
              className="header-row-skills-matrix-column-name"
              style={{ width: "115px" }}>
              #Days
            </TableCell> */}
            <TableCell
              align="left"
              colSpan={1}
              className="header-row-skills-matrix-column-name"
              style={{ width: "150px" }}>
              <div style={{ display: "flex", gap: "12px" }}>
                <div> Skill Level</div>{" "}
                <Tooltip
                  className="testing"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: "600px",
                      },
                    },
                  }}
                  title={
                    <div>
                      {" "}
                      <span style={{ fontWeight: "700" }}>Beginner: </span>
                      <br />
                      <ul>
                        <li>First steps on the module</li>
                        <li>
                          Capable of handling small tasks within this area with
                          the support of a senior
                        </li>
                        <li>
                          Able to apply common consulting best practices to
                          identify needs, and problems and propose solutions
                        </li>
                      </ul>
                      <br /> <br />
                      <span style={{ fontWeight: "700" }}>
                        Intermediate:{" "}
                      </span>{" "}
                      <br />{" "}
                      <ul>
                        <li>
                          Capable of implementing the related area or part of
                          the area with limited supervision.
                        </li>
                        <li>
                          Already worked previously on different tasks in that
                          area or has business experience.
                        </li>
                        <li>
                          Able to understand business requirements in that area.
                        </li>
                        <li>
                          Capable of capturing and/or understanding business
                          requirements presented or discussed during workshops
                          or meetings with the business counterparts
                        </li>
                      </ul>{" "}
                      <br /> <br />
                      <span style={{ fontWeight: "700" }}>Advanced: </span>{" "}
                      <br />{" "}
                      <ul>
                        <li>
                          Capable of implementing the related area independently
                          without supervision.
                        </li>
                        <li>Capable of coaching people in that area.</li>
                        <li>
                          Able to write business and technical specifications in
                          that area.
                        </li>
                        <li>
                          Capable of mapping business requirements to solution
                          designs
                        </li>
                      </ul>{" "}
                      <br /> <br />
                      <span style={{ fontWeight: "700" }}>Expert: </span> <br />
                      <ul>
                        <li>
                          Excellent know-how of business and technical aspects.
                        </li>
                        <li>Several project experiences in that area</li>
                        <li>
                          Capable of running and/or leading client's business
                          and technical workshops.
                        </li>
                        <li>
                          Able to provide business and technical support on
                          multiple projects.
                        </li>
                        <li>
                          Capable of managing/leading the related area
                          independently without supervision.
                        </li>
                      </ul>
                    </div>
                  }
                  arrow>
                  <HelpOutlineIcon />
                </Tooltip>
              </div>
            </TableCell>
            <TableCell
              align="left"
              colSpan={1}
              className="header-row-skills-matrix-column-name"
              style={{ width: "220px" }}>
              Date Skill Last Used <br /> (MM.YYYY)
            </TableCell>
            <TableCell
              style={{ width: "145px" }}
              align="left"
              colSpan={2}
              className="header-row-skills-matrix-column-name">
              Last Project Manager
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              align="left"
              colSpan={1}
              className="skills-matrix-collapse-title">
              <IconButton
                className="collapse-skills-matrix-button"
                aria-label="expand row"
                size="small"
                onClick={() =>
                  dispatch(changeAvqSkillsOpen(!skillMatrixState.avqSkillsOpen))
                }>
                {skillMatrixState.avqSkillsOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell
              align="center"
              colSpan={11}
              className="skills-matrix-collapse-title">
              AVALOQ SKILLS
            </TableCell>
          </TableRow>
          {skillMatrixState.avqSkillsOpen ? (
            skillMatrixState.avqSkills.map((skill) => {
              return (
                <TableRow>
                  <TableCell />
                  {/* <TableCell>{skill.skillCategory}</TableCell> */}
                  <TableCell className="skill-matrix-table-cell">
                    {" "}
                    {skill.skillCategory}
                  </TableCell>
                  <TableCell className="skill-matrix-table-cell" align="left">
                    {skill.skillSubCategory}
                  </TableCell>
                  {/* <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="#Days"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              #Days
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateAvqSkill({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="1 month">1 month</MenuItem>
                        <MenuItem value="2 months">2 months</MenuItem>{" "}
                        <MenuItem value="3 months">3 months</MenuItem>
                        <MenuItem value="6 months">6 months</MenuItem>{" "}
                        <MenuItem value="1 year">1 year</MenuItem>{" "}
                        <MenuItem value="+ 1 year">+ 1 year</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </TableCell> */}
                  <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="Skill Level"
                        // value="None"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              Skill Level
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateAvqSkill({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Beginner">Beginner</MenuItem>{" "}
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Advanced">Advanced</MenuItem>{" "}
                        <MenuItem value="Expert">Expert</MenuItem>{" "}
                        {/* <MenuItem value="+ 1 year">+ 1 year</MenuItem>{" "} */}
                      </Select>
                    </FormControl>
                  </TableCell>
                  {/* <TableCell align="center" className="skill-matrix-table-cell">
                    {computeSkillIndex(skill.duration)}
                  </TableCell> */}
                  <TableCell align="left">
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        className="outlinedinput-dynamic-cv"
                        inputFormat="MM.yyyy"
                        mask="__.____"
                        disableMaskedInput={false}
                        //   value=""
                        value={parse(skill.lastUsed, "MM.yyyy", new Date())}
                        onChange={(newValue) => {
                          let transformedDate =
                            moment(newValue).format("MM.YYYY");
                          if (transformedDate !== "Invalid date") {
                            axiosInstance
                              .put("/cvapp/skills-matrix/", {
                                id: skill.id,
                                skillCategory: skill.skillCategory,
                                skillSubCategory: skill.skillSubCategory,
                                lastUsed: transformedDate,
                              })
                              .then((response) => {
                                dispatch(
                                  updateAvqSkill({
                                    id: response.data.id,
                                    category: response.data.skillCategory,
                                    subCategory: response.data.skillSubCategory,
                                    property: "lastUsed",
                                    value: response.data.lastUsed,
                                  })
                                );
                              })
                              .catch((error) => {
                                console.log(
                                  "error updating last used skill matrix: ",
                                  error
                                );
                              });
                          }
                        }}
                        disableOpenPicker
                        renderInput={(params) => {
                          //add style prop to increase font-size
                          return (
                            <OutlinedInput
                              className="outlinedinput-dynamic-cv"
                              InputProps={params.InputProps}
                              {...params}
                              // label="birthdate"
                              inputRef={params.inputRef}
                              //   helperText="dd.mm.yyyy"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm.yyyy",
                              }}
                              sx={{ width: `${80}px` }}
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <OutlinedInput
                      className="outlined-input-skills-matrix-customized input-skills-matrix-pm"
                      placeholder="Last Project Manager"
                      value={skill.lastProjectManager}
                      onChange={(e) => {
                        dispatch(
                          updateAvqSkill({
                            id: skill.id,
                            category: skill.skillCategory,
                            subCategory: skill.skillSubCategory,
                            property: "lastProjectManager",
                            value: e.target.value,
                          })
                        );
                      }}
                      onBlur={(e) => {
                        axiosInstance
                          .put("/cvapp/skills-matrix/", {
                            id: skill.id,
                            skillCategory: skill.skillCategory,
                            skillSubCategory: skill.skillSubCategory,
                            lastProjectManager: e.target.value,
                          })
                          .then((response) => {
                            dispatch(
                              updateAvqSkill({
                                id: skill.id,
                                category: response.data.skillCategory,
                                subCategory: response.data.skillSubCategory,
                                property: "lastProjectManager",
                                value: response.data.lastProjectManager,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/skills-matrix: ",
                              error
                            );
                          });
                      }}
                    />
                    {/* {skill.lastProjectManager} */}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}

          <TableRow
            style={{
              boxShadow: skillMatrixState.techSkillsOpen
                ? "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                : "none",
            }}>
            <TableCell
              align="left"
              colSpan={1}
              className="skills-matrix-collapse-title">
              <IconButton
                className="collapse-skills-matrix-button"
                aria-label="expand row"
                size="small"
                onClick={() =>
                  dispatch(
                    changeTechSkillsOpen(!skillMatrixState.techSkillsOpen)
                  )
                }>
                {skillMatrixState.techSkillsOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell
              align="center"
              colSpan={11}
              className="skills-matrix-collapse-title">
              TECHNICAL SKILLS
            </TableCell>
          </TableRow>
          {skillMatrixState.techSkillsOpen ? (
            skillMatrixState.techSkills.map((skill) => {
              return (
                <TableRow>
                  <TableCell />
                  {/* <TableCell>{skill.skillCategory}</TableCell> */}
                  <TableCell className="skill-matrix-table-cell">
                    {" "}
                    {skill.skillCategory}
                  </TableCell>
                  <TableCell className="skill-matrix-table-cell" align="left">
                    {skill.skillSubCategory}
                  </TableCell>
                  {/* <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="#Days"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              #Days
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateTechSkill({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="1 month">1 month</MenuItem>
                        <MenuItem value="2 months">2 months</MenuItem>{" "}
                        <MenuItem value="3 months">3 months</MenuItem>
                        <MenuItem value="6 months">6 months</MenuItem>{" "}
                        <MenuItem value="1 year">1 year</MenuItem>{" "}
                        <MenuItem value="+ 1 year">+ 1 year</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </TableCell> */}
                  {/* <TableCell align="center" className="skill-matrix-table-cell">
                    {computeSkillIndex(skill.duration)}
                  </TableCell> */}
                  <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="Skill Level"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              Skill Level
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateTechSkill({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Beginner">Beginner</MenuItem>{" "}
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Advanced">Advanced</MenuItem>{" "}
                        <MenuItem value="Expert">Expert</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        className="outlinedinput-dynamic-cv"
                        inputFormat="MM.yyyy"
                        mask="__.____"
                        disableMaskedInput={false}
                        //   value=""
                        value={parse(skill.lastUsed, "MM.yyyy", new Date())}
                        onChange={(newValue) => {
                          let transformedDate =
                            moment(newValue).format("MM.YYYY");
                          if (transformedDate !== "Invalid date") {
                            axiosInstance
                              .put("/cvapp/skills-matrix/", {
                                id: skill.id,
                                skillCategory: skill.skillCategory,
                                skillSubCategory: skill.skillSubCategory,
                                lastUsed: transformedDate,
                              })
                              .then((response) => {
                                dispatch(
                                  updateTechSkill({
                                    id: response.data.id,
                                    category: response.data.skillCategory,
                                    subCategory: response.data.skillSubCategory,
                                    property: "lastUsed",
                                    value: response.data.lastUsed,
                                  })
                                );
                              })
                              .catch((error) => {
                                console.log(
                                  "error updating last used skill matrix: ",
                                  error
                                );
                              });
                          }
                        }}
                        disableOpenPicker
                        renderInput={(params) => {
                          //add style prop to increase font-size
                          return (
                            <OutlinedInput
                              className="outlinedinput-dynamic-cv"
                              InputProps={params.InputProps}
                              {...params}
                              // label="birthdate"
                              inputRef={params.inputRef}
                              //   helperText="dd.mm.yyyy"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm.yyyy",
                              }}
                              sx={{ width: `${80}px` }}
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <OutlinedInput
                      className="outlined-input-skills-matrix-customized input-skills-matrix-pm"
                      placeholder="Last Project Manager"
                      value={skill.lastProjectManager}
                      onChange={(e) => {
                        dispatch(
                          updateTechSkill({
                            id: skill.id,
                            category: skill.skillCategory,
                            subCategory: skill.skillSubCategory,
                            property: "lastProjectManager",
                            value: e.target.value,
                          })
                        );
                      }}
                      onBlur={(e) => {
                        axiosInstance
                          .put("/cvapp/skills-matrix/", {
                            id: skill.id,
                            skillCategory: skill.skillCategory,
                            skillSubCategory: skill.skillSubCategory,
                            lastProjectManager: e.target.value,
                          })
                          .then((response) => {
                            dispatch(
                              updateTechSkill({
                                id: skill.id,
                                category: response.data.skillCategory,
                                subCategory: response.data.skillSubCategory,
                                property: "lastProjectManager",
                                value: response.data.lastProjectManager,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/skills-matrix: ",
                              error
                            );
                          });
                      }}
                    />
                    {/* {skill.lastProjectManager} */}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}

          <TableRow
            style={{
              boxShadow: skillMatrixState.thirdPartySkillsOpen
                ? "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                : "none",
            }}>
            <TableCell
              align="left"
              colSpan={1}
              className="skills-matrix-collapse-title">
              <IconButton
                className="collapse-skills-matrix-button"
                aria-label="expand row"
                size="small"
                onClick={() =>
                  dispatch(
                    changeThirdPartyApplicationOpen(
                      !skillMatrixState.thirdPartySkillsOpen
                    )
                  )
                }>
                {skillMatrixState.thirdPartySkillsOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell
              align="center"
              colSpan={11}
              className="skills-matrix-collapse-title">
              3rd PARTY APPLICATIONS
            </TableCell>
          </TableRow>

          {skillMatrixState.thirdPartySkillsOpen ? (
            skillMatrixState.thirdPartySkills.map((skill) => {
              return (
                <TableRow>
                  <TableCell />
                  {/* <TableCell>{skill.skillCategory}</TableCell> */}
                  <TableCell className="skill-matrix-table-cell">
                    {" "}
                    {skill.skillCategory}
                  </TableCell>
                  <TableCell className="skill-matrix-table-cell" align="left">
                    {skill.skillSubCategory}
                  </TableCell>
                  {/* <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="#Days"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              #Days
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateThirdPartySkill({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="1 month">1 month</MenuItem>
                        <MenuItem value="2 months">2 months</MenuItem>{" "}
                        <MenuItem value="3 months">3 months</MenuItem>
                        <MenuItem value="6 months">6 months</MenuItem>{" "}
                        <MenuItem value="1 year">1 year</MenuItem>{" "}
                        <MenuItem value="+ 1 year">+ 1 year</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </TableCell> */}
                  {/* <TableCell align="center" className="skill-matrix-table-cell">
                    {computeSkillIndex(skill.duration)}
                  </TableCell> */}
                  <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="Skill Level"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              Skill Level
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateThirdPartySkill({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Beginner">Beginner</MenuItem>{" "}
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Advanced">Advanced</MenuItem>{" "}
                        <MenuItem value="Expert">Expert</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        className="outlinedinput-dynamic-cv"
                        inputFormat="MM.yyyy"
                        mask="__.____"
                        disableMaskedInput={false}
                        //   value=""
                        value={parse(skill.lastUsed, "MM.yyyy", new Date())}
                        onChange={(newValue) => {
                          let transformedDate =
                            moment(newValue).format("MM.YYYY");
                          if (transformedDate !== "Invalid date") {
                            axiosInstance
                              .put("/cvapp/skills-matrix/", {
                                id: skill.id,
                                skillCategory: skill.skillCategory,
                                skillSubCategory: skill.skillSubCategory,
                                lastUsed: transformedDate,
                              })
                              .then((response) => {
                                dispatch(
                                  updateThirdPartySkill({
                                    id: response.data.id,
                                    category: response.data.skillCategory,
                                    subCategory: response.data.skillSubCategory,
                                    property: "lastUsed",
                                    value: response.data.lastUsed,
                                  })
                                );
                              })
                              .catch((error) => {
                                console.log(
                                  "error updating last used skill matrix: ",
                                  error
                                );
                              });
                          }
                        }}
                        disableOpenPicker
                        renderInput={(params) => {
                          //add style prop to increase font-size
                          return (
                            <OutlinedInput
                              className="outlinedinput-dynamic-cv"
                              InputProps={params.InputProps}
                              {...params}
                              // label="birthdate"
                              inputRef={params.inputRef}
                              //   helperText="dd.mm.yyyy"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm.yyyy",
                              }}
                              sx={{ width: `${80}px` }}
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <OutlinedInput
                      className="outlined-input-skills-matrix-customized input-skills-matrix-pm"
                      placeholder="Last Project Manager"
                      value={skill.lastProjectManager}
                      onChange={(e) => {
                        dispatch(
                          updateThirdPartySkill({
                            id: skill.id,
                            category: skill.skillCategory,
                            subCategory: skill.skillSubCategory,
                            property: "lastProjectManager",
                            value: e.target.value,
                          })
                        );
                      }}
                      onBlur={(e) => {
                        axiosInstance
                          .put("/cvapp/skills-matrix/", {
                            id: skill.id,
                            skillCategory: skill.skillCategory,
                            skillSubCategory: skill.skillSubCategory,
                            lastProjectManager: e.target.value,
                          })
                          .then((response) => {
                            dispatch(
                              updateThirdPartySkill({
                                id: skill.id,
                                category: response.data.skillCategory,
                                subCategory: response.data.skillSubCategory,
                                property: "lastProjectManager",
                                value: response.data.lastProjectManager,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/skills-matrix: ",
                              error
                            );
                          });
                      }}
                    />
                    {/* {skill.lastProjectManager} */}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}

          {/* start business skills */}
          <TableRow
            style={{
              boxShadow: skillMatrixState.businessSkillsOpen
                ? "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                : "none",
            }}>
            <TableCell
              align="left"
              colSpan={1}
              className="skills-matrix-collapse-title">
              <IconButton
                className="collapse-skills-matrix-button"
                aria-label="expand row"
                size="small"
                onClick={() =>
                  dispatch(
                    changeBusinessSkillsOpen(
                      !skillMatrixState.businessSkillsOpen
                    )
                  )
                }>
                {skillMatrixState.businessSkillsOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell
              align="center"
              colSpan={11}
              className="skills-matrix-collapse-title">
              BUSINESS
            </TableCell>
          </TableRow>

          {skillMatrixState.businessSkillsOpen ? (
            skillMatrixState.businessSkills.map((skill) => {
              return (
                <TableRow>
                  <TableCell />
                  {/* <TableCell>{skill.skillCategory}</TableCell> */}
                  <TableCell className="skill-matrix-table-cell">
                    {" "}
                    {skill.skillCategory}
                  </TableCell>
                  <TableCell className="skill-matrix-table-cell" align="left">
                    {skill.skillSubCategory}
                  </TableCell>
                  {/* <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="#Days"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              #Days
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateBusinessSkills({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="1 month">1 month</MenuItem>
                        <MenuItem value="2 months">2 months</MenuItem>{" "}
                        <MenuItem value="3 months">3 months</MenuItem>
                        <MenuItem value="6 months">6 months</MenuItem>{" "}
                        <MenuItem value="1 year">1 year</MenuItem>{" "}
                        <MenuItem value="+ 1 year">+ 1 year</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </TableCell> */}
                  {/* <TableCell align="center" className="skill-matrix-table-cell">
                    {computeSkillIndex(skill.duration)}
                  </TableCell> */}
                  <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="Skill Level"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              Skill Level
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateBusinessSkills({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Beginner">Beginner</MenuItem>{" "}
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Advanced">Advanced</MenuItem>{" "}
                        <MenuItem value="Expert">Expert</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        className="outlinedinput-dynamic-cv"
                        inputFormat="MM.yyyy"
                        mask="__.____"
                        disableMaskedInput={false}
                        //   value=""
                        value={parse(skill.lastUsed, "MM.yyyy", new Date())}
                        onChange={(newValue) => {
                          let transformedDate =
                            moment(newValue).format("MM.YYYY");
                          if (transformedDate !== "Invalid date") {
                            axiosInstance
                              .put("/cvapp/skills-matrix/", {
                                id: skill.id,
                                skillCategory: skill.skillCategory,
                                skillSubCategory: skill.skillSubCategory,
                                lastUsed: transformedDate,
                              })
                              .then((response) => {
                                dispatch(
                                  updateBusinessSkills({
                                    id: response.data.id,
                                    category: response.data.skillCategory,
                                    subCategory: response.data.skillSubCategory,
                                    property: "lastUsed",
                                    value: response.data.lastUsed,
                                  })
                                );
                              })
                              .catch((error) => {
                                console.log(
                                  "error updating last used skill matrix: ",
                                  error
                                );
                              });
                          }
                        }}
                        disableOpenPicker
                        renderInput={(params) => {
                          //add style prop to increase font-size
                          return (
                            <OutlinedInput
                              className="outlinedinput-dynamic-cv"
                              InputProps={params.InputProps}
                              {...params}
                              // label="birthdate"
                              inputRef={params.inputRef}
                              //   helperText="dd.mm.yyyy"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm.yyyy",
                              }}
                              sx={{ width: `${80}px` }}
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <OutlinedInput
                      className="outlined-input-skills-matrix-customized input-skills-matrix-pm"
                      placeholder="Last Project Manager"
                      value={skill.lastProjectManager}
                      onChange={(e) => {
                        dispatch(
                          updateBusinessSkills({
                            id: skill.id,
                            category: skill.skillCategory,
                            subCategory: skill.skillSubCategory,
                            property: "lastProjectManager",
                            value: e.target.value,
                          })
                        );
                      }}
                      onBlur={(e) => {
                        axiosInstance
                          .put("/cvapp/skills-matrix/", {
                            id: skill.id,
                            skillCategory: skill.skillCategory,
                            skillSubCategory: skill.skillSubCategory,
                            lastProjectManager: e.target.value,
                          })
                          .then((response) => {
                            dispatch(
                              updateBusinessSkills({
                                id: skill.id,
                                category: response.data.skillCategory,
                                subCategory: response.data.skillSubCategory,
                                property: "lastProjectManager",
                                value: response.data.lastProjectManager,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/skills-matrix: ",
                              error
                            );
                          });
                      }}
                    />
                    {/* {skill.lastProjectManager} */}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}

          {/* start consulting skills */}
          <TableRow
            style={{
              boxShadow: skillMatrixState.consultingSkillsOpen
                ? "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                : "none",
            }}>
            <TableCell
              align="left"
              colSpan={1}
              className="skills-matrix-collapse-title">
              <IconButton
                className="collapse-skills-matrix-button"
                aria-label="expand row"
                size="small"
                onClick={() =>
                  dispatch(
                    changeConsultingSkillsOpen(
                      !skillMatrixState.consultingSkillsOpen
                    )
                  )
                }>
                {skillMatrixState.consultingSkillsOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell
              align="center"
              colSpan={11}
              className="skills-matrix-collapse-title">
              CONSULTING / PM
            </TableCell>
          </TableRow>

          {skillMatrixState.consultingSkillsOpen ? (
            skillMatrixState.consultingSkills.map((skill) => {
              return (
                <TableRow>
                  <TableCell />
                  {/* <TableCell>{skill.skillCategory}</TableCell> */}
                  <TableCell className="skill-matrix-table-cell">
                    {" "}
                    {skill.skillCategory}
                  </TableCell>
                  <TableCell className="skill-matrix-table-cell" align="left">
                    {skill.skillSubCategory}
                  </TableCell>
                  {/* <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="#Days"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              #Days
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateConsultingSkill({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="1 month">1 month</MenuItem>
                        <MenuItem value="2 months">2 months</MenuItem>{" "}
                        <MenuItem value="3 months">3 months</MenuItem>
                        <MenuItem value="6 months">6 months</MenuItem>{" "}
                        <MenuItem value="1 year">1 year</MenuItem>{" "}
                        <MenuItem value="+ 1 year">+ 1 year</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </TableCell> */}
                  {/* <TableCell align="center" className="skill-matrix-table-cell">
                    {computeSkillIndex(skill.duration)}
                  </TableCell> */}
                  <TableCell align="left">
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="selectfield-skills-matrix"
                        label="Skill Level"
                        value={skill.duration ? skill.duration : ""}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? (
                            Array.isArray(value) ? (
                              value.join(", ")
                            ) : (
                              value
                            )
                          ) : (
                            <span style={{ color: "rgba(142, 142, 142, 0.9)" }}>
                              Skill Level
                            </span>
                          )
                        }
                        onChange={(e) => {
                          axiosInstance
                            .put("/cvapp/skills-matrix/", {
                              id: skill.id,
                              skillCategory: skill.skillCategory,
                              skillSubCategory: skill.skillSubCategory,
                              skillIndex: computeSkillIndex(e.target.value),
                              duration: e.target.value,
                            })
                            .then((response) => {
                              dispatch(
                                updateConsultingSkill({
                                  id: response.data.id,
                                  category: response.data.skillCategory,
                                  subCategory: response.data.skillSubCategory,
                                  property: "duration",
                                  value: response.data.duration,
                                })
                              );
                            })
                            .catch((error) => {
                              console.log(
                                "error PUT /cvapp/skills-matrix: ",
                                error
                              );
                            });
                        }}>
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Beginner">Beginner</MenuItem>{" "}
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Advanced">Advanced</MenuItem>{" "}
                        <MenuItem value="Expert">Expert</MenuItem>{" "}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        className="outlinedinput-dynamic-cv"
                        inputFormat="MM.yyyy"
                        mask="__.____"
                        disableMaskedInput={false}
                        //   value=""
                        value={parse(skill.lastUsed, "MM.yyyy", new Date())}
                        onChange={(newValue) => {
                          let transformedDate =
                            moment(newValue).format("MM.YYYY");
                          if (transformedDate !== "Invalid date") {
                            axiosInstance
                              .put("/cvapp/skills-matrix/", {
                                id: skill.id,
                                skillCategory: skill.skillCategory,
                                skillSubCategory: skill.skillSubCategory,
                                lastUsed: transformedDate,
                              })
                              .then((response) => {
                                dispatch(
                                  updateConsultingSkill({
                                    id: response.data.id,
                                    category: response.data.skillCategory,
                                    subCategory: response.data.skillSubCategory,
                                    property: "lastUsed",
                                    value: response.data.lastUsed,
                                  })
                                );
                              })
                              .catch((error) => {
                                console.log(
                                  "error updating last used skill matrix: ",
                                  error
                                );
                              });
                          }
                        }}
                        disableOpenPicker
                        renderInput={(params) => {
                          //add style prop to increase font-size
                          return (
                            <OutlinedInput
                              className="outlinedinput-dynamic-cv"
                              InputProps={params.InputProps}
                              {...params}
                              // label="birthdate"
                              inputRef={params.inputRef}
                              //   helperText="dd.mm.yyyy"
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "mm.yyyy",
                              }}
                              sx={{ width: `${80}px` }}
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <OutlinedInput
                      className="outlined-input-skills-matrix-customized input-skills-matrix-pm"
                      placeholder="Last Project Manager"
                      value={skill.lastProjectManager}
                      onChange={(e) => {
                        dispatch(
                          updateConsultingSkill({
                            id: skill.id,
                            category: skill.skillCategory,
                            subCategory: skill.skillSubCategory,
                            property: "lastProjectManager",
                            value: e.target.value,
                          })
                        );
                      }}
                      onBlur={(e) => {
                        axiosInstance
                          .put("/cvapp/skills-matrix/", {
                            id: skill.id,
                            skillCategory: skill.skillCategory,
                            skillSubCategory: skill.skillSubCategory,
                            lastProjectManager: e.target.value,
                          })
                          .then((response) => {
                            dispatch(
                              updateConsultingSkill({
                                id: skill.id,
                                category: response.data.skillCategory,
                                subCategory: response.data.skillSubCategory,
                                property: "lastProjectManager",
                                value: response.data.lastProjectManager,
                              })
                            );
                          })
                          .catch((error) => {
                            console.log(
                              "error PUT /cvapp/skills-matrix: ",
                              error
                            );
                          });
                      }}
                    />
                    {/* {skill.lastProjectManager} */}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ContentSkillsMatrixPage;
